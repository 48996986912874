<template>
  <div>
    <div
      class="content_img d-flex flex-wrap other_mt"
      style="
        position: relative;
        width: 100%;
        padding-bottom: 100%;
        height: 100%;
      "
    >
      <iframe
        :src="getnewurl(index)"
        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
        marginwidth="0"
        marginheight="0"
        scrolling="auto"
        frameborder="0"
        align="center"
      ></iframe>
    </div>
  </div>
</template>
<script>
export default {
  props: { index: Number },
  methods: {
    getnewurl: function (t) {
      return (
        process.env.VUE_APP_BASEURL +
        "/promotions_app.aspx?station=" +
        process.env.VUE_APP_STATOIN +
        "&id=" +
        t
      );
    },
  },
};
</script>
