<template>
  <div class="footer w-100">
    <div class="d-flex mx-auto mb-0">
      <div class="row">
        <div class="col-6 col-xl-4">
          <div class="flex-column d-inline-flex">
            <div class="">
              <span class="footer_title">{{ $t("footer_games") }}</span>
            </div>
            <div class="" @click="this.$router.push('/slot/casino')">
              <span class="footer_subtitle">{{ $t("home_text6") }}</span>
            </div>
            <div class="" @click="this.$router.push('/slot/slot')">
              <span class="footer_subtitle">{{ $t("home_text7") }}</span>
            </div>
            <div class="" @click="this.$router.push('/slot/hot')">
              <span class="footer_subtitle">{{ $t("text_hot") }}</span>
            </div>
            <!-- <div class="" @click="this.$router.push('/main_sport')">
              <span class="footer_subtitle">{{ $t("text_livesport") }}</span>
            </div> -->
            <div class="" @click="this.$router.push('/slot/sport')">
              <span class="footer_subtitle">{{ $t("text_sport") }}</span>
            </div>
            <div class="" @click="this.$router.push('/slot/poker')">
              <span class="footer_subtitle">{{ $t("text_poker_game_1") }}</span>
            </div>
            <div class="" @click="this.$router.push('/slot/fish')">
              <span class="footer_subtitle">{{ $t("text_main12") }}</span>
            </div>
            <div class="" @click="this.$router.push('/slot/chess')">
              <span class="footer_subtitle">{{ $t("text_chess") }}</span>
            </div>
            <div class="" @click="this.$router.push('/slot/lotto')">
              <span class="footer_subtitle">{{ $t("text_lotto") }}</span>
            </div>
          </div>
        </div>
        <div class="col-6 col-xl-4">
          <div class="flex-column d-inline-flex">
            <div class="">
              <span class="footer_title">{{ $t("footer_help") }}</span>
            </div>
            <div class="" @click="this.$router.push('/help/K')">
              <span class="footer_subtitle">{{ $t("footer_k") }}</span>
            </div>
            <div class="" @click="this.$router.push('/help/P')">
              <span class="footer_subtitle">{{ $t("footer_p") }}</span>
            </div>
            <div class="" @click="this.$router.push('/help/R')">
              <span class="footer_subtitle">{{ $t("footer_r") }}</span>
            </div>
            <div class="" @click="this.$router.push('/help/T')">
              <span class="footer_subtitle">{{ $t("footer_t") }}</span>
            </div>
            <!-- <div class="" @click="this.$router.push('/help/F')">
              <span class="footer_subtitle">{{ $t("footer_f") }}</span>
            </div>
            <div class="" @click="this.$router.push('/help/E')">
              <span class="footer_subtitle">{{ $t("footer_e") }}</span>
            </div> -->
          </div>
        </div>
        <div class="col-12 col-xl-4 mt-3 mt-xl-0">
          <div class="flex-column d-inline-flex">
            <div class="">
              <div class="flex-row">
                <div class="">
                  <span class="footer_title">{{ $t("footer_a") }}</span>
                </div>
              </div>
            </div>
            <div class="">
              <span class="footer_subtitle" style="cursor: initial">{{
                $t("footer_a_content")
              }}</span>
              <p class="footer_subtitle mt-2" style="cursor: initial">
                {{ $t("footer_text5") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr style="color: #4e5466" />
    <div class="">
      <div
        class="d-grid justify-content-center align-items-center mx-auto footer_img"
      >
        <img
          class="footer_right_img"
          src="@/assets/img/footer/mga.png"
          alt=""
        /><img
          class="footer_right_img"
          src="@/assets/img/footer/pagcor.png"
          alt=""
        /><img
          class="footer_right_img"
          src="@/assets/img/footer/gambling_commission.png"
          alt=""
        /><img
          class="footer_right_img"
          src="@/assets/img/footer/gaming_curacao.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footertwo",
  data() {
    return {};
  },
  components: {},
  computed: {},
  methods: {},
  created() {},
  watch: {},
  mounted() {},
};
</script>
