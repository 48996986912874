<template>
  <div id="salary">
    <div class="mt-xl-3 mt-1 border_eee box_shadow p-2">
      <div class="d-flex w-100">
        <div class="w-100">
          <div
            class="invitedperson_table GbTable_root__aRhLC"
            style="border-radius: 10px"
          >
            <table
              style="table-layout: auto"
              class="w-100 GbTable_table__moqvH text-center border_eee"
              cellpadding="10"
            >
              <thead class="ant-table-thead">
                <tr>
                  <th>{{ $t("text_date") }}</th>
                  <th>{{ $t("share_bonus_user") }}</th>
                  <th>{{ $t("deposit_amt") }}</th>
                  <th>{{ $t("share_invite_text31") }}</th>
                </tr>
              </thead>
              <tbody
                class="ant-table-tbody"
                v-show="this.list.length == null || this.list.length == 0"
              >
                <tr>
                  <td class="" colspan="4" style="padding-top: 30px">
                    <img
                      class=""
                      src="@/assets/img/invite/37CDF956FB99616BA166DC17329BEFCA.svg"
                      alt=""
                    />
                    <p class="">{{ $t("nodata") }}</p>
                  </td>
                </tr>
              </tbody>
              <tbody
                class="ant-table-tbody"
                v-show="this.list.length != null && this.list.length > 0"
                v-for="l in list"
                :key="l"
              >
                <tr>
                  <td class="">{{ l.date }}</td>
                  <td class="">{{ l.userid }}</td>
                  <td class="">{{ l.deposit }}</td>
                  <td class="">{{ l.betting_income }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";

export default {
  name: "invited_person",
  computed: {},
  data() {
    return {
      code: Cookies.get("code"),
      list: [],
    };
  },
  methods: {
    invited_person: function () {
      let param = {
        action: "invited_person",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        var status = res["status"];
        if (status == "success") {
          this.list = res["data"];
        }
      });
    },
  },
  created() {
    if (
      Cookies.get("code") != null &&
      Cookies.get("code") != "" &&
      localStorage.getItem("uid") != null &&
      localStorage.getItem("uid") != ""
    ) {
      this.invited_person();
    }
  },
};
</script>
