<template>
  <div
    class="mx-auto mb-5"
    style="max-width: 1200px; min-height: 550px; padding-top: 30px"
  >
    <div v-show="id == null || id == ''">
      <div class="d-flex flex-column w-100">
        <div style="padding: 8px">
          <h1 class="GbFAQList_title__srUMv">{{ $t("footer_f") }}</h1>
          <div class="position-relative">
            <div
              class="d-flex flex-flow"
              style="margin-left: -4px; margin-right: -4px; row-gap: 8px"
            >
              <div style="width: 50%; max-width: 50%" @click="id = 'account'">
                <div style="padding-left: 4px; padding-right: 4px">
                  <div class="mb-0">
                    <div class="FAQLIST_item">
                      <p class="FAQLIST_item_p">{{ $t("text_userid") }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div style="width: 50%; max-width: 50%" @click="id = 'dw'">
                <div style="padding-left: 4px; padding-right: 4px">
                  <div class="mb-0">
                    <div class="FAQLIST_item">
                      <p class="FAQLIST_item_p">{{ $t("faq_1") }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div style="width: 50%; max-width: 50%" @click="id = 're'">
                <div style="padding-left: 4px; padding-right: 4px">
                  <div class="mb-0">
                    <div class="FAQLIST_item">
                      <p class="FAQLIST_item_p">{{ $t("faq_2") }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div style="width: 50%; max-width: 50%" @click="id = 'games'">
                <div style="padding-left: 4px; padding-right: 4px">
                  <div class="mb-0">
                    <div class="FAQLIST_item">
                      <p class="FAQLIST_item_p">{{ $t("faq_3") }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div style="width: 50%; max-width: 50%" @click="id = 'new'">
                <div style="padding-left: 4px; padding-right: 4px">
                  <div class="mb-0">
                    <div class="FAQLIST_item">
                      <p class="FAQLIST_item_p">{{ $t("faq_4") }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div style="width: 50%; max-width: 50%" @click="id = 'pro'">
                <div style="padding-left: 4px; padding-right: 4px">
                  <div class="mb-0">
                    <div class="FAQLIST_item">
                      <p class="FAQLIST_item_p">{{ $t("faq_5") }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div style="width: 50%; max-width: 50%" @click="id = 'safe'">
                <div style="padding-left: 4px; padding-right: 4px">
                  <div class="mb-0">
                    <div class="FAQLIST_item">
                      <p class="FAQLIST_item_p">{{ $t("faq_6") }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="DesignArticle_root__yLujo">
      <div class="d-flex w-100">
        <div v-show="id == 'account'">
          <h1 class="help_title">{{ $t("text_userid") }}</h1>
          <div v-html="$t('help_faq_home1_con')"></div>
        </div>

        <div v-show="id == 'dw'">
          <h1 class="help_title">{{ $t("help_faq_home2_title") }}</h1>
          <div v-html="$t('help_faq_home2_con')"></div>
        </div>

        <div v-show="id == 're'">
          <h1 class="help_title">{{ $t("help_faq_home3_title") }}</h1>
          <div v-html="$t('help_faq_home3_con')"></div>
        </div>

        <div v-show="id == 'games'">
          <h1 class="help_title">{{ $t("faq_3") }}</h1>

          <div class="ft_14">
            <div v-html="$t('help_faq_home4_con1')"></div>
            <div v-html="$t('help_faq_home4_con2')"></div>
          </div>
        </div>

        <div v-show="id == 'new'">
          <h1 class="help_title">{{ $t("faq_4") }}</h1>
          <div class="ft_14" v-html="$t('help_faq_home4_con1')"></div>
        </div>

        <div v-show="id == 'pro'">
          <h1 class="help_title">{{ $t("faq_5") }}</h1>
          <div v-html="$t('help_faq_home4_con3')"></div>
        </div>

        <div v-show="id == 'safe'">
          <h1 class="help_title">{{ $t("faq_6") }}</h1>
          <div class="ft_14" v-html="$t('help_faq_home4_con4')"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "faq",
  data() {
    return { id: "" };
  },
  created() {},
  methods: {},
  watch: {
    id() {
      document.getElementById("app").scrollTop = 0;
    },
  },
  computed: {},
  components: {},
  mounted() {},
};
</script>
