<template>
  <div class="">
    <div
      class="d-flex justify-content-center align-items-center position-relative"
    >
      <div
        class="mobile-menu-deposit-back pe-2 py-1 c-pointer position-absolute mobile-menu-back"
        @click="this.$emit('selectid', false)"
      >
        <span> ＜{{ $t("text_back_page") }} </span>
      </div>
      <span class="text-center club_span_big" v-if="games == 'texas' && texas_type == 9">
        {{ $t("text_texas_poker") }}
      </span>
      <span class="text-center club_span_big" v-if="games == 'texas' && texas_type == 6">
        {{ $t("text_king_poker") }}
      </span>
      <span class="text-center club_span_big" v-if="games == 'baccarat'">
        {{ $t("text_baccarat") }}
      </span>
      <span class="text-center club_span_big" v-if="games == 'niu'">
        {{ $t("text_niu") }}
      </span>
      <span class="text-center club_span_big" v-if="games == 'tongzi'">
        {{ $t("text_tongzi") }}
      </span>
      <span class="text-center club_span_big" v-if="games == 'sicbo'">
        {{ $t("text_sicbo") }}
      </span>
      <span class="text-center club_span_big" v-if="games == 'fasttexas'">
        {{ $t("text_fasttexas") }}
      </span>
      <span class="text-center club_span_big" v-if="games == 'roulette'">
        {{ $t("text_roulette") }}
      </span>
      <span class="text-center club_span_big" v-if="games == 'dragontiger'">
        {{ $t("text_dragontiger") }}
      </span>
    </div>
    <form @submit="create_table">
      <div
        class="d-flex justify-content-start flex-lg-column align-items-lg-center bg-table-img p-3 px-md-5 py-md-3 casino-header-container mt-3"
      >
        <div class="casino-search-input-results flex-column w-100">
          <span class="club_span3 text_light_coff">{{
            $t("text_table13")
          }}</span>
          <div class="casino-header-container-search mt-0 w-100">
            <div>
              <input
                :placeholder="$t('text_table1')"
                v-model="tablename"
                required="required"
              />
            </div>
          </div>
        </div>

        <div class="casino-search-input-results flex-column w-100 mt-3">
          <span class="club_span3 text_light_coff">{{
            $t("text_table8")
          }}</span>
          <div class="casino-header-container-search mt-0 w-100">
            <div>
              <input
                type="number"
                :placeholder="$t('text_table12')"
                v-model="groupnumber"
                required="required"
                min="1"
                max="20"
              />
            </div>
          </div>
        </div>

        <div
          class="casino-search-input-results flex-column w-100 mt-3"
          v-if="this.games != 'texas'"
        >
          <span class="club_span3 text_light_coff">{{
            $t("text_table18")
          }}</span>
          <div class="casino-header-container-search mt-0 w-100">
            <div>
              <input
                type="number"
                :placeholder="$t('text_table19')"
                v-model="killrate"
                step="0.01"
                required="required"
                min="0"
                max="1"
              />
            </div>
          </div>
        </div>

        <div class="mt-4 w-100">
          <p class="club_span3 text_light_coff">{{ $t("text_table2") }}</p>

          <div
            class="px-3 mt-2 d-flex flex-column flex-lg-row"
            style="gap: 35px"
          >
            <div
              class="d-flex justify-content-center align-items-center member_item"
              v-if="this.games == 'texas'"
            >
              <p class="club_span3 text-nowrap mb-0 me-3">
                {{ $t("text_table4") }}
              </p>
              <select
                v-model="old_sbamount_type"
                @change="switchIndex($event.target.selectedIndex)"
              >
                <option
                  v-for="(l, i) in list_sbamount_type"
                  :key="i"
                  :value="l"
                >
                  {{ l }}
                </option>
              </select>
            </div>
            <div
              class="d-flex justify-content-center align-items-center member_item"
              v-if="this.games == 'texas'"
            >
              <p class="club_span3 text-nowrap mb-0 me-3">
                {{ $t("text_table5") }}
              </p>
              <select v-model="duration">
                <option v-for="(l, i) in list_duration" :key="i" :value="l">
                  {{ l }}
                </option>
              </select>
            </div>
            <div
              class="d-flex justify-content-center align-items-center member_item"
              v-if="this.games != 'texas'"
            >
              <p class="club_span3 text-nowrap mb-0 me-3">
                {{ $t("text_table17") }}
              </p>
              <select
                v-model="old_limit"
                @change="switchIndex($event.target.selectedIndex)"
              >
                <option v-for="(l, i) in list_limit" :key="i" :value="l">
                  {{ l }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="mt-5 w-100 d-flex justify-content-center">
          <button class="btn mb-3 take-btn give-btn">
            {{ $t("text_table6") }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import Cookies from "js-cookie";
export default {
  props: {
    games: String,
    texas_type: String,
  },
  computed: {},
  data() {
    return {
      code: Cookies.get("code"),
      duration: 3,
      groupnumber: 1,
      old_sbamount_type: "100/200",
      sbamount_type: 1,
      tablename: "",
      killrate: 0,
      old_limit: "100/20000",
      limit: 1,
      list_sbamount_type: ["100/200", "300/600", "1000/2000", "2000/4000"],
      list_duration: [3, 5, 8, 10],
      list_limit: ["100/20000", "300/60000", "1000/200000", "2000/400000"],
    };
  },
  methods: {
    create_table(e) {
      e.preventDefault();
      if (
        Cookies.get("code") == null ||
        Cookies.get("code") == "" ||
        localStorage.getItem("uid") == null ||
        localStorage.getItem("uid") == ""
      ) {
        this.$store.commit("islogin", true);
        this.$store.dispatch("logout_public");
      } else {
        let param = {
          action: "create_table",
          body: {
            uid: localStorage.getItem("uid"),
            session_code: Cookies.get("code"),
            clubid: localStorage.getItem("clubid"),
            duration: this.duration,
            groupnumber: this.groupnumber,
            sbamount_type: this.sbamount_type,
            tablename: this.tablename,
            texas_type: this.texas_type,
            games: this.games,
            killrate: this.killrate,
          },
        };
        this.$store.dispatch("getpost", param).then((res) => {
          if (res.status == "success") {
            this.$router.push("/club");
          } else this.$MSG.API_popmsg(res.msg, "", "warning");
        });
      }
    },
    switchIndex(id) {
      this.sbamount_type = id + 1;
    },
  },
  watch: {},
  created() {
    if (
      Cookies.get("code") == null ||
      Cookies.get("code") == "" ||
      localStorage.getItem("uid") == null ||
      localStorage.getItem("uid") == ""
    ) {
      this.$store.commit("islogin", true);
      this.$store.dispatch("logout_public");
    }
    // this.duration = this.list_duration[0];
    // this.texas_type = this.list_texas_type[0];
  },
};
</script>