<template>
  <div class="home_game_casino jackpot_game">
    <div class="d-flex justify-content-between align-items-center">
      <div class="game_logo_img fw-bolder my-3">
        <img
          src="@/assets/img/left/game_shareholders.png"
          alt=""
          style="width: 60px"
        />
        <div class="d-flex flex-column">
          <span class="jackpot_rank_title ms-2">
            {{ $t("text_game_shareholders").toLowerCase() }}</span
          >
          <span class="ms-2" style="color: #e39c44">
            {{ $t("text_subtitle_game_shareholders").toLowerCase() }}</span
          >
        </div>
      </div>
    </div>
    <div class="">
      <div class="row mx-auto">
        <p class="mt-3 mb-2" style="font-size: 20px">
          {{ $t("home_text6").toUpperCase() }}
        </p>
        <div
          class="col-6 col-xl-2"
          style="padding: 5px"
          v-for="(l, k) in casino_list"
          :key="k"
          @click="click_detail(l, 'share')"
        >
          <div
            class="card border-0"
            style="border-radius: 26px; background-color: #0e9ae3"
          >
            <div class="card-images">
              <img
                class="card-img-top position-relative rounded-3"
                v-lazy="l.img"
              />
              <div class="mask position-absolute top-0 start-0"></div>
              <div
                class="card-body p-xl-3 d-flex justify-content-center ps-1 pe-0 align-items-center flex-column"
              >
                <div
                  class="d-flex justify-content-center align-items-center flex-column spe-flex-wrap"
                >
                  <h6 class="text-truncate mb-1 text-invest">
                    {{ $t("invest_text3") }}：
                  </h6>
                  <h6 class="text-truncate mb-1 text-invest">
                    <!-- <img
                    src="@/assets/img/main/diamond.png"
                    style="width: 25px; height: 25px"
                  /> -->
                    {{ $t("cur") }}
                    {{ l.payment }}
                  </h6>
                </div>

                <div
                  class="d-flex justify-content-center align-items-center flex-column spe-flex-wrap mt-2"
                >
                  <h6 class="text-truncate mb-1 text-invest">
                    {{ $t("invest_text13") }}：
                  </h6>
                  <h6 class="text-truncate mb-1 text-invest">
                    {{ l.rate }}%
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p class="mt-3 mb-2" style="font-size: 20px">
          {{ $t("home_text7").toUpperCase() }}
        </p>
        <div
          class="col-6 col-xl-2"
          style="padding: 5px"
          v-for="(l, k) in slot_list"
          :key="k"
          @click="click_detail(l, 'share')"
        >
          <div
            class="card border-0"
            style="border-radius: 26px; background-color: #0e9ae3"
          >
            <div class="card-images">
              <img
                class="card-img-top position-relative rounded-3"
                v-lazy="l.img"
              />
              <div class="mask position-absolute top-0 start-0"></div>
              <div
                class="card-body p-xl-3 d-flex justify-content-center ps-1 pe-0 align-items-center flex-column"
              >
                <div
                  class="d-flex justify-content-center align-items-center flex-column spe-flex-wrap"
                >
                  <h6 class="text-truncate mb-1 text-invest">
                    {{ $t("invest_text3") }}：
                  </h6>
                  <h6 class="text-truncate mb-1 text-invest">
                    <!-- <img
                    src="@/assets/img/main/diamond.png"
                    style="width: 25px; height: 25px"
                  /> -->
                    {{ $t("cur") }}
                    {{ l.payment }}
                  </h6>
                </div>

                <div
                  class="d-flex justify-content-center align-items-center flex-column spe-flex-wrap mt-2"
                >
                  <h6 class="text-truncate mb-1 text-invest">
                    {{ $t("invest_text13") }}：
                  </h6>
                  <h6 class="text-truncate mb-1 text-invest">
                    {{ l.rate }}%
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p class="mt-3 mb-2" style="font-size: 20px">
          {{ $t("text_main11").toUpperCase() }}
        </p>
        <div
          class="col-6 col-xl-2"
          style="padding: 5px"
          v-for="(l, k) in hot_list"
          :key="k"
          @click="click_detail(l, 'betstock')"
        >
          <div
            class="card border-0"
            style="border-radius: 26px; background-color: #0e9ae3"
          >
            <div class="card-images">
              <img
                class="card-img-top position-relative rounded-3"
                v-lazy="l.img"
              />
              <div class="mask position-absolute top-0 start-0"></div>
              <div
                class="card-body p-xl-3 d-flex justify-content-center ps-1 pe-0 align-items-center flex-column"
              >
                <div
                  class="d-flex justify-content-center align-items-center flex-column spe-flex-wrap"
                >
                  <h6 class="text-truncate mb-1 text-invest">
                    {{ $t("invest_text3") }}：
                  </h6>
                  <h6 class="text-truncate mb-1 text-invest">
                    <!-- <img
            src="@/assets/img/main/diamond.png"
            style="width: 25px; height: 25px"
          /> -->
                    {{ $t("cur") }}
                    {{ l.payment }}
                  </h6>
                </div>

                <div
                  class="d-flex justify-content-center align-items-center flex-column spe-flex-wrap mt-2"
                >
                  <h6 class="text-truncate mb-1 text-invest">
                    {{ $t("invest_text13") }}：
                  </h6>
                  <h6 class="text-truncate mb-1 text-invest">
                    {{ l.rate }}%
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div
      class="w-25 mx-auto text-center w-lg-75 mt-3"
      v-if="list_index != list.length"
    >
      <button class="w-100 btn btn-success" @click="more()">
        <a>{{ $t("slot_text2") }}</a>
      </button>
    </div> -->

      <div class="finance_page home_vip_page" v-show="flag_finance_page">
        <div class="finance_card">
          <div class="finance_close" @click="flag_finance_page = false">×</div>
          <div class="finance_title">
            {{ $t("text_game_shareholders") }}
          </div>

          <div class="finance_main">
            <div class="finance_deposit" style="margin-top: 10px">
              <p>{{ $t("invest_text2") }}：{{ cou_name }}</p>
              <p>
                {{ $t("invest_text3") }}：
                <!-- <img
                  src="@/assets/img/main/diamond.png"
                  style="width: 25px; height: 25px"
                  class="me-1"
                /> -->
                {{ $t("cur") }}
                {{ cou_price }}
              </p>
              <p>
                {{ $t("invest_text4") }}：
                {{ parseFloat((cou_rate * 100).toFixed(2)) }}%
              </p>

              <div>
                <p class="mb-2">
                  {{ $t("invest_text15") }}
                </p>
                <div class="position-relative d-flex align-items-center">
                  <!-- <span class="mx-2 position-absolute"> {{ $t("cur") }}</span> -->
                  <input
                    type="text"
                    class="finance_text text-center"
                    v-model="payment"
                  />
                </div>
                <p v-show="flag_pay_error">
                  <span style="color: #dc3545">{{ $t("error_tips") }}</span>
                </p>
              </div>

              <div class="mt-3">
                <span> {{ $t("invest_text5") }}： </span>
                <span> {{ $t("cur") }}</span
                >&nbsp;
                <span> {{ payment * cou_price }}</span>
              </div>

              <div class="mt-3">
                <p class="mb-2">
                  {{ $t("reg_trade_pw_title") }}
                </p>
                <div class="position-relative d-flex align-items-center">
                  <input
                    :type="flag_eye2 ? 'text' : 'password'"
                    class="finance_text text-center"
                    v-model="withdrawpw"
                    minlength="4"
                    maxlength="6"
                  />
                  <span
                    class="validating-icon"
                    @click="flag_eye2 = !flag_eye2"
                    style="position: absolute; right: 15px; top: 6px"
                  >
                    <img
                      :src="
                        flag_eye2
                          ? require('@/assets/img/main/open_eye.png')
                          : require('@/assets/img/main/close_eye.png')
                      "
                    />
                  </span>
                </div>
              </div>

              <div class="col-10 text-white mx-auto my-3">
                <button
                  @click="fun_who_which"
                  class="border-0 text-white bg_green_btn p-2 w-100"
                  :disabled="flag_pay_error"
                  :style="
                    flag_pay_error
                      ? 'cursor: no-drop;background-color: #CCC;'
                      : ''
                  "
                >
                  <span>{{ $t("invest_text6") }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
export default {
  props: {},
  data() {
    return {
      casino_list: [],
      slot_list: [],
      flag_finance_page: false,
      flag_pay_error: false,
      payment: 0,
      cou_name: "",
      cou_price: "",
      cou_rate: "",
      cou_mpkidx: "",
      list_index: 6,
      code: Cookies.get("code"),
      flag_eye2: false,
      stockList: [],
      hot_list: [],
      who_which: "",
    };
  },
  methods: {
    sharebonus_item: function () {
      let param = {
        action: "sharebonus_item",
        body: {
          uid: localStorage.getItem("uid"),
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        var status = res.status;
        if (status == "success") {
          this.slot_list = res.data[0]["slot"];
          this.casino_list = res.data[1]["casino"];
        }
      });
    },
    click_detail: function (e, who_which) {
      if (
        this.code == null ||
        this.code == "" ||
        localStorage.getItem("uid") == null ||
        localStorage.getItem("uid") == ""
      ) {
        this.$store.commit("islogin", true);
        this.$store.dispatch("logout_public");
      } else {
        this.who_which = who_which;
        this.flag_finance_page = true;
        this.cou_name = e.cname;
        this.cou_price = e.payment;
        this.cou_rate = e.rate;
        this.cou_mpkidx = e.mpkidx;
      }
    },
    buy_sharebonus_item: function () {
      if (this.payment != 0 && this.payment != "" && this.payment != null) {
        let param = {
          action: "sharebonus_buy",
          body: {
            uid: localStorage.getItem("uid"),
            session_code: Cookies.get("code"),
            mpkidx: this.cou_mpkidx,
            finance: this.payment,
            withdrawpw: this.withdrawpw,
          },
        };
        this.$store.dispatch("getpost", param).then((res) => {
          var status = res.status;
          if (status == "success") {
            this.$MSG.API_popmsg(res.msg, "wallet", "success");
          } else {
            this.$MSG.API_popmsg(res.msg, "", "error");
          }
        });
      } else this.flag_pay_error = true;
    },
    betstock_fun: function () {
      let param = {
        action: "betstock_item",
        body: {
          uid: localStorage.getItem("uid"),
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        var status = res.status;
        if (status == "success") {
          this.stockList = res.data;
          this.hot_list = this.temp_fun("HOT SHARE");
        }
      });
    },
    buy_betstock_item: function () {
      if (this.payment != 0 && this.payment != "" && this.payment != null) {
        let param = {
          action: "betstock_buy",
          body: {
            uid: localStorage.getItem("uid"),
            session_code: Cookies.get("code"),
            mpkidx: this.cou_mpkidx,
            payment: this.cou_price,
            unit: this.payment,
            withdrawpw: this.withdrawpw,
          },
        };
        this.$store.dispatch("getpost", param).then((res) => {
          var status = res.status;
          if (status == "success") {
            this.$MSG.API_popmsg(res.msg, "wallet", "success");
          } else {
            this.$MSG.API_popmsg(res.msg, "", "error");
          }
        });
      } else this.flag_pay_error = true;
    },
    temp_fun: function (Gamestype) {
      return this.stockList.filter((i) => {
        return i["tag"] == Gamestype;
      });
    },
    more: function () {
      this.list_index = this.list.length;
    },
    fun_who_which: function () {
      this.who_which == "betstock"
        ? this.buy_betstock_item()
        : this.buy_sharebonus_item();
    },
  },
  mounted() {},
  created() {
    this.sharebonus_item();
    this.betstock_fun();
  },
  watch: {
    payment: function (e) {
      if (!Number(e)) {
        this.flag_pay_error = true;
      } else this.flag_pay_error = false;
    },
  },
};
</script>
