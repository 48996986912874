<template>
  <div class="wc-PageView wc_message">
    <div class="wc-PageView_ContentContainer wc-ModulePage_ContentContainer">
      <div class="message_second" style="padding: 0 20px">
        <div>
          <div class="nh-NavigationHeaderModule nh-NavigationHeaderModule-wide">
            <div class="nh-NavigationHeaderModule_Title">
              {{ $t("message1") }}
            </div>
          </div>
        </div>
        <div>
          <div class="sticky-header">
            <div class="sticky-header-inner">
              <div class="nav-wrapper">
                <ul class="nav">
                  <li
                    class="nav-item"
                    :class="this.day == 7 ? 'nav-item-selected first' : ''"
                    @click="this.day = 7"
                  >
                    <div>
                      <span>{{ $t("week") }}</span>
                    </div>
                  </li>

                  <li
                    class="nav-item"
                    :class="this.day == 30 ? 'nav-item-selected first' : ''"
                    @click="this.day = 30"
                  >
                    <div>
                      <a>{{ $t("activity_30") }}</a>
                    </div>
                  </li>

                  <li
                    class="nav-item last"
                    :class="this.day == 365 ? 'nav-item-selected first' : ''"
                    @click="this.day = 365"
                  >
                    <div>
                      <a>{{ $t("activity_365") }}</a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="d-flex my-2 justify-content-end">
            <div
              class="sc-18mcksl-1"
              style="background-color: red; padding: 8px; cursor: pointer"
              @click="delete_msg"
            >
              <img
                src="@/assets/img/main/delete.png"
                style="width: 23px; height: 23px"
              />
              <span style="font-size: 14px">{{ $t("text_delete") }}</span>
            </div>
          </div>
          <div class="inb-MessagingInboxModule">
            <div class="mli-MessageList mli-MessageList-wide">
              <div class="mli-MessageSummary mli-MessageSummary_Read">
                <div
                  class="mli-MessageSummary_InfoContainer"
                  v-for="(l, index) in list"
                  :key="index"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :value="l.id"
                    v-model="list_delete_msg"
                  />
                  <div class="row">
                    <div class="col-6">{{ l.subject }}</div>
                    <div class="col-6 text-end">
                      {{ l.cdate1 }} {{ l.cdate2 }}
                    </div>
                    <div class="mli-MessageSummary_SubjectLine mt-2">
                      {{ l.body }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";

export default {
  name: "Home",
  computed: {},
  data() {
    return {
      list: [],
      day: 30,
      list_delete_msg: [],
    };
  },
  methods: {
    msg: function (d) {
      this.list = [];
      let param = {
        action: "message1",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
          day: d,
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        res = JSON.parse(JSON.stringify(res).replaceAll("@", "<br/>"));
        if (res["data"] != "" && res["data"] != null) {
          this.list = res["data"].filter((v, i) => {
            if (i < 20) {
              return v;
            }
          });
        }
      });
    },
    delete_msg: function () {
      let param = {
        action: "message_delete",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
          id: this.list_delete_msg,
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        this.$MSG.API_popmsg(res.status, "message", "success");
      });
    },
  },
  watch: {
    day: function (e) {
      this.msg(e);
    },
  },
  created() {
    this.msg("30");
  },
};
</script>
