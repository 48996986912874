<template>
  <div class="jackpot_game new_share">
    <div class="d-flex justify-content-start align-items-center">
      <img
        src="@/assets/img/left/share-icon.png"
        :title="$t('left_menu_text4')"
        style="width: 50px"
      />
      <span class="jackpot_rank_title ms-2">{{ $t("left_menu_text4") }}</span>
    </div>

    <div
      class="d-flex justify-content-start align-items-center mt-4 flex-767-column"
    >
      <div class="affiliate-code-card-input-holder me-3">
        <label>{{ $t("share_invite_code") }}： </label>
      </div>
      <div class="d-flex justify-content-start align-items-center flex-1">
        <div class="br-1 py-1 me-2">{{ sharecode }}</div>
        <button @click="Copy('code')" class="py-1 agent_detail_btn text-nowrap">
          {{ $t("text_copy") }}
        </button>
      </div>
    </div>
    <div
      class="d-flex justify-content-start align-items-center mt-4 flex-767-column"
    >
      <div class="affiliate-code-card-input-holder me-3">
        <label>{{ $t("share_invite_url") }}： </label>
      </div>
      <div class="d-flex justify-content-start align-items-center flex-1">
        <div class="br-1 py-1 me-2">{{ shareurl }}</div>
        <button @click="Copy('url')" class="py-1 agent_detail_btn text-nowrap">
          {{ $t("text_copy") }}
        </button>
      </div>
    </div>

    <share_history class="mt-5" />
  </div>
</template>
<script>
import share_history from "./share_history.vue";
export default {
  name: "new_share",
  data() {
    return {
      sharecode: "132456789132",
      shareurl: "132456789132456789132456789",
    };
  },
  created() {},
  methods: {
    Copy: function (e) {
      var content = e == "code" ? this.sharecode : this.shareurl;
      this.$copyText(content).then(
        () => {
          this.$MSG.API_popmsg(
            this.$t("text_copied") + "<br/>" + content,
            "",
            "success"
          );
        },
        (e) => {
          this.$MSG.API_popmsg(
            this.$t("text_notcopy") + "： " + e.text,
            "",
            "error"
          );
        }
      );
    },
  },
  watch: {},
  computed: {},
  components: { share_history },
  mounted() {},
};
</script>
