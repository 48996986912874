<template>
  <div class="home_game_arrow home_game_casino home_game_hot">
    <div class="mt-3">
      <div class="p-2 bg-blue" :class="'home_game_' + show_rank_id">
        <div class="d-flex justify-content-between">
          <div class="game_logo_img fw-bolder my-3">
            {{
              show_rank_id.toUpperCase() == "RECENTBIG"
                ? $t("text_recentbig").toUpperCase()
                : show_rank_id.toUpperCase() == "FISH"
                ? $t("three_rank_text2").toUpperCase()
                : show_rank_id.toUpperCase() == "RTP"
                ? $t("three_rank_text3").toUpperCase()
                : ""
            }}
          </div>
          <div class="top_arrow">
            <div class="left_right">
              <button class="btn_arrow" @click="turn(show_rank_id, 'left')">
                <span role="img" aria-label="left"
                  ><svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="left"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"
                    ></path>
                  </svg>
                </span></button
              ><button class="btn_arrow" @click="turn(show_rank_id, 'right')">
                <span role="img" aria-label="right"
                  ><svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="right"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"
                    ></path>
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
        <swiper
          :loop="swiper_casino_options.loop"
          :autoplay="swiper_casino_options.autoplay"
          :speed="swiper_casino_options.speed"
          :slidesPerView="swiper_casino_options.slidesPerView"
          :spaceBetween="swiper_casino_options.spaceBetween"
          :breakpoints="swiper_casino_options.breakpoints"
          :navigation="swiper_casino_options.navigation"
        >
          <swiper-slide
            class="home_game_img"
            v-for="l in which_swip_type"
            :key="l"
          >
            <div class="home_game_img_div">
              <div class="">
                <img v-lazy="l.iconName" class="w-100" style="height: 150px" />
                <div
                  v-if="
                    show_rank_id.toLowerCase() == 'recentbig' ||
                    show_rank_id.toLowerCase() == 'fish'
                  "
                  class="ps-2 pe-1 pt-3"
                >
                  <p>{{ Math.ceil(Math.random() * 10000 + 10000) + "***" }}</p>
                  <p class="ft-green fw-bolder">
                    {{ $t("cur") + " " + format_num() }}
                  </p>
                </div>
                <div
                  v-if="show_rank_id.toLowerCase() == 'rtp'"
                  class="ps-2 pe-1 pt-3"
                >
                  <p>
                    {{ $t("text_rtp") }}：

                    <span class="text-danger">{{ rtp_random() }} %</span>
                  </p>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { reactive } from "vue";
//引入swiper
import SwiperCore, { Autoplay, EffectCoverflow, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.scss";
//设置swiper
SwiperCore.use([Autoplay, EffectCoverflow, Navigation]);
export default {
  props: { show_rank_id: String },
  setup() {
    //指定swiper的设置
    let swiper_casino_options = reactive({
      loop: false,
      autoplay: {
        delay: 2000,
        disableOnInteraction: false,
      },
      speed: 500,
      slidesPerView: "auto",
      navigation: {
        nextElRef: ".swiper-button-next",
        prevElRef: ".swiper-button-prev",
      },
    });

    return {
      swiper_casino_options,
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions(["getGameList"]),
    turn(id, e) {
      if (e == "left") {
        document
          .querySelector(".home_game_" + id + " .swiper-button-prev")
          .click();
      } else {
        document
          .querySelector(".home_game_" + id + " .swiper-button-next")
          .click();
      }
    },
    gamelist: function (t) {
      var action_val = "GameLobby";
      if (t.toLowerCase() == "recentbig" || t.toLowerCase() == "rtp")
        t = "RANDOM";
      else if (t.toLowerCase() == "fish") t = "FISH";
      let param = {
        action: action_val,
        body: {
          gametype: t,
          type: "all",
        },
      };
      this.getGameList(param);
    },
    rtp_random: function () {
      var ran = Math.ceil(Math.random() * (120 - 97) + 97);
      return ran;
    },
    format_num: function () {
      var ran = Math.ceil(Math.random() * 10100000 + 5000);
      return this.filter_number(ran);
    },
    filter_number: function (value) {
      if (!value) return 0;
      const valueArray = value.toString().split(".");
      let regex = /(\d)(?=(?:\d{3})+$)/g;
      if (valueArray.length === 2) {
        const intPart = valueArray[0].toString().replace(regex, "$1,");
        let floatPart = "";
        floatPart = valueArray[1].toString();
        return intPart + "." + floatPart;
      } else {
        const intPart = value.toString().replace(regex, "$1,");
        return intPart;
      }
    },
    rank_arr: function (arr) {
      if (arr != null && arr != "") {
        for (let i = arr.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [arr[i], arr[j]] = [arr[j], arr[i]]; // swap
        }
        return arr;
      } else return [];
    },
  },
  watch: {},
  mounted() {},
  created() {
    this.gamelist(this.show_rank_id);
  },
  computed: {
    ...mapGetters(["list"]),
    which_swip_type: function () {
      if (this.list != null && this.list != "") {
        if (
          this.show_rank_id.toLowerCase() == "recentbig" &&
          localStorage.getItem("random_all_gamelist") != null &&
          localStorage.getItem("random_all_gamelist") != ""
        ) {
          return this.rank_arr(
            JSON.parse(localStorage.getItem("random_all_gamelist")).slice(0, 19)
          );
        } else if (this.show_rank_id.toLowerCase() == "fish")
          return this.rank_arr(
            JSON.parse(localStorage.getItem("fish_all_gamelist"))
          );
        else if (
          this.show_rank_id.toLowerCase() == "rtp" &&
          localStorage.getItem("random_all_gamelist") != null &&
          localStorage.getItem("random_all_gamelist") != ""
        ) {
          return this.rank_arr(
            JSON.parse(localStorage.getItem("random_all_gamelist")).slice(
              20,
              39
            )
          );
        } else return [];
      } else return [];
    },
  },
};
</script>
