import store from "@/store/index.js";

const resPonseStatus = (res) => {
  var state = false;
  if (String(res).length > 0) {
    state = true;
  }
  return state;
};
const API_popmsg = (msg, type, icon_who) => {
  if (icon_who == "success") {
    icon_who = "success";
  } else if (icon_who == "fail" || icon_who == "error") {
    icon_who = "error";
  } else {
    icon_who = "warning";
  }

  let popinfo = {
    isLoading: true,
    data: {
      action: icon_who,
      msg: msg,
      class: "api",
      type: type,
    },
  };
  store.commit("Pop", popinfo);
};
export default {
  resPonseStatus,
  API_popmsg,
};
