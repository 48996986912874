import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";
import Cookies from "js-cookie";
import store from "../store";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/:id",
    // name: "igaming",
    component: Home,
  },
  {
    path: "/:id/:type",
    component: Home,
  },
  {
    path: "/:id/:type/:sub_type",
    component: Home,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  if (
    Cookies.get("code") == null ||
    Cookies.get("code") == "" ||
    localStorage.getItem("uid") == null ||
    localStorage.getItem("uid") == ""
  ) {
    if (
      to.params.id == "share" ||
      to.params.id == "ME" ||
      to.params.id == "BO" ||
      to.params.id == "VIP" ||
      to.params.id == "club" ||
      to.params.id == "home_vip"
    ) {
      next({ path: "/" });
      store.commit("islogin", true);
      store.dispatch("logout_public");
    } else if (to.path == "/slot/guess") {
      next({ path: "/" });
    } else next();
  } else {
    if (to.params.id == "club" || to.params.id == "home_vip") {
      if (
        localStorage.getItem("utypeid") == null ||
        localStorage.getItem("utypeid") == "" ||
        localStorage.getItem("utypeid") == 10
      )
        next({ path: "/" });
      else next();
    } else next();
  }
});
export default router;
