<template>
  <div>
    <div
      v-if="filterSearch.length != 0"
      class="d-lg-flex justify-content-lg-center flex-lg-column align-items-lg-center"
    >
      <!-- <div class="d-flex mt-2 justify-content-center w-100">
        <div
          class="py-2 row overflow-y-auto w-100 align-content-start"
          style="height: 50vh; gap: 15px"
        >
          <div
            class="py-2 mb-1 d-flex justify-content-center align-items-center member_item c-pointer"
            v-for="(l, k) in filterSearch"
            :key="k + 1"
            style="height: 150px; border: 1px solid #5f5f5f"
            :class="sele_index == k + 1 ? 'bg-counter-sele' : ''"
            @click="
              sele_index = k + 1;
              select_arr = l;
            "
          >
            <img
              src="@/assets/img/d_club/ic5.png"
              style="
                border-radius: 100%;
                width: 75px;
                height: 75px;
                border: 1px solid #fff;
              "
              class="p-2"
            />
            <div class="d-flex flex-column">
              <div class="ms-3">
                <p class="fs-18">{{ $t("text_king8") }}：{{ l.userid }}</p>
                <p class="fs-18 mb-0">{{ $t("text_king9") }}：{{ l.name }}</p>
              </div>

              <div class="ms-3 mt-3">
                <div class="d-flex align-items-center">
                  <div class="d-flex position-relative">
                    <img
                      src="@/assets/img/d_club/img_player-chip.png"
                      style="
                        width: 45px;
                        position: absolute;
                        top: -2px;
                        left: -10px;
                      "
                    />
                    <div class="bg_drak_coff radius-25 text-white fw-bold">
                      <span class="ps-5 px-3 club_span1 club_span3">{{
                        l.balance
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <div class="w-100 p-3" style="background: #071d2a; border-radius: 16px">
        <div style="height: 45vh; overflow-y: auto">
          <div class="new_data new_data_3 w-100 align-content-start d-grid">
            <div
              class="new_data-div"
              v-for="(l, k) in filterSearch"
              :key="k + 1"
              :class="sele_index == k + 1 ? 'bg-counter-sele' : ''"
              @click="
                sele_index = k + 1;
                select_arr = l;
              "
            >
              <div class="ms-3">
                <p class="fs-18">{{ $t("text_king8") }}：{{ l.userid }}</p>
                <p class="fs-18 mb-0">{{ $t("text_king9") }}：{{ l.name }}</p>
              </div>

              <div class="mt-3 d-flex justify-content-center align-items-cente">
                <div class="d-flex position-relative">
                  <img
                    src="@/assets/img/d_club/img_player-chip.png"
                    style="
                      width: 45px;
                      position: absolute;
                      top: -2px;
                      left: -10px;
                    "
                  />
                  <div class="bg_drak_coff radius-25 text-white fw-bold">
                    <span class="ps-5 px-3 club_span1 club_span3">{{
                      l.balance
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="div-header">
          <span>{{ $t("text_king8") }}</span>
          <span>{{ $t("text_king9") }}</span>
          <span>{{ $t("text_player_coin") }}</span>
        </div>
        <div
          class="div-container"
          style="
            height: 45vh;
            overflow-y: auto;
            border-bottom: 15px solid #18222d;
            border-radius: 16px;
          "
        >
          <div
            class="content1"
            v-for="(l, k) in 15"
            :key="k + 1"
            :class="sele_index == k + 1 ? 'bg-counter-sele' : ''"
            @click="
              sele_index = k + 1;
              select_arr = l;
            "
          >
            <span class="word-break">{{ 123456798012 }}</span>
            <span class="word-break">{{ 123456798012 }}</span>
            <span>
              <div class="">
                <div
                  class="d-flex position-relative justify-content-center align-items-center"
                >
                  <img
                    src="@/assets/img/d_club/img_player-chip.png"
                    style="width: 35px"
                  />
                  <div class="radius-25 text-white fw-bold">
                    <span class="ps-1 club_span3">{{ 100000 }}</span>
                  </div>
                </div>
              </div>
            </span>
          </div>
        </div> -->
      </div>

      <div
        class="d-flex justify-content-evenly col-lg-6 mt-4 px-4"
        style="gap: 15px"
      >
        <button
          class="btn mb-3 take-btn take1-btn opacity-100"
          :style="
            sele_index == 0 ? 'cursor: no-drop;background-color: #CCC;' : ''
          "
          :disabled="sele_index == 0"
          @click="
            action = 'take';
            flag_prewin = true;
          "
        >
          {{ $t("text_counter5") }}
        </button>
        <button
          class="btn mb-3 take-btn give-btn opacity-100"
          :style="
            sele_index == 0 ? 'cursor: no-drop;background-color: #CCC;' : ''
          "
          :disabled="sele_index == 0"
          @click="
            action = 'give';
            flag_prewin = true;
          "
        >
          {{ $t("text_counter6") }}
        </button>
      </div>
    </div>
    <div
      v-else
      class="d-flex justify-content-center flex-column align-items-center mt-5"
    >
      <h5>{{ $t("nodata") }}</h5>
    </div>

    <div class="finance_page deposit_temp__page" v-if="flag_prewin">
      <div class="finance_card" style="">
        <div class="finance_close" @click="this.flag_prewin = false">×</div>
        <div
          class="finance_title"
          :class="action.toLowerCase() == 'take' ? 'take1-btn' : ''"
        >
          {{
            action.toLowerCase() == "take"
              ? $t("text_counter5")
              : $t("text_counter6")
          }}
        </div>
        <form method="POST" @submit="coin_adjust">
          <div class="finance_main">
            <div class="finance_deposit" style="margin-top: 1rem">
              <div>
                <div class="d-flex position-relative w-1024-100">
                  <img
                    src="@/assets/img/d_club/img_club-cash.png"
                    style="width: 40px; position: absolute; top: 0px; left: 0px"
                    class="me-2"
                  />
                  <div class="bg_drak_coff radius-25 text-white fw-bold">
                    <span class="ps-5 px-3 club_span1">{{
                      master_balance_hall
                    }}</span>
                  </div>
                </div>

                <div class="form-floating mt-2">
                  <input
                    type="number"
                    autocomplete="on"
                    tabindex="0"
                    placeholder="  "
                    v-model="amount"
                    required="required"
                    id="uid"
                    class="form-control text-secondary-new"
                    style="
                      border: 1px solid #fff;
                      padding-left: 25px;
                      background-color: transparent;
                      color: #fff !important;
                    "
                    min="1"
                  />
                  <label
                    for="uid"
                    class="text-secondary ps-0"
                    style="
                      background-color: transparent;
                      color: #fff !important;
                    "
                    >{{ $t("text_counter7") }}</label
                  >
                </div>

                <div
                  class="py-2 d-flex justify-content-start align-items-center"
                  style="border-bottom: 1px solid #fff"
                >
                  <!-- <img
                    src="@/assets/img/d_club/ic5.png"
                    style="
                      border-radius: 100%;
                      width: 75px;
                      height: 75px;
                      border: 1px solid #fff;
                    "
                    class="p-2"
                  /> -->
                  <div class="d-flex flex-column">
                    <div class="ms-3">
                      <p class="fs-18">
                        {{ $t("text_king8") }}：{{ select_arr.userid }}
                      </p>
                      <p class="fs-18 mb-0">
                        {{ $t("text_king9") }}：{{ select_arr.name }}
                      </p>
                    </div>

                    <div class="ms-3 mt-3">
                      <div class="d-flex align-items-center">
                        <div class="d-flex position-relative">
                          <img
                            src="@/assets/img/d_club/img_player-chip.png"
                            style="
                              width: 40px;
                              position: absolute;
                              top: 0px;
                              left: -3px;
                            "
                          />
                          <div class="radius-25 text-white fw-bold">
                            <span
                              class="ps-5 px-3 club_span1 club_span3 text_dark_coff"
                              >{{ select_arr.balance }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p class="fs-18 text-center mt-5">
                {{ $t("text_total") }}：<span class="fw-bolder text-new-gold">{{
                  amount
                }}</span>
              </p>
              <div class="col-10 text-white mx-auto my-3">
                <button
                  class="border-0 text-white p-2 w-100 bg_light_green_btn"
                  :class="action.toLowerCase() == 'take' ? 'take1-btn' : ''"
                >
                  <span>{{ $t("text_counter8") }}</span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import { mapGetters } from "vuex";
export default {
  props: { keyWord: String },
  computed: {
    ...mapGetters(["master_balance_hall", "member_total_coin"]),
    filterSearch() {
      return this.list_y.filter((v) => {
        if (this.keyWord.length > 0) {
          return v.userid.toLowerCase().includes(this.keyWord.toLowerCase());
        } else return v;
      });
    },
  },
  data() {
    return {
      code: Cookies.get("code"),
      sele_index: 0,
      action: "take",
      list_y: [],
      flag_prewin: false,
      amount: 0,
      select_arr: [],
    };
  },
  methods: {
    get_member_y() {
      if (
        Cookies.get("code") == null ||
        Cookies.get("code") == "" ||
        localStorage.getItem("uid") == null ||
        localStorage.getItem("uid") == ""
      ) {
        this.$store.commit("islogin", true);
        this.$store.dispatch("logout_public");
      } else {
        let param = {
          action: "all_club_member",
          body: {
            uid: localStorage.getItem("uid"),
            session_code: Cookies.get("code"),
            clubid: localStorage.getItem("clubid"),
            userid: "",
            status: "Y",
            orderby: "3",
          },
        };
        this.$store.dispatch("getpost", param).then((res) => {
          if (res.status == "success") {
            this.list_y = res.member;

            this.$store.commit("master_balance_hall", res.master_balance);
            this.$store.commit("member_total_coin", res.all_member_balance);
          }
        });
      }
    },
    coin_adjust(e) {
      e.preventDefault();
      if (
        Cookies.get("code") == null ||
        Cookies.get("code") == "" ||
        localStorage.getItem("uid") == null ||
        localStorage.getItem("uid") == ""
      ) {
        this.$store.commit("islogin", true);
        this.$store.dispatch("logout_public");
      } else {
        let param = {
          action: "coin_adjust",
          body: {
            uid: localStorage.getItem("uid"),
            session_code: Cookies.get("code"),
            clubid: localStorage.getItem("clubid"),
            userid: this.select_arr.userid,
            amount: this.amount,
            type:
              this.action.toLowerCase() == "take"
                ? "1"
                : this.action.toLowerCase() == "give"
                ? "2"
                : "",
          },
        };
        this.$store.dispatch("getpost", param).then((res) => {
          if (res.status == "success") {
            this.flag_prewin = false;
            this.$MSG.API_popmsg(res.msg, "", "success");
            this.get_member_y();
          } else this.$MSG.API_popmsg(res.msg, "", "warning");
        });
      }
    },
  },
  components: {},
  watch: {},
  created() {
    this.get_member_y();
  },
};
</script>
