<template>
  <div id="salary">
    <div class="mt-xl-3 mt-1 border_eee box_shadow p-2">
      <div class="d-flex w-100">
        <div class="w-100">
          <select
            class="form-select w-xl-25 mx-auto me-0 mt-2 mb-3 border_eee"
            id="type_select"
            @change="type_change"
            v-model="type_select"
          >
            <option value="1" selected>{{ $t("share_bonus_select1") }}</option>
            <option value="2">{{ $t("share_bonus_select2") }}</option>
            <option value="3">{{ $t("share_bonus_select3") }}</option>
          </select>
          <div class="invitedperson_table GbTable_root__aRhLC border_eee">
            <table
              style="table-layout: auto"
              class="w-100 GbTable_table__moqvH text-center border_eee"
              cellpadding="10"
            >
              <thead class="ant-table-thead">
                <tr>
                  <th>{{ $t("text_date") }}</th>
                  <th>{{ $t("share_bonus_user") }}</th>
                  <th>{{ $t("share_bonus_reward") }}</th>
                  <th>{{ $t("share_bonus_bonus") }}</th>
                </tr>
              </thead>
              <tbody
                class="ant-table-tbody"
                v-show="this.list.length == null || this.list.length == 0"
              >
                <tr>
                  <td class="" colspan="4" style="padding-top: 30px">
                    <img
                      class=""
                      src="@/assets/img/invite/37CDF956FB99616BA166DC17329BEFCA.svg"
                      alt=""
                    />
                    <p class="">{{ $t("nodata") }}</p>
                  </td>
                </tr>
              </tbody>
              <tbody
                class="ant-table-tbody"
                v-show="this.list.length != null && this.list.length > 0"
                v-for="l in list"
                :key="l"
              >
                <tr>
                  <td class="">{{ l.date }}</td>
                  <td class="">{{ l.userid }}</td>
                  <td class="">{{ l.type }}</td>
                  <td class="">{{ l.bonus }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";

export default {
  name: "bonus",
  computed: {},
  data() {
    return {
      code: Cookies.get("code"),
      type: 1,
      list: [],
    };
  },
  methods: {
    bonus: function () {
      let param = {
        action: "bonus",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
          type: this.type,
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        var status = res["status"];
        if (status == "success") {
          this.list = res["data"];
        }
      });
    },
    type_change: function () {
      this.type = this.type_select;
      if (
        Cookies.get("code") != null &&
        Cookies.get("code") != "" &&
        localStorage.getItem("uid") != null &&
        localStorage.getItem("uid") != ""
      ) {
        this.bonus();
      }
    },
  },
  created() {
    this.type_select = this.type;
    if (
      Cookies.get("code") != null &&
      Cookies.get("code") != "" &&
      localStorage.getItem("uid") != null &&
      localStorage.getItem("uid") != ""
    ) {
      this.bonus();
    }
  },
};
</script>
