<template>
  <div class="mb-5">
    <p style="font-size: 18px" class="px-2 p-xl-3 mb-0 pb-xl-1">
      {{ $t("set_game_text1") }}
    </p>
    <p style="font-size: 18px" class="px-2 p-xl-3">
      {{ $t("set_game_text2") }}
    </p>
    <div class="d-grid grid-6">
      <div class="h-100 position-relative c-pointer" @click="Setgame(1)">
        <img
          :src="require('@/assets/img/new_img/check.png')"
          class="position-absolute"
          style="width: 30px; right: -7px; top: -9px"
          v-if="station_game_set == 1"
        />
        <div style="border: 2px solid #fff" class="px-2 py-3 h-100">
          <p class="text-center mb-4 fw-bolder ft-set-18 mb-768-2">
            {{ $t("text_combination_1") }}
          </p>
          <div class="d-grid grid-set-3">
            <div class="d-flex justify-content-center align-items-center">
              <img
                src="@/assets/img/game_category/icon-live.png"
                :title="$t('home_text6_1')"
                class="set_img me-3"
              />
              <a class="nav-link fw-bold">{{ $t("home_text6_1") }}</a>
            </div>
            <div class="d-flex justify-content-center align-items-center mt-4">
              <img
                src="@/assets/img/game_category/icon-slot.png"
                :title="$t('home_text7_1')"
                class="set_img me-3"
              />
              <a class="nav-link fw-bold">{{ $t("home_text7_1") }}</a>
            </div>
            <div class="d-flex justify-content-center align-items-center mt-4">
              <img
                src="@/assets/img/game_category/ic2_hot.png"
                :title="$t('text_main11_1')"
                class="set_img me-3"
              />
              <a class="nav-link fw-bold">{{ $t("text_main11_1") }}</a>
            </div>
            <div class="d-flex justify-content-center align-items-center mt-4">
              <img
                src="@/assets/img/game_category/icon-sport.png"
                :title="$t('text_sport_1')"
                class="set_img me-3"
              />
              <a class="nav-link fw-bold">{{ $t("text_sport_1") }}</a>
            </div>
            <div class="d-flex justify-content-center align-items-center mt-4">
              <img
                src="@/assets/img/game_category/icon-fish.png"
                :title="$t('text_main12_1')"
                class="set_img me-3"
              />
              <a class="nav-link fw-bold">{{ $t("text_main12_1") }}</a>
            </div>
            <div class="d-flex justify-content-center align-items-center mt-4">
              <img
                src="@/assets/img/game_category/icon-g-chess.png"
                :title="$t('text_chess_1')"
                class="set_img me-3"
              />
              <a class="nav-link fw-bold">{{ $t("text_chess_1") }}</a>
            </div>
            <div class="d-flex justify-content-center align-items-center mt-4">
              <img
                src="@/assets/img/game_category/icon-area.png"
                :title="$t('text_area_1')"
                class="set_img me-3"
              />
              <a class="nav-link fw-bold">{{ $t("text_area_1") }}</a>
            </div>
            <div class="d-flex justify-content-center align-items-center mt-4">
              <img
                src="@/assets/img/game_category/icon-lotto.png"
                :title="$t('text_lotto_1')"
                class="set_img me-3"
              />
              <a class="nav-link fw-bold">{{ $t("text_lotto_1") }}</a>
            </div>
            <div class="d-flex justify-content-center align-items-center mt-4">
              <img
                src="@/assets/img/game_category/poker.png"
                :title="$t('text_poker_game')"
                class="set_img me-3"
              />
              <a class="nav-link fw-bold">{{ $t("text_poker_game") }}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="h-100 position-relative c-pointer" @click="Setgame(2)">
        <img
          :src="require('@/assets/img/new_img/check.png')"
          class="position-absolute"
          style="width: 30px; right: -7px; top: -9px"
          v-if="station_game_set == 2"
        />
        <div style="border: 2px solid #fff" class="px-2 py-3 h-100">
          <p class="text-center mb-4 fw-bolder ft-set-18">
            {{ $t("text_combination_2") }}
          </p>
          <div class="d-flex justify-content-center align-items-center">
            <img
              src="@/assets/img/game_category/icon-live.png"
              :title="$t('home_text6_1')"
              class="set_img me-3"
            />
            <a class="nav-link fw-bold">{{ $t("home_text6_1") }}</a>
          </div>
        </div>
      </div>
      <div class="h-100 position-relative c-pointer" @click="Setgame(3)">
        <img
          :src="require('@/assets/img/new_img/check.png')"
          class="position-absolute"
          style="width: 30px; right: -7px; top: -9px"
          v-if="station_game_set == 3"
        />
        <div style="border: 2px solid #fff" class="px-2 py-3 h-100">
          <p class="text-center mb-4 fw-bolder ft-set-18">
            {{ $t("text_combination_3") }}
          </p>
          <div class="d-flex justify-content-center align-items-center">
            <img
              src="@/assets/img/game_category/icon-slot.png"
              :title="$t('home_text7_1')"
              class="set_img me-3"
            />
            <a class="nav-link fw-bold">{{ $t("home_text7_1") }}</a>
          </div>
          <div class="d-flex justify-content-center align-items-center mt-4">
            <img
              src="@/assets/img/game_category/icon-fish.png"
              :title="$t('text_main12_1')"
              class="set_img me-3"
            />
            <a class="nav-link fw-bold">{{ $t("text_main12_1") }}</a>
          </div>
          <div class="d-flex justify-content-center align-items-center mt-4">
            <img
              src="@/assets/img/game_category/icon-g-chess.png"
              :title="$t('text_chess_1')"
              class="set_img me-3"
            />
            <a class="nav-link fw-bold">{{ $t("text_chess_1") }}</a>
          </div>
          <div class="d-flex justify-content-center align-items-center mt-4">
            <img
              src="@/assets/img/game_category/icon-area.png"
              :title="$t('text_area_1')"
              class="set_img me-3"
            />
            <a class="nav-link fw-bold">{{ $t("text_area_1") }}</a>
          </div>
        </div>
      </div>
      <div class="h-100 position-relative c-pointer" @click="Setgame(4)">
        <img
          :src="require('@/assets/img/new_img/check.png')"
          class="position-absolute"
          style="width: 30px; right: -7px; top: -9px"
          v-if="station_game_set == 4"
        />
        <div style="border: 2px solid #fff" class="px-2 py-3 h-100">
          <p class="text-center mb-4 fw-bolder ft-set-18">
            {{ $t("text_combination_4") }}
          </p>
          <div class="d-flex justify-content-center align-items-center">
            <img
              src="@/assets/img/game_category/icon-sport.png"
              :title="$t('text_sport_1')"
              class="set_img me-3"
            />
            <a class="nav-link fw-bold">{{ $t("text_sport_1") }}</a>
          </div>
        </div>
      </div>
      <div class="h-100 position-relative c-pointer" @click="Setgame(5)">
        <img
          :src="require('@/assets/img/new_img/check.png')"
          class="position-absolute"
          style="width: 30px; right: -7px; top: -9px"
          v-if="station_game_set == 5"
        />
        <div style="border: 2px solid #fff" class="px-2 py-3 h-100">
          <p class="text-center mb-4 fw-bolder ft-set-18">
            {{ $t("text_combination_5") }}
          </p>
          <div class="d-flex justify-content-center align-items-center">
            <img
              src="@/assets/img/game_category/icon-lotto.png"
              :title="$t('text_lotto_1')"
              class="set_img me-3"
            />
            <a class="nav-link fw-bold">{{ $t("text_lotto_1") }}</a>
          </div>
        </div>
      </div>
      <div class="h-100 position-relative c-pointer" @click="Setgame(6)">
        <img
          :src="require('@/assets/img/new_img/check.png')"
          class="position-absolute"
          style="width: 30px; right: -7px; top: -9px"
          v-if="station_game_set == 6"
        />
        <div style="border: 2px solid #fff" class="px-2 py-3 h-100">
          <p class="text-center mb-4 fw-bolder ft-set-18">
            {{ $t("text_combination_6") }}
          </p>
          <div class="d-flex justify-content-center align-items-center">
            <img
              src="@/assets/img/game_category/poker.png"
              :title="$t('text_poker_game')"
              class="set_img me-3"
            />
            <a class="nav-link fw-bold">{{ $t("text_poker_game") }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
export default {
  data() {
    return {
      station_game_set: -1,
    };
  },
  computed: {},
  methods: {
    Setgame: function (e) {
      let param = {
        action: "Update_Game_vip",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
          game: e,
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        if (res.status == "success") {
          this.station_game_set = e;
          this.$MSG.API_popmsg(res.msg, "", "success");
        } else {
          this.$MSG.API_popmsg(res.msg, "", "error");
        }
      });
    },
    get_info: function () {
      let param = {
        action: "Set_Game_vip",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        this.station_game_set = res.game;
      });
    },
  },
  watch: {},
  created() {
    this.get_info();
  },
};
</script>
