<template>
  <div class="casino-header-container-new new_loan">
    <div
      class="d-flex justify-content-between align-items-center position-relative"
    >
      <span class="text-center club_span_big ms-0">
        {{ $t("text_club14") }}
      </span>
      <p class="text-center mb-0">{{ begin }} ～ {{ end }}</p>
    </div>

    <div
      class="mt-2 d-flex justify-content-start align-items-center flex-1023-column"
    >
      <div
        class="d-flex justify-content-start align-items-center flex-1023-column w-100"
      >
        <div
          class="serach_date_card-new d-flex align-items-center me-3 me-1023-3 w-100"
        >
          <div class="dateControlLabel">{{ $t("history_begin") }}:</div>
          <input type="date" v-model="old_begin" Style="padding: 15px;" />
        </div>
        <div
          class="serach_date_card-new d-flex align-items-center me-3 me-1023-3 w-100"
        >
          <div class="dateControlLabel">{{ $t("history_end") }}:</div>
          <input type="date" v-model="old_end" Style="padding: 15px;" />
        </div>
      </div>
      <div
        class="show-history-button rounded-5 bg_green_btn m-0 btn-ft w-100"
        @click="get_data()"
      >
        {{ $t("text_search") }}
      </div>
    </div>

    <div
      class="mt-3 w-100 p-3"
      style="background: #071d2a; border-radius: 16px"
    >
      <div style="height: 45vh; overflow-y: auto">
        <div class="new_data new_data_3 w-100 align-content-start d-grid">
          <div class="new_data-div" v-for="l in list" :key="l">
            <div class="text-end">
              <p class="fs-18">{{ $t("text_date") }}： {{ l.cdate }}</p>
              <!-- <p class="fs-18 mb-0">
                {{ $t("text_king9") }}：{{ 123456798012 }}
              </p> -->
            </div>
            <div
              class="d-flex justify-content-between align-items-center flex-1024-column"
              style="gap: 8px"
            >
              <div class="" style="flex: 1">
                <p class="fs-18">
                  {{ $t("text_club16") }}：
                  <span class="">{{ l.losewin }}</span>
                </p>
                <p class="fs-18">
                  {{ $t("text_club17") }}：
                  <span class="">{{ l.turnover }}</span>
                </p>
                <p class="fs-18 mb-0">
                  {{ $t("text_club18") }}：
                  <span class="">{{ l.fee }}</span>
                </p>
              </div>

              <div class="" style="flex: 1">
                <p class="fs-18">
                  {{ $t("text_club19") }}：
                  <span class="">{{ l.deposit }}</span>
                </p>
                <p class="fs-18">
                  {{ $t("text_club20") }}：
                  <span class="">{{ l.withdraw }}</span>
                </p>
                <p class="fs-18 mb-0">
                  {{ $t("text_club21") }}：
                  <span class="">{{ l.income }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import dateTime from "@/components/utils/datatime";
export default {
  computed: {},
  data() {
    return {
      old_begin: dateTime.getoneday(-1),
      old_end: dateTime.getoneday(0),
      begin: "",
      end: "",
      list: [],
    };
  },
  methods: {
    get_data: function () {
      this.begin = this.old_begin;
      this.end = this.old_end;
      if (
        Cookies.get("code") == null ||
        Cookies.get("code") == "" ||
        localStorage.getItem("uid") == null ||
        localStorage.getItem("uid") == ""
      ) {
        this.$store.commit("islogin", true);
        this.$store.dispatch("logout_public");
      } else {
        let param = {
          action: "data_vip_bydate",
          body: {
            uid: localStorage.getItem("uid"),
            session_code: Cookies.get("code"),
            clubid: localStorage.getItem("clubid"),
            begindate: this.begin,
            enddate: this.end,
          },
        };
        this.$store.dispatch("getpost", param).then((res) => {
          this.list = res.data;
        });
      }
    },
  },
  components: {},
  watch: {},
  created() {
    this.get_data();
  },
};
</script>
