<template>
  <div class="casino-header-container-new">
    <div
      class="d-flex justify-content-between align-items-center position-relative"
    >
      <span class="text-center club_span_big ms-0">
        {{ $t("text_club5") }}
      </span>
      <div class="casino-header-container w-50">
        <div class="casino-search-input-results w-100">
          <div class="casino-header-container-search w-100">
            <div>
              <input :placeholder="$t('slot_Search')" v-model="keyWord" />
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 17 17"
            >
              <path
                fill="#7A7D8D"
                d="M13.874 12.105c.903-1.267 1.44-2.801 1.44-4.457C15.315 3.434 11.872 0 7.646 0 3.42 0 0 3.434 0 7.648c0 4.213 3.444 7.647 7.67 7.647 1.66 0 3.224-.536 4.47-1.437l2.784 2.777c.244.243.562.365.855.365.293 0 .635-.122.855-.365.488-.487.488-1.242 0-1.73l-2.76-2.8zM2.443 7.648c0-2.874 2.344-5.212 5.227-5.212 2.882 0 5.227 2.338 5.227 5.212 0 2.873-2.345 5.212-5.227 5.212-2.883-.025-5.227-2.363-5.227-5.212z"
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex bg_drak_coff2 mt-3 mt-1024-0 member_opa">
      <span
        class="club_span3 c-pointer"
        @click="is_not_id = 'N'"
        :class="is_not_id == 'N' ? 'bg_light_coff2' : ''"
        >{{ $t("text_king6") }}</span
      >
      <span class="club_span3">｜</span>
      <span
        class="club_span3 c-pointer"
        @click="is_not_id = 'Y'"
        :class="is_not_id == 'Y' ? 'bg_light_coff2' : ''"
        >{{ $t("text_king7") }}</span
      >
      <!-- <div
        class="bg_linear_item c-pointer"
        @click="is_not_id = 'Y'"
        :class="is_not_id == 'Y' ? 'bg_light_coff2' : ''"
      >
        <span class="club_span3">{{ $t("text_king7") }}</span>
      </div>
      <div
        class="bg_linear_item c-pointer"
        @click="is_not_id = 'N'"
        :class="is_not_id == 'N' ? 'bg_light_coff2' : ''"
      >
        <span class="club_span3">{{ $t("text_king6") }}</span>
      </div>
      <div class="bg_linear_item"></div> -->
    </div>

    <div
      v-if="is_not_id == 'Y'"
      class="mt-3"
      style="background: #071d2a; border-radius: 16px"
    >
      <div v-if="filterSearch.length != 0">
        <div class="div-header">
          <span>{{ $t("text_king8") }}</span>
          <span>{{ $t("text_king9") }}</span>
        </div>
        <div
          class="div-container"
          style="
            height: 45vh;
            overflow-y: auto;
            border-bottom: 15px solid #18222d;
            border-radius: 16px;
          "
        >
          <div class="content1" v-for="l in filterSearch" :key="l">
            <span>{{ l.userid }}</span>
            <span>{{ l.name }}</span>
          </div>
          <!-- <div class="content1" v-for="l in 15" :key="l">
          <span class="">{{ 123456798012 }}</span>
          <span class="">{{ 123456798012 }}</span>
        </div> -->
        </div>
      </div>
      <div v-else class="p-3">
        <div
          class="d-flex justify-content-center flex-column align-items-center my-3"
        >
          <h5 class="m-0">{{ $t("nodata") }}</h5>
        </div>
      </div>
    </div>

    <!-- ///////////////////////////////////// -->
    <div
      v-else
      class="mt-3 w-100 p-3"
      style="background: #071d2a; border-radius: 16px"
    >
      <div
        v-if="filterSearch_n.length != 0"
        style="height: 45vh; overflow-y: auto"
      >
        <div class="new_data new_data_3 w-100 align-content-start d-grid">
          <div class="new_data-div" v-for="l in filterSearch_n" :key="l">
            <div class="ms-3">
              <p class="fs-18">{{ $t("text_king8") }}：{{ l.userid }}</p>
              <p class="fs-18 mb-0">{{ $t("text_king9") }}：{{ l.name }}</p>
            </div>

            <div class="mt-3 d-flex justify-content-center">
              <img
                src="@/assets/img/main/bt_no.png"
                style="width: 60px"
                class="me-3"
                @click="member_yes_no('N', l.userid)"
                :alt="$t('text_reject')"
                :title="$t('text_reject')"
              />
              <img
                src="@/assets/img/main/bt_yes.png"
                style="width: 60px"
                @click="member_yes_no('Y', l.userid)"
                :alt="$t('text_allow')"
                :title="$t('text_allow')"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="d-flex justify-content-center flex-column align-items-center my-3"
      >
        <h5 class="m-0">{{ $t("nodata") }}</h5>
      </div>
    </div>
    <!-- /////////////////////////////// -->

    <!-- <div v-else class="mt-3" style="background: #071d2a; border-radius: 16px">
      <div class="div-header">
        <span>{{ $t("text_king8") }}</span>
        <span>{{ $t("text_king9") }}</span>
        <span>{{ $t("text_operate") }}</span>
      </div>
      <div
        class="div-container"
        style="
          height: 45vh;
          overflow-y: auto;
          border-bottom: 15px solid #18222d;
          border-radius: 16px;
        "
      >
        <div class="content1 content1_new" v-for="l in 15" :key="l">
          <span class="word-break">{{ 123456798012 }}</span>
          <span class="word-break">{{ 123456798012 }}</span>
          <span>
            <div class="member_operate">
              <img
                src="@/assets/img/main/bt_no.png"
                style="width: 60px"
                class="me-3"
                @click="member_yes_no('N', l.userid)"
              />
              <img
                src="@/assets/img/main/bt_yes.png"
                style="width: 60px"
                @click="member_yes_no('Y', l.userid)"
              /></div
          ></span>
        </div>
      </div>
    </div> -->

    <!-- <div
      class="d-flex justify-content-start flex-lg-column align-items-lg-center bg-table-img px-4 px-md-5 casino-header-container"
      style="padding-top: 0.25rem !important"
    >
      <div
        class="d-flex mt-2 justify-content-center w-100"
        v-if="is_not_id == 'Y'"
      >
        <div
          class="py-2 row overflow-y-auto w-100 align-content-start"
          style="height: 85vh; gap: 15px"
        >
          <div
            class="py-2 mb-1 d-flex justify-content-center align-items-center member_item c-pointer"
            v-for="l in filterSearch"
            :key="l"
            style="height: 150px; border: 1px solid #5f5f5f"
          >
            <img
              src="@/assets/img/d_club/ic5.png"
              style="
                border-radius: 100%;
                width: 75px;
                height: 75px;
                border: 1px solid #fff;
              "
              class="p-2"
            />
            <div class="ms-3">
              <p class="fs-18">{{ $t("text_king8") }}：{{ l.userid }}</p>
              <p class="fs-18 mb-0">{{ $t("text_king9") }}：{{ l.name }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex mt-2 justify-content-center w-100" v-else>
        <div
          class="py-2 row overflow-y-auto w-100 align-content-start"
          style="height: 85vh; gap: 15px"
        >
          <div
            class="py-2 mb-1 d-flex justify-content-center align-items-center member_item c-pointer"
            v-for="l in filterSearch_n"
            :key="l"
            style="height: 150px; border: 1px solid #5f5f5f"
          >
            <img
              src="@/assets/img/d_club/ic5.png"
              style="
                border-radius: 100%;
                width: 75px;
                height: 75px;
                border: 1px solid #fff;
              "
              class="p-2"
            />
            <div class="d-flex flex-column">
              <div class="ms-3">
                <p class="fs-18">{{ $t("text_king8") }}：{{ l.userid }}</p>
                <p class="fs-18 mb-0">{{ $t("text_king9") }}：{{ l.name }}</p>
              </div>

              <div class="ms-3 mt-3">
                <img
                  src="@/assets/img/main/bt_no.png"
                  style="width: 60px"
                  class="me-3"
                  @click="member_yes_no('N', l.userid)"
                />
                <img
                  src="@/assets/img/main/bt_yes.png"
                  style="width: 60px"
                  @click="member_yes_no('Y', l.userid)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import Cookies from "js-cookie";
export default {
  computed: {
    filterSearch() {
      return this.list_y.filter((v) => {
        if (this.keyWord.length > 0) {
          return v.userid.toLowerCase().includes(this.keyWord.toLowerCase());
        } else return v;
      });
    },
    filterSearch_n() {
      return this.list_n.filter((v) => {
        if (this.keyWord.length > 0) {
          return v.userid.toLowerCase().includes(this.keyWord.toLowerCase());
        } else return v;
      });
    },
  },
  data() {
    return {
      code: Cookies.get("code"),
      is_not_id: "N",
      list_y: [],
      list_n: [],
      keyWord: "",
    };
  },
  methods: {
    get_member_y() {
      if (
        Cookies.get("code") == null ||
        Cookies.get("code") == "" ||
        localStorage.getItem("uid") == null ||
        localStorage.getItem("uid") == ""
      ) {
        this.$store.commit("islogin", true);
        this.$store.dispatch("logout_public");
      } else {
        let param = {
          action: "all_club_member",
          body: {
            uid: localStorage.getItem("uid"),
            session_code: Cookies.get("code"),
            clubid: localStorage.getItem("clubid"),
            userid: "",
            status: "Y",
            orderby: "3",
          },
        };
        this.$store.dispatch("getpost", param).then((res) => {
          if (res.status == "success") {
            this.list_y = res.member;
          }
        });
      }
    },
    get_member_n() {
      if (
        Cookies.get("code") == null ||
        Cookies.get("code") == "" ||
        localStorage.getItem("uid") == null ||
        localStorage.getItem("uid") == ""
      ) {
        this.$store.commit("islogin", true);
        this.$store.dispatch("logout_public");
      } else {
        let param = {
          action: "all_club_member",
          body: {
            uid: localStorage.getItem("uid"),
            session_code: Cookies.get("code"),
            clubid: localStorage.getItem("clubid"),
            userid: "",
            status: "S",
            orderby: "3",
          },
        };
        this.$store.dispatch("getpost", param).then((res) => {
          if (res.status == "success") {
            this.list_n = res.member;
          }
        });
      }
    },
    member_yes_no(status, member) {
      if (
        Cookies.get("code") == null ||
        Cookies.get("code") == "" ||
        localStorage.getItem("uid") == null ||
        localStorage.getItem("uid") == ""
      ) {
        this.$store.commit("islogin", true);
        this.$store.dispatch("logout_public");
      } else {
        let param = {
          action: "check_member",
          body: {
            uid: localStorage.getItem("uid"),
            session_code: Cookies.get("code"),
            clubid: localStorage.getItem("clubid"),
            userid: member,
            status: status,
          },
        };
        this.$store.dispatch("getpost", param).then((res) => {
          if (res.status == "success") {
            this.$MSG.API_popmsg(res.msg, "", "success");
            this.get_member_y();
            this.get_member_n();
          }
        });
      }
    },
  },
  components: {},
  watch: {},
  created() {
    this.get_member_y();
    this.get_member_n();
  },
};
</script>
