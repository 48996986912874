<template>
  <div>
    <div class="prewindow_div"></div>
    <div class="prewin pre_reg" :class="islogin ? 'islogin' : 'isreg'">
      <LoginPart v-if="islogin" />
      <Register v-else-if="isreg" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import LoginPart from "./Home/LoginPart.vue";
import Register from "./Register.vue";

export default {
  name: "login",
  setup() {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["islogin", "isreg"]),
  },
  components: { LoginPart, Register },
  watch: {
    islogin() {
      if (this.islogin) {
        this.$store.dispatch("logout_public");
      }
    },
    isreg() {
      if (this.isreg) {
        this.$store.dispatch("logout_public");
      }
    },
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
