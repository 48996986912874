<template>
  <div class="col-xl-12 header_mt">
    <div class="row mx-auto">
      <div
        class="w-100 justify-content-center d-xl-flex d-none rounded-4"
        style="background: var(--bo-invite-bg)"
      >
        <div
          class="w-100"
          :class="this.id == 'invite' ? 'pc_inv' : ''"
          @click="this.id = 'invite'"
        >
          <div class="text-center">
            <img
              src="@/assets/img/invite/invite.png"
              alt=""
              style="width: 40px; height: 40px"
            />
            <div>
              <span class="text-white">{{ $t("share_text1") }}</span>
            </div>
          </div>
        </div>
        <div
          class="w-100"
          :class="this.id == 'statistics' ? 'pc_inv' : ''"
          @click="this.id = 'statistics'"
        >
          <div class="text-center">
            <img
              src="@/assets/img/invite/statistics.png"
              alt=""
              style="width: 40px; height: 40px"
            />
            <div>
              <span class="text-white">{{ $t("share_text2") }}</span>
            </div>
          </div>
        </div>
        <div
          class="w-100"
          :class="this.id == 'salary' ? 'pc_inv' : ''"
          @click="this.id = 'salary'"
        >
          <div class="text-center">
            <img
              src="@/assets/img/invite/salary.png"
              alt=""
              style="width: 40px; height: 40px"
            />
            <div>
              <span class="text-white">{{ $t("share_text3") }}</span>
            </div>
          </div>
        </div>
        <div
          class="w-100"
          :class="this.id == 'invitedperson' ? 'pc_inv' : ''"
          @click="this.id = 'invitedperson'"
        >
          <div class="text-center">
            <img
              src="@/assets/img/invite/invitedperson.png"
              alt=""
              style="width: 40px; height: 40px"
            />
            <div>
              <span class="text-white">{{ $t("share_text4") }}</span>
            </div>
          </div>
        </div>
        <div
          class="w-100"
          :class="this.id == 'bonus' ? 'pc_inv' : ''"
          @click="this.id = 'bonus'"
        >
          <div class="text-center">
            <img
              src="@/assets/img/invite/bonus.png"
              alt=""
              style="width: 40px; height: 40px"
            />
            <div>
              <span class="text-white">{{ $t("share_text5") }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="d-block d-xl-none mb-4 p-0">
        <div
          class="bonus_mobi box_shadow"
          data-bs-toggle="collapse"
          data-bs-target="#bonav"
          :aria-expanded="this.flag_coll"
          aria-controls="bonav"
          @click="change_flag"
        >
          <div class="d-inline-flex flex-row">
            <div class="">
              <img
                class="bonus_mobi_img bonus_mobi_img1"
                :src="this.imageSrc"
                alt=""
              />
            </div>
            <div class="">{{ mobi_top_choose }}</div>
          </div>
          <svg viewBox="0 0 24 24">
            <path
              d="M5.94 7.94a1.5 1.5 0 012.12 0L13 12.88l4.94-4.94a1.5 1.5 0 012.12 2.12l-6 6a1.5 1.5 0 01-2.12 0l-6-6a1.5 1.5 0 010-2.12z"
            ></path>
          </svg>
        </div>
        <div class="collapse" :class="this.flag_coll ? 'show' : ''" id="bonav">
          <ul class="p-0">
            <li @click="change_id('invite')">
              <div>
                <img
                  class="bonus_mobi_img bonus_mobi_img1"
                  src="@/assets/img/invite/invite.png"
                  alt=""
                />{{ $t("share_text1") }}
              </div>
              <img
                v-show="this.id == 'invite'"
                src="@/assets/img/invite/check.png"
                alt=""
                class="bonus_mobi_img"
              />
            </li>
            <li @click="change_id('statistics')">
              <div>
                <img
                  class="bonus_mobi_img bonus_mobi_img1"
                  src="@/assets/img/invite/statistics.png"
                  alt=""
                />{{ $t("share_text2") }}
              </div>
              <img
                v-show="this.id == 'statistics'"
                src="@/assets/img/invite/check.png"
                alt=""
                class="bonus_mobi_img"
              />
            </li>
            <li @click="change_id('salary')">
              <div>
                <img
                  class="bonus_mobi_img bonus_mobi_img1"
                  src="@/assets/img/invite/salary.png"
                  alt=""
                />{{ $t("share_text3") }}
              </div>
              <img
                v-show="this.id == 'salary'"
                src="@/assets/img/invite/check.png"
                alt=""
                class="bonus_mobi_img"
              />
            </li>
            <li @click="change_id('invitedperson')">
              <div>
                <img
                  class="bonus_mobi_img bonus_mobi_img1"
                  src="@/assets/img/invite/invitedperson.png"
                  alt=""
                />{{ $t("share_text4") }}
              </div>
              <img
                v-show="this.id == 'invitedperson'"
                src="@/assets/img/invite/check.png"
                alt=""
                class="bonus_mobi_img"
              />
            </li>
            <li @click="change_id('bonus')">
              <div>
                <img
                  class="bonus_mobi_img bonus_mobi_img1"
                  src="@/assets/img/invite/bonus.png"
                  alt=""
                />{{ $t("share_text5") }}
              </div>
              <img
                v-show="this.id == 'bonus'"
                src="@/assets/img/invite/check.png"
                alt=""
                class="bonus_mobi_img"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>

    <InviteView v-if="this.id == 'invite'" />
    <Statistics v-if="this.id == 'statistics'" />
    <salary v-if="this.id == 'salary'" />
    <invitedperson v-if="this.id == 'invitedperson'" />
    <bonus v-if="this.id == 'bonus'" />
  </div>
</template>
<script>
import Cookies from "js-cookie";
import "@/assets/style/invite.css";
import "@/assets/style/invite2.css";
import InviteView from "./invite/invite";
import Statistics from "./invite/statistics";
import salary from "./invite/salary";
import invitedperson from "./invite/invitedperson";
import bonus from "./invite/bonus";

export default {
  name: "home3",
  setup() {},
  data() {
    return {
      id: "invite",
      mobi_top_choose: this.$t("share_text1"),
      imageSrc: require("@/assets/img/invite/invite.png"),
      flag_coll: false,
      code: Cookies.get("code"),
    };
  },
  computed: {},
  components: {
    InviteView,
    Statistics,
    salary,
    invitedperson,
    bonus,
  },
  watch: {
    id() {
      this.imageSrc = require("@/assets/img/invite/" + this.id + ".png");
      if (this.id == "statistics")
        this.mobi_top_choose = this.$t("share_text2");
      else if (this.id == "salary")
        this.mobi_top_choose = this.$t("share_text3");
      else if (this.id == "invitedperson")
        this.mobi_top_choose = this.$t("share_text4");
      else if (this.id == "bonus")
        this.mobi_top_choose = this.$t("share_text5");
      else this.mobi_top_choose = this.$t("share_text1");
    },
  },
  created() {
    if (
      this.code == null ||
      this.code == "" ||
      localStorage.getItem("uid") == null ||
      localStorage.getItem("uid") == ""
    ) {
      this.$store.commit("islogin", true);
      this.$store.dispatch("logout_public");
    }
  },
  mounted() {},
  methods: {
    change_id: function (e) {
      this.id = e;
      this.change_flag();
    },
    change_flag: function () {
      this.flag_coll = !this.flag_coll;
    },
  },
};
</script>
