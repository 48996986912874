<template>
  <div class="home_game_casino jackpot_game home_vip_page">
    <div
      class="d-flex justify-content-between align-items-center flex-column flex-md-row"
    >
      <div class="game_logo_img fw-bolder my-3">
        <img
          src="@/assets/img/left/customer_self.png"
          alt=""
          style="width: 60px"
        />
        <div class="d-flex flex-column">
          <span class="jackpot_rank_title ms-2">
            {{ $t("customer_self_text1").toLowerCase() }}</span
          >
          <span class="ms-2" style="color: #e39c44">
            {{ $t("customer_self_text2").toLowerCase() }}</span
          >
        </div>
      </div>

      <div class="d-flex justify-content-end self_div w-100" style="gap: 8px">
        <div
          style="border: 1px solid #fff"
          class="px-2 py-1 text-center c-pointer"
        >
          {{ $t("customer_self_text3") }}
        </div>
        <div
          style="border: 1px solid #fff"
          class="px-2 py-1 text-center c-pointer"
          @click="flag_finance_page = true"
        >
          {{ $t("customer_self_text4") }}
        </div>
      </div>
    </div>
    <div class="">
      <p class="mt-5 mb-2" style="font-size: 20px">
        {{ $t("customer_self_text5") }}
      </p>
      <div
        class="d-grid grid-3 col-12 col-md-8 overflow-x-auto overflow-y-hidden"
      >
        <div @click="open_wheel_fun()">
          <img src="@/assets/img/self_img_center/32.png" class="w-100" />
          <div
            class="d-flex justify-content-between align-items-center pe-0 mt-2"
          >
            <img
              src="@/assets/img/self_img_center/redbo.png"
              style="width: 25px"
            />
            <span class="text-nowrap"
              >{{ random_num("self_1") }}{{ $t("customer_self_text8") }}</span
            >
          </div>
        </div>
        <div @click="open_sign_fun()">
          <img src="@/assets/img/self_img_center/33.png" class="w-100" />
          <div
            class="d-flex justify-content-between align-items-center pe-0 mt-2"
          >
            <img
              src="@/assets/img/self_img_center/redbo.png"
              style="width: 25px"
            />
            <span class="text-nowrap"
              >{{ random_num("self_2") }}{{ $t("customer_self_text8") }}</span
            >
          </div>
        </div>
        <div @click="open_extra_fun()">
          <img src="@/assets/img/self_img_center/34.png" class="w-100" />
          <div
            class="d-flex justify-content-between align-items-center pe-0 mt-2"
          >
            <img
              src="@/assets/img/self_img_center/redbo.png"
              style="width: 25px"
            />
            <span class="text-nowrap"
              >{{ random_num("self_3") }}{{ $t("customer_self_text8") }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <p class="mt-5 mb-2" style="font-size: 20px">
        {{ $t("customer_self_text6") }}
      </p>
      <div
        class="d-grid grid-3 col-12 col-md-8 overflow-x-auto overflow-y-hidden"
      >
        <div>
          <img src="@/assets/img/self_img_center/38.png" class="w-100" />
          <div
            class="d-flex justify-content-between align-items-center pe-0 mt-2"
          >
            <img
              src="@/assets/img/self_img_center/redbo.png"
              style="width: 25px"
            />
            <span class="text-nowrap"
              >{{ random_num("self_4") }}{{ $t("customer_self_text8") }}</span
            >
          </div>
        </div>
        <div>
          <img src="@/assets/img/self_img_center/39.png" class="w-100" />
          <div
            class="d-flex justify-content-between align-items-center pe-0 mt-2"
          >
            <img
              src="@/assets/img/self_img_center/redbo.png"
              style="width: 25px"
            />
            <span class="text-nowrap"
              >{{ random_num("self_5") }}{{ $t("customer_self_text8") }}</span
            >
          </div>
        </div>
        <div>
          <img src="@/assets/img/self_img_center/40.png" class="w-100" />
          <div
            class="d-flex justify-content-between align-items-center pe-0 mt-2"
          >
            <img
              src="@/assets/img/self_img_center/redbo.png"
              style="width: 25px"
            />
            <span class="text-nowrap"
              >{{ random_num("self_6") }}{{ $t("customer_self_text8") }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <p class="mt-5 mb-2" style="font-size: 20px">
        {{ $t("customer_self_text7") }}
      </p>
      <div
        class="d-grid grid-3 col-12 col-md-8 overflow-x-auto overflow-y-hidden"
      >
        <div>
          <img src="@/assets/img/self_img_center/35.png" class="w-100" />
          <div
            class="d-flex justify-content-between align-items-center pe-0 mt-2"
          >
            <img
              src="@/assets/img/self_img_center/redbo.png"
              style="width: 25px"
            />
            <span class="text-nowrap"
              >{{ random_num("self_7") }}{{ $t("customer_self_text8") }}</span
            >
          </div>
        </div>
        <div>
          <img src="@/assets/img/self_img_center/36.png" class="w-100" />
          <div
            class="d-flex justify-content-between align-items-center pe-0 mt-2"
          >
            <img
              src="@/assets/img/self_img_center/redbo.png"
              style="width: 25px"
            />
            <span class="text-nowrap"
              >{{ random_num("self_8") }}{{ $t("customer_self_text8") }}</span
            >
          </div>
        </div>
        <div>
          <img src="@/assets/img/self_img_center/37.png" class="w-100" />
          <div
            class="d-flex justify-content-between align-items-center pe-0 mt-2"
          >
            <img
              src="@/assets/img/self_img_center/redbo.png"
              style="width: 25px"
            />
            <span class="text-nowrap"
              >{{ random_num("self_9") }}{{ $t("customer_self_text8") }}</span
            >
          </div>
        </div>
      </div>
    </div>

    <!-- 彩金即时稽核彈窗 -->
    <div class="finance_page home_vip_page" v-show="flag_finance_page">
      <div class="finance_card">
        <!-- <div class="finance_close" @click="flag_finance_page = false">×</div> -->
        <div class="finance_title">
          {{ $t("customer_self_text9") }}
        </div>

        <div class="finance_main">
          <div class="finance_deposit" style="margin-top: 10px">
            <div class="mt-4">
              <p class="mb-0">{{ $t("customer_self_text10") }}：1000</p>
            </div>
            <div class="mt-4">
              <p class="mb-0">{{ $t("customer_self_text11") }}：50</p>
            </div>
            <div class="mt-4">
              <p class="mb-0">{{ $t("customer_self_text12") }}：950</p>
            </div>
            <p class="mt-4 text-center">{{ $t("customer_self_text13") }}</p>

            <div
              class="col-10 text-white mx-auto my-3"
              @click="flag_finance_page = false"
            >
              <button class="border-0 text-white bg_green_btn p-2 w-100">
                <span>{{ $t("shared_comfirm") }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import { mapActions, mapGetters } from "vuex";
export default {
  props: {},
  components: {},
  data() {
    return {
      code: Cookies.get("code"),
      uid: localStorage.getItem("uid"),
      flag_finance_page: false,
    };
  },
  methods: {
    ...mapActions(["logout_public"]),
    open_wheel_fun: function () {
      if (
        Cookies.get("code") == null ||
        Cookies.get("code") == "" ||
        localStorage.getItem("uid") == null ||
        localStorage.getItem("uid") == ""
      ) {
        this.$store.commit("islogin", true);
        this.$store.dispatch("logout_public");
      } else {
        this.$store.commit("flag_wheel", true);
      }
    },
    open_sign_fun: function () {
      if (
        Cookies.get("code") == null ||
        Cookies.get("code") == "" ||
        localStorage.getItem("uid") == null ||
        localStorage.getItem("uid") == ""
      ) {
        this.$store.commit("islogin", true);
        this.$store.dispatch("logout_public");
      } else {
        this.$store.commit("flag_sign", true);
      }
    },
    open_extra_fun: function () {
      if (
        Cookies.get("code") == null ||
        Cookies.get("code") == "" ||
        localStorage.getItem("uid") == null ||
        localStorage.getItem("uid") == ""
      ) {
        this.$store.commit("islogin", true);
        this.$store.dispatch("logout_public");
      } else {
        this.$store.commit("flag_extra", true);
      }
    },
    random_num(id) {
      var origin_num = localStorage.getItem(id) ?? 20000;
      if (origin_num > 990000) origin_num = 20000;
      var ran_plus = Math.ceil(Math.random() * 100 + 20);
      localStorage.setItem(id, parseInt(origin_num) + parseInt(ran_plus));
      return localStorage.getItem(id);
    },
  },
  mounted() {},
  created() {},
  watch: {},
  computed: {
    ...mapGetters(["flag_wheel", "flag_sign", "flag_extra"]),
  },
};
</script>
