<template>
  <div class="col-xl-12">
    <KYCview v-if="Id === 'K'"></KYCview>
    <privacy v-else-if="Id === 'P'"></privacy>
    <Responsible v-else-if="Id === 'R'"></Responsible>
    <term v-else-if="Id === 'T'"></term>
    <faq v-else-if="Id === 'F'"></faq>
    <earn v-else-if="Id === 'E'"></earn>
  </div>
</template>
<script>
import "@/assets/style/help.css";
import KYCview from "./kyc.vue";
import privacy from "./privacy.vue";
import Responsible from "./Responsible.vue";
import term from "./term.vue";
import faq from "./faq_home.vue";
import earn from "./earn.vue";

export default {
  name: "help",
  data() {
    return {};
  },
  created() {},
  methods: {},
  watch: {
    Id() {
      document.getElementById("app").scrollTop = 0;
    },
  },
  computed: {
    Id: function () {
      if (this.$route.params.type != null) return this.$route.params.type;
      else return "";
    },
  },
  components: {
    privacy,
    term,
    KYCview,
    Responsible,
    faq,
    earn,
  },
  mounted() {
    document.getElementById("app").scrollTop = 0;
  },
};
</script>
