<template>
  <!-- 最上面的nav -->
  <div class="header-wrapper-m">
    <div
      class="content_div fixed-header gb-logged-out pb-0"
      style="
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.2),
          0 4px 6px -2px rgba(0, 0, 0, 0.1);
        background-color: #12151e;
      "
    >
      <nav
        class="navigation-menu-wrapper d-flex align-items-center position-relative mx-0 my-0"
        style="width: 100%"
      >
        <div
          class="logo-wrapper-div"
          style="
            position: relative;
            display: grid;
            width: 100%;
            align-items: center;
            grid-template-columns: auto 1fr;
            gap: 8px;
          "
        >
          <img
            src="@/assets/img/main/ic1-1.png"
            alt=""
            class="top-left-nav d-xl-none d-block"
            @click="hide_nav()"
          />
          <div class="ms-3 logo-wrapper-m lcfc">
            <img
              :src="logo"
              class="top_head_img c-pointer d-block"
              @click="this.$router.push('/')"
            />
            <!-- <img
              src="@/assets/gembet_logo_pic.png"
              class="top_head_img c-pointer d-none top_head_img_mob"
              @click="this.$router.push('/')"
            /> -->
          </div>
          <div
            class="top-nav-container d-flex align-items-center justify-content-end"
          >
            <!-- 未登入 -->
            <div
              class="top-nav-logged-out d-flex align-items-center position-relative"
              v-if="!flag_code_uid"
            >
              <div class="login-register-header-form d-flex align-items-center">
                <button
                  class="transparent-button transparent-button-active loginbtn"
                  @click="open_login('islogin')"
                >
                  {{ $t("login1") }}</button
                ><button
                  class="transparent-button transparent-button-active signup"
                  @click="open_login('isreg')"
                >
                  {{ $t("text_join") }}
                </button>
              </div>
            </div>
            <!-- 登入 -->
            <div class="top-nav-logged-in" v-else-if="Id != 'club'">
              <div class="header-balance-info">
                <span class="text-gold fw-bolder me-5 d-1199-none">{{
                  uid
                }}</span>
                <span
                  class="text-light-blue fw-bolder c-pointer me-3 d-1199-none"
                  @click="this.$router.push('/VIP')"
                  >{{ $t("text_top_vip") }}</span
                >
                <span
                  class="text-light-blue fw-bolder c-pointer me-3 d-1199-none"
                  @click="this.$router.push('/ME/wallet/deposit')"
                  >{{ $t("deposit") }}</span
                >
                <span
                  class="text-light-blue fw-bolder c-pointer me-3 d-1199-none"
                  @click="this.$router.push('/ME/wallet/withdraw')"
                  >{{ $t("withdrawl") }}</span
                >

                <div class="balance-in-header me-3">
                  <balanceview />
                </div>

                <div
                  @click="logout"
                  class="d-1200-flex align-items-center c-pointer me-2 d-1199-none"
                >
                  <img
                    src="@/assets/img/left/logout.png"
                    :title="$t('logout')"
                    style="width: 28px; margin-right: 3px"
                  />
                  <a class="nav-link fw-bold">{{ $t("logout") }}</a>
                </div>
              </div>
            </div>
            <!-- 登入 -->
            <div
              class="top-nav-logged-in"
              v-else-if="Id == 'club' && flag_code_uid"
            >
              <div class="header-balance-info">
                <span class="text-gold fw-bolder me-5 d-1199-none">{{
                  uid
                }}</span>
              </div>
            </div>
            <!-- 语言 -->
            <!-- <lang class="main_top_lang"></lang> -->
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import { mapActions, mapGetters } from "vuex";
// import Lang from "./Home/language.vue";
import balanceview from "./balance.vue";

export default {
  name: "maintop2",
  data() {
    return {
      code: Cookies.get("code"),
      isMobile: this.$Utils.isMobile(),
      uid: localStorage.getItem("uid"),
    };
  },
  components: {
    // Lang,
    balanceview,
  },
  computed: {
    ...mapGetters(["logo"]),
    ...mapGetters(["flag_nav"]),
    Id: function () {
      if (this.$route.params.id != null) return this.$route.params.id;
      else return "";
    },

    flag_code_uid() {
      return Cookies.get("code") == null ||
        Cookies.get("code") == "" ||
        localStorage.getItem("uid") == null ||
        localStorage.getItem("uid") == ""
        ? false
        : true;
    },
  },
  methods: {
    ...mapActions(["getLogo"]),
    ...mapActions(["logout_public"]),
    open_login: function (id) {
      this.$store.commit(id, true);
      this.$store.dispatch("logout_public");
    },
    show_nav: function () {
      this.$store.commit("flagnav", !this.flag_nav);
    },
    logout: function () {
      this.logout_public();
      this.$router.push("/");
      window.location.reload();
    },
    hide_nav: function () {
      this.$store.commit("flagnav", !this.flag_nav);
    },
  },
  created() {
    this.getLogo();
  },
  watch: {},
};
</script>
