<template>
  <div class="">
    <!-- <createtable v-if="m_type == ''" /> -->
    <homeview v-if="m_type == ''" />
    <memberview v-if="m_type == 'member'" />
    <selectgame v-if="m_type == 'selectgame'" />
    <counterview v-else-if="m_type == 'counter'" />
    <gamedata v-else-if="m_type == 'gamedata'" />
    <financial v-else-if="m_type == 'financial'" />
    <agent v-else-if="m_type == 'agent'" />
    <viplogo v-else-if="m_type == 'viplogo'" />
    <loan v-else-if="m_type == 'loan'" />
    <countertopview v-else-if="m_type == 'countertop'" />
    <setgame v-else-if="m_type == 'setgame'" />
    <casino_comm v-else-if="m_type == 'casino_comm'" />
    <company_report v-else-if="m_type == 'company_report'" />
    <!--      <dataview v-else-if="m_type == 'data'" /> -->
  </div>
</template>
<script>
// import createtable from "./create_table.vue";
import selectgame from "./select_game.vue";
import memberview from "./create_member.vue";
import counterview from "./create_counter.vue";
import gamedata from "./newHistory.vue";
import financial from "./club_financial.vue";
import agent from "./club_agent.vue";
import viplogo from "./club_viplogo.vue";
import loan from "./loan_agent.vue";
import countertopview from "./countertop_agent.vue";
import homeview from "./home_vip_new.vue";
import setgame from "./club_setgame.vue";
import casino_comm from "./club_casino_comm.vue";
import company_report from "./club_company_report.vue";
// import dataview from "./create_data.vue";
export default {
  components: {
    // createtable,
    selectgame,
    memberview,
    counterview,
    gamedata, // dataview
    financial,
    agent,
    viplogo,
    loan,
    countertopview,
    homeview,
    setgame,
    casino_comm,
    company_report,
  },
  data() {
    return {};
  },
  methods: {},
  watch: {},
  created() {},
  computed: {
    m_type: function () {
      if (this.$route.params.type != null) return this.$route.params.type;
      else return "";
    },
  },
  mounted() {},
};
</script>
