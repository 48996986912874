<template>
  <div>
    <div class="casino-header-container-new">
      <div
        class="d-flex justify-content-between align-items-center position-relative flex-wrap"
      >
        <span class="text-center club_span_big ms-0" style="font-size: 22px">
          {{ $t("text_new_share_text1") }}{{ $t("text_new_share_text2") }}
        </span>
        <p class="text-center mb-0">{{ begin }} ～ {{ end }}</p>
      </div>

      <div
        class="mt-2 d-flex justify-content-start align-items-center flex-1023-column"
      >
        <div
          class="d-flex justify-content-start align-items-center flex-1023-column w-100"
        >
          <div
            class="serach_date_card-new d-flex align-items-center me-3 me-1023-3 w-100"
          >
            <div class="dateControlLabel">{{ $t("history_begin") }}:</div>
            <input type="date" v-model="old_begin" Style="padding: 15px;" />
          </div>
          <div
            class="serach_date_card-new d-flex align-items-center me-3 me-1023-3 w-100"
          >
            <div class="dateControlLabel">{{ $t("history_end") }}:</div>
            <input type="date" v-model="old_end" Style="padding: 15px;" />
          </div>
        </div>
        <div
          class="show-history-button rounded-5 bg_green_btn m-0 btn-ft w-100"
          @click="get_data()"
        >
          {{ $t("text_search") }}
        </div>
      </div>
      <div class="d-grid share_history_grid mt-4">
        <div
          class="p-3 pb-1 d-flex flex-column"
          style="background: #071d2a; border-radius: 16px; gap: 30px"
        >
          <p class="fs-18 mb-0">{{ $t("text_new_share_text3") }}</p>
          <p class="mb-0 text-end">
            <span class="fs-24">500</span>
            <span>{{ $t("text_new_share_text4") }}</span>
          </p>
        </div>
        <div
          class="p-3 pb-1 d-flex flex-column"
          style="background: #071d2a; border-radius: 16px; gap: 30px"
        >
          <p class="fs-18 mb-0">{{ $t("text_agent_text6") }}</p>
          <p class="mb-0 text-end">
            <span
              class="fs-24"
              :style="[
                totalWinLose > 0
                  ? 'color:#00af4c;font-weight:600'
                  : totalWinLose < 0
                  ? 'color:#e7323e;font-weight:600'
                  : '',
              ]"
              >{{ totalWinLose }}</span
            >
            <span>{{ $t("text_new_share_text5") }}</span>
          </p>
        </div>
        <div
          class="p-3 pb-1 d-flex flex-column"
          style="background: #071d2a; border-radius: 16px; gap: 30px"
        >
          <p class="fs-18 mb-0">
            {{ $t("home_text6_1") }}{{ $t("text_agent_text7") }}
          </p>
          <div class="d-flex justify-content-between align-items-center">
            <div class="text-grey">
              <span>{{ $t("text_agent_text8") }}</span>
              <span>100,000</span>
            </div>
            <div class="">
              <span
                class="fs-24"
                :style="[
                  new_totalWinLose > 0
                    ? 'color:#00af4c;font-weight:600'
                    : new_totalWinLose < 0
                    ? 'color:#e7323e;font-weight:600'
                    : '',
                ]"
                >{{ new_totalWinLose * -1 }}</span
              >
              <span>{{ $t("text_new_share_text5") }}</span>
            </div>
          </div>
        </div>
        <div
          class="p-3 pb-1 d-flex flex-column"
          style="background: #071d2a; border-radius: 16px; gap: 30px"
        >
          <p class="fs-18 mb-0">
            {{ $t("home_text7_1") }}{{ $t("text_agent_text7") }}
          </p>
          <div class="d-flex justify-content-between align-items-center">
            <div class="text-grey">
              <span>{{ $t("text_agent_text8") }}</span>
              <span>100,000</span>
            </div>
            <div class="">
              <span
                class="fs-24"
                :style="[
                  new_totalWinLose > 0
                    ? 'color:#00af4c;font-weight:600'
                    : new_totalWinLose < 0
                    ? 'color:#e7323e;font-weight:600'
                    : '',
                ]"
                >{{ new_totalWinLose * -1 }}</span
              >
              <span>{{ $t("text_new_share_text5") }}</span>
            </div>
          </div>
        </div>
        <div
          class="p-3 pb-1 d-flex flex-column"
          style="background: #071d2a; border-radius: 16px; gap: 30px"
        >
          <p class="fs-18 mb-0">
            {{ $t("text_lotto_1") }}{{ $t("text_agent_text7") }}
          </p>
          <div class="d-flex justify-content-between align-items-center">
            <div class="text-grey">
              <span>{{ $t("text_agent_text8") }}</span>
              <span>100,000</span>
            </div>
            <div class="">
              <span
                class="fs-24"
                :style="[
                  new_totalWinLose > 0
                    ? 'color:#00af4c;font-weight:600'
                    : new_totalWinLose < 0
                    ? 'color:#e7323e;font-weight:600'
                    : '',
                ]"
                >{{ new_totalWinLose * -1 }}</span
              >
              <span>{{ $t("text_new_share_text5") }}</span>
            </div>
          </div>
        </div>
        <div
          class="p-3 pb-1 d-flex flex-column"
          style="background: #071d2a; border-radius: 16px; gap: 30px"
        >
          <p class="fs-18 mb-0">
            {{ $t("text_sport_1") }}{{ $t("text_agent_text7") }}
          </p>
          <div class="d-flex justify-content-between align-items-center">
            <div class="text-grey">
              <span>{{ $t("text_agent_text8") }}</span>
              <span>100,000</span>
            </div>
            <div class="">
              <span
                class="fs-24"
                :style="[
                  new_totalWinLose > 0
                    ? 'color:#00af4c;font-weight:600'
                    : new_totalWinLose < 0
                    ? 'color:#e7323e;font-weight:600'
                    : '',
                ]"
                >{{ new_totalWinLose * -1 }}</span
              >
              <span>{{ $t("text_new_share_text5") }}</span>
            </div>
          </div>
        </div>
        <div
          class="p-3 pb-1 d-flex flex-column"
          style="background: #071d2a; border-radius: 16px; gap: 30px"
        >
          <p class="fs-18 mb-0">
            {{ $t("text_chess_1") }}{{ $t("text_agent_text7") }}
          </p>
          <div class="d-flex justify-content-between align-items-center">
            <div class="text-grey">
              <span>{{ $t("text_agent_text8") }}</span>
              <span>100,000</span>
            </div>
            <div class="">
              <span
                class="fs-24"
                :style="[
                  new_totalWinLose > 0
                    ? 'color:#00af4c;font-weight:600'
                    : new_totalWinLose < 0
                    ? 'color:#e7323e;font-weight:600'
                    : '',
                ]"
                >{{ new_totalWinLose * -1 }}</span
              >
              <span>{{ $t("text_new_share_text5") }}</span>
            </div>
          </div>
        </div>
        <div
          class="p-3 pb-1 d-flex flex-column"
          style="background: #071d2a; border-radius: 16px; gap: 30px"
        >
          <p class="fs-18 mb-0">
            {{ $t("text_texas_poker") }}{{ $t("text_agent_text7") }}
          </p>
          <div class="d-flex justify-content-between align-items-center">
            <div class="text-grey">
              <span>{{ $t("text_agent_text8") }}</span>
              <span>100,000</span>
            </div>
            <div class="">
              <span
                class="fs-24"
                :style="[
                  new_totalWinLose > 0
                    ? 'color:#00af4c;font-weight:600'
                    : new_totalWinLose < 0
                    ? 'color:#e7323e;font-weight:600'
                    : '',
                ]"
                >{{ new_totalWinLose * -1 }}</span
              >
              <span>{{ $t("text_new_share_text5") }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dateTime from "@/components/utils/datatime";
export default {
  computed: {},
  data() {
    return {
      old_begin: dateTime.getoneday(-1),
      old_end: dateTime.getoneday(0),
      begin: "",
      end: "",
      totalWinLose: 500,
      new_totalWinLose: -500,
    };
  },
  methods: {
    get_data: function () {
      this.begin = this.old_begin;
      this.end = this.old_end;
    },
  },
  components: {},
  watch: {},
  created() {
    this.begin = this.old_begin;
    this.end = this.old_end;
  },
};
</script>
