<template>
  <div>
    <div class="col-xl-12 header_mt">
      <WalletView v-if="m_type == 'wallet'" />
      <Myacc v-if="m_type == 'myacc'" />
      <HistoryView v-if="m_type == 'history'" />
      <ActivityView v-if="m_type == 'activity'" />
      <MessageView v-if="m_type == 'message'" />
      <InvestView v-if="m_type == 'invest'" />
      <myfinanceView v-if="m_type == 'finance'" />
      <!-- <assetscenter v-if="m_type == 'assetscenter'" /> -->
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import { mapGetters } from "vuex";
import "@/assets/style/_bank.scss";
import "@/assets/style/_bank_into.scss";
import WalletView from "./Wallet";
import Myacc from "./Myacc.vue";
import HistoryView from "./History.vue";
import ActivityView from "./Activity.vue";
import MessageView from "./Message.vue";
import InvestView from "./myinvest.vue";
import myfinanceView from "./myfinance.vue";
// import assetscenter from "./assets_center.vue";

export default {
  name: "home2",
  setup() {},
  data() {
    return {
      code: Cookies.get("code"),
    };
  },
  computed: {
    ...mapGetters(["list"]),
    m_type: function () {
      if (this.$route.params.type != null) return this.$route.params.type;
      else return "";
    },
  },
  components: {
    WalletView,
    Myacc,
    HistoryView,
    ActivityView,
    MessageView,
    InvestView,
    myfinanceView,
    // assetscenter,
  },
  watch: {},
  created() {
    if (
      this.code == null ||
      this.code == "" ||
      localStorage.getItem("uid") == null ||
      localStorage.getItem("uid") == ""
    ) {
      this.$store.commit("islogin", true);
      this.$store.dispatch("logout_public");
    }
  },
  mounted() {},
  methods: {},
};
</script>
