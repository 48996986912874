<template>
  <div class="home_game_casino jackpot_game enology_div">
    <div class="d-flex justify-content-between align-items-center">
      <div class="game_logo_img fw-bolder my-3 flex-column align-items-start">
        <!-- <img
          src="@/assets/img/left/game_shareholders.png"
          alt=""
          style="width: 60px"
        /> -->
        <h1>BET 生态</h1>
        <div class="d-flex flex-column">
          <span class="" style="color: #e39c44">
            {{ $t("enology_text_1").toLowerCase() }}</span
          >
        </div>
      </div>
    </div>
    <div class="mt-4">
      <div class="mb-5">
        <p class="text-new-gold fs_24 fw-bolder">{{ $t("enology_text_2") }}</p>
        <p v-html="$t('enology_text_3')" class="fs_e_18"></p>
      </div>
      <div class="mb-5">
        <p class="text-new-gold fs_24 fw-bolder">{{ $t("enology_text_4") }}</p>
        <p v-html="$t('enology_text_5')" class="fs_e_18"></p>
      </div>
      <div class="mb-5">
        <p class="text-new-gold fs_24 fw-bolder">{{ $t("enology_text_6") }}</p>
        <p v-html="$t('enology_text_7')" class="fs_e_18"></p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  created() {},
  watch: {},
};
</script>
