
// 新增判斷方法
const isMobile = function () {

    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    if (flag) {
        if (navigator.userAgent.match(/(iPhone|iPod|ios|iPad)/i)) { return ("APPLE Mobile端"); }
        else if (navigator.userAgent.match(/(Android)/i)) { return ("Android Mobile端"); }
        else {
            return ("Mobile端");
        }
    }
    else { return "pc端"; }
}


export default {  
    isMobile,

}