<template>
  <div>
    <div class="casino-header-container-new new_loan">
      <div
        class="d-flex justify-content-between align-items-center position-relative"
      >
        <div
          class="d-flex justify-content-center align-items-center w-1024-100"
        >
          <span class="text-center club_span_big ms-0 text-nowrap">
            {{ $t("company_report_text1") }}
          </span>
        </div>
        <div class="casino-header-container w-50 w-1024-100 mt-0">
          <div class="casino-search-input-results w-100 justify-content-end">
            <select
              class="form-select w-xl-25"
              @change="type_change"
              v-model="type_select"
            >
              <option value="1">{{ $t("text_month1") }}</option>
              <option value="2">{{ $t("text_month2") }}</option>
              <option value="3">{{ $t("text_month3") }}</option>
              <option value="4">{{ $t("text_month4") }}</option>
              <option value="5">{{ $t("text_month5") }}</option>
              <option value="6">{{ $t("text_month6") }}</option>
              <option value="7">{{ $t("text_month7") }}</option>
              <option value="8">{{ $t("text_month8") }}</option>
              <option value="9">{{ $t("text_month9") }}</option>
              <option value="10">{{ $t("text_month10") }}</option>
              <option value="11">{{ $t("text_month11") }}</option>
              <option value="12">{{ $t("text_month12") }}</option>
            </select>
          </div>
        </div>
      </div>

      <p class="mt-3">{{ $t("company_report_text2") }}</p>

      <div
        class="mt-3 w-100 p-3"
        style="background: #071d2a; border-radius: 16px"
      >
        <div v-if="this.msg == '成功'">
          <div class="new_data new_data_3 w-100 align-content-start d-grid">
            <div class="new_data-div">
              <div>
                <p class="fs-18 d-flex align-items-center flex-wrap">
                  {{ $t("company_report_text3") }}：
                  <span class="text-new-gold">{{ buy_amount }}</span>
                </p>
                <p class="fs-18 d-flex align-items-center flex-wrap">
                  {{ $t("company_report_text4") }}：
                  <span class="text-new-gold">{{ buy }}</span>
                </p>
                <br />
                <br />
                <p class="fs-18 d-flex align-items-center flex-wrap">
                  {{ $t("company_report_text5") }}：
                  <span class="text-new-gold">{{ losewin }}</span>
                </p>
                <p class="fs-18 d-flex align-items-center flex-wrap">
                  {{ $t("company_report_text6") }}：
                  <span class="text-new-gold">{{ turnover }}</span>
                </p>
                <br />
                <br />
                <p class="fs-18 d-flex align-items-center flex-wrap">
                  {{ $t("company_report_text7") }}：
                  <span class="text-new-gold">{{ lending }}</span>
                </p>
                <p class="fs-18 d-flex align-items-center flex-wrap">
                  {{ $t("company_report_text8") }}：
                  <span class="text-new-gold">{{ lending1 }}</span>
                </p>
                <p class="fs-18 d-flex align-items-center flex-wrap">
                  {{ $t("company_report_text9") }}：
                  <span class="text-new-gold">{{ lending2 }}</span>
                </p>
                <p class="fs-18 d-flex align-items-center flex-wrap">
                  {{ $t("company_report_text10") }}：
                  <span class="text-new-gold">{{ countertop_trunover }}</span>
                </p>
                <p class="fs-18 d-flex align-items-center flex-wrap">
                  {{ $t("company_report_text11") }}：
                  <span class="text-new-gold">{{ countertop_trunover1 }}</span>
                </p>
                <br />
                <br />
                <p class="fs-18 d-flex align-items-center flex-wrap mb-1">
                  <span class="text-new-gold">
                    {{ $t("company_report_text12") }}：</span
                  >

                  <span class="text-new-gold">{{ total }}</span>
                </p>
                <p class="d-flex align-items-center flex-wrap">
                  {{ $t("company_report_text13") }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="d-flex justify-content-center flex-column align-items-center my-3"
        >
          <h5 class="m-0">{{ $t("nodata") }}</h5>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
export default {
  computed: {},
  data() {
    return {
      type_select: 1,
      msg: "",
      buy_amount: 0,
      buy: 0,
      losewin: 0,
      turnover: 0,
      lending: 0,
      lending1: 0,
      lending2: 0,
      countertop_trunover: 0,
      countertop_trunover1: 0,
      total: 0,
    };
  },
  methods: {
    type_change: function () {
      if (
        Cookies.get("code") == null ||
        Cookies.get("code") == "" ||
        localStorage.getItem("uid") == null ||
        localStorage.getItem("uid") == ""
      ) {
        this.$store.commit("islogin", true);
        this.$store.dispatch("logout_public");
      } else {
        let param = {
          action: "data_vip_bymonth",
          body: {
            uid: localStorage.getItem("uid"),
            session_code: Cookies.get("code"),
            month: this.type_select,
          },
        };
        this.$store.dispatch("getpost", param).then((res) => {
          this.msg = res.msg;
          this.buy_amount = res.buy_amount;
          this.buy = res.buy;
          this.losewin = res.losewin;
          this.turnover = res.turnover;
          this.lending = res.lending;
          this.lending1 = res.lending1;
          this.lending2 = res.lending2;
          this.countertop_trunover = res.countertop_trunover;
          this.countertop_trunover1 = res.countertop_trunover1;
          this.total = res.total;
        });
      }
    },
  },
  components: {},
  watch: {},
  created() {
    this.type_change();
  },
};
</script>
