<template>
  <div>
    <div
      v-if="filterSearch.length != 0"
      class="d-lg-flex justify-content-lg-center flex-lg-column align-items-lg-center"
    >
      <!-- <div
            class="py-2 mb-1 d-flex justify-content-between align-items-center member_item c-pointer px-1"
            v-for="l in filterSearch"
            :key="l"
            style="height: 150px; border: 1px solid #5f5f5f; gap: 15px"
          >
            <div class="flex-1">
              <p class="fs_16 text-nowrap">{{ $t("text_king8") }}：</p>
              <p class="fs_16 mb-0">{{ l.userid }}</p>
            </div>
            <div
              class="d-flex justify-content-center align-items-center flex-column flex-1"
            >
              <img
                :src="
                  l.amount > 0
                    ? require('@/assets/img/d_club/arrow_b.png')
                    : require('@/assets/img/d_club/arrow_g.png')
                "
                style="width: 35px; margin-bottom: 0.4rem"
              />
              <p class="fs_16 mb-0 text-center">{{ l.cdate }}</p>
            </div>
            <div class="text-end flex-1">
              <p class="fs_16">
                {{ l.amount > 0 ? $t("text_counter11") : $t("text_counter12") }}
              </p>
              <p
                class="fs_16 mb-0 me-2 fw-bolder"
                :style="l.amount > 0 ? 'color: #0181b0' : 'color: #1ab001'"
              >
                {{ l.amount }}
              </p>
            </div>
          </div> -->
      <div class="w-100 p-3" style="background: #071d2a; border-radius: 16px">
        <div style="height: 45vh; overflow-y: auto">
          <div class="new_data new_data_3 w-100 align-content-start d-grid">
            <div class="new_data-div" v-for="(l, k) in filterSearch" :key="k + 1">
              <div class="ms-3">
                <p class="fs-18">{{ $t("text_king8") }}：{{ l.userid }}</p>
                <p class="fs-18">
                  {{ $t("text_trade_time") }}：{{l.cdate}}
                </p>
                <p class="fs-18 mb-0">
                  {{ l.amount > 0 ? $t("text_counter11") : $t("text_counter12") }}：
                  <span
                    class="fs_16 mb-0 me-2 fw-bolder"
                    :style="l.amount > 0 ? 'color: #0181b0' : 'color: #1ab001'"
                  >
                    {{ l.amount }}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="d-flex justify-content-center flex-column align-items-center mt-5"
    >
      <h5>{{ $t("nodata") }}</h5>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
export default {
  props: { keyWord: String },
  computed: {
    filterSearch() {
      return this.list.filter((v) => {
        if (this.keyWord.length > 0) {
          return v.userid.toLowerCase().includes(this.keyWord.toLowerCase());
        } else return v;
      });
    },
  },
  data() {
    return {
      code: Cookies.get("code"),
      list: [],
    };
  },
  methods: {
    coin_transation() {
      if (
        Cookies.get("code") == null ||
        Cookies.get("code") == "" ||
        localStorage.getItem("uid") == null ||
        localStorage.getItem("uid") == ""
      ) {
        this.$store.commit("islogin", true);
        this.$store.dispatch("logout_public");
      } else {
        let param = {
          action: "coin_transation",
          body: {
            uid: localStorage.getItem("uid"),
            session_code: Cookies.get("code"),
            clubid: localStorage.getItem("clubid"),
          },
        };
        this.$store.dispatch("getpost", param).then((res) => {
          if (res.status == "success") {
            this.list = res.member;
          }
        });
      }
    },
  },
  components: {},
  watch: {},
  created() {
    this.coin_transation();
  },
};
</script>
