var now = new Date(); //當前日期
var nowDayOfWeek = now.getDay(); //今天本週的第幾天
var nowDay = now.getDate(); //當前日
var nowMonth = now.getMonth(); //當前月
var nowYear = now.getYear(); //當前年
nowYear += nowYear < 2000 ? 1900 : 0; //
var lastMonthDate = new Date(); //上月日期
lastMonthDate.setDate(1);
lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
var lastMonth = lastMonthDate.getMonth(); //上一月

//格式化日期：yyyy-MM-dd
function formatDate(date) {
  var myyear = date.getFullYear();
  var mymonth = date.getMonth() + 1;
  var myweekday = date.getDate();
  if (mymonth < 10) {
    mymonth = "0" + mymonth;
  }
  if (myweekday < 10) {
    myweekday = "0" + myweekday;
  }
  return myyear + "-" + mymonth + "-" + myweekday;
}
//格式化日期：dd MM yyyy
const formatDate1 = function (date, format) {
  var dd = new Date(date);
  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  var myyear = dd.getFullYear();
  var mymonth = dd.getMonth() + 1;
  var myweekday = dd.getDate();
  if (mymonth < 10) {
    mymonth = "0" + mymonth;
  }
  if (myweekday < 10) {
    myweekday = "0" + myweekday;
  }
  if (format == 1) {
    return myyear + "-" + mymonth + "-" + myweekday;
  } else if (format == 2) {
    return myweekday + " " + months[dd.getMonth()] + " " + myyear;
  }
};
//獲得本月
function getMonth() {
  var Month = nowMonth + 1;
  if (Month < 10) {
    Month = "0" + Month;
  }
  return Month;
}
//獲得本年
function getYear() {
  return nowYear;
}
//獲得本日
function getDate() {
  return nowDay;
}
function getToday() {
  return nowYear + "-" + getMonth() + "-" + nowDay;
}
function getoneday(daycount) {
  var dd = new Date();
  dd.setDate(dd.getDate() + daycount);
  var y = dd.getFullYear();
  var m = dd.getMonth() + 1;
  if (m < 10) {
    m = "0" + m;
  }
  var d = dd.getDate();
  if (d < 10) {
    d = "0" + d;
  }

  return y + "-" + m + "-" + d;
}

//獲得某月的天數
function getMonthDays(myMonth) {
  var monthStartDate = new Date(nowYear, myMonth, 1);
  var monthEndDate = new Date(nowYear, myMonth + 1, 1);
  var days = (monthEndDate - monthStartDate) / (1000 * 60 * 60 * 24);
  return days;
}
//獲得本季度的開端月份
function getQuarterStartMonth() {
  var quarterStartMonth = 0;
  if (nowMonth < 3) {
    quarterStartMonth = 0;
  }
  if (2 < nowMonth && nowMonth < 6) {
    quarterStartMonth = 3;
  }
  if (5 < nowMonth && nowMonth < 9) {
    quarterStartMonth = 6;
  }
  if (nowMonth > 8) {
    quarterStartMonth = 9;
  }
  return quarterStartMonth;
}

//獲得本週的開端日期
const getWeekStartDate = function () {
  var weekStartDate;
  if (nowDayOfWeek == 0) {
    weekStartDate = new Date(nowYear, nowMonth, nowDay - nowDayOfWeek + 1 - 7);
  } else {
    weekStartDate = new Date(nowYear, nowMonth, nowDay - nowDayOfWeek + 1);
  }
  return formatDate(weekStartDate);
};
//獲得本週的停止日期
const getWeekEndDate = function () {
  var weekEndDate;
  if (nowDayOfWeek == 0) {
    weekEndDate = new Date(
      nowYear,
      nowMonth,
      nowDay + (6 - nowDayOfWeek) + 1 - 7
    );
  } else {
    weekEndDate = new Date(nowYear, nowMonth, nowDay + (6 - nowDayOfWeek) + 1);
  }
  return formatDate(weekEndDate);
};

//獲得本月的開端日期
const getMonthStartDate = function () {
  var monthStartDate = new Date(nowYear, nowMonth, 1);
  return formatDate(monthStartDate);
};
//獲得本月的停止日期
const getMonthEndDate = function () {
  var monthEndDate = new Date(nowYear, nowMonth, getMonthDays(nowMonth));
  return formatDate(monthEndDate);
};
//獲得上月開端時候
const getLastMonthStartDate = function () {
  var lastMonthStartDate = new Date(nowYear, lastMonth, 1);
  return formatDate(lastMonthStartDate);
};
//獲得上月停止時候
const getLastMonthEndDate = function () {
  var lastMonthEndDate = new Date(nowYear, lastMonth, getMonthDays(lastMonth));
  return formatDate(lastMonthEndDate);
};
//獲得本季度的開端日期
const getQuarterStartDate = function () {
  var quarterStartDate = new Date(nowYear, getQuarterStartMonth(), 1);
  return formatDate(quarterStartDate);
};
//獲得本季度的停止日期
const getQuarterEndDate = function () {
  var quarterEndMonth = getQuarterStartMonth() + 2;
  var quarterStartDate = new Date(
    nowYear,
    quarterEndMonth,
    getMonthDays(quarterEndMonth)
  );
  return formatDate(quarterStartDate);
};

export default {
  formatDate1,
  getDate,
  getMonth,
  getYear,
  getToday,
  getWeekStartDate,
  getWeekEndDate,
  getMonthStartDate,
  getMonthEndDate,
  getLastMonthStartDate,
  getLastMonthEndDate,
  getQuarterStartDate,
  getQuarterEndDate,
  getoneday,
};
