<template>
  <div id="statistics">
    <div class="mt-xl-3 mt-1 border_eee box_shadow p-2">
      <div class="d-flex w-100">
        <div class="w-100">
          <div class="Agent_statistics__u7ohA">
            <div class="Agent_datePicker__pmxhq border_eee box_shadow p-2">
              <div class="Agent_segmented__Gkuak">
                <div class="ant-segmented ant-segmented-lg rounded-5">
                  <div class="ant-segmented-group">
                    <label
                      class="ant-segmented-item rounded-5"
                      :class="this.day == 1 ? 'selected' : ''"
                      @click="this.day = 1"
                    >
                      <div class="ant-segmented-item-label">
                        {{ $t("text_day") }}
                      </div>
                    </label>
                    <label
                      class="ant-segmented-item rounded-5"
                      :class="this.day == 7 ? 'selected' : ''"
                      @click="this.day = 7"
                    >
                      <div class="ant-segmented-item-label">
                        {{ $t("com_week") }}
                      </div>
                    </label>
                    <label
                      class="ant-segmented-item rounded-5"
                      :class="this.day == 30 ? 'selected' : ''"
                      @click="this.day = 30"
                    >
                      <div class="ant-segmented-item-label">
                        {{ $t("text_month") }}
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <div class="text-xl-end">
                <div
                  class="ant-picker ant-picker-range ant-picker-large d-inline-block w-xl-75 text-xl-start"
                >
                  <div class="ant-picker-input">
                    <div class="dateControlLabel text_gray w-50 fs_16">
                      {{ $t("start_date") }}
                    </div>
                    <input
                      type="date"
                      class="w-auto"
                      id="begindate"
                      @change="begindate_change"
                    />
                  </div>
                  <div class="ant-picker-input">
                    <div class="dateControlLabel text_gray w-50 fs_16">
                      {{ $t("end_date") }}
                    </div>
                    <input
                      type="date"
                      class="w-auto"
                      id="enddate"
                      @change="enddate_change"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div style="margin: 20px auto">
              <div
                class="Agent_tableBlock__kIjCN GbTable_root__aRhLC"
                style="border-radius: 10px"
              >
                <table
                  style="table-layout: auto"
                  class="w-100 GbTable_table__moqvH"
                >
                  <thead class="ant-table-thead">
                    <tr>
                      <th>
                        <img
                          src="@/assets/img/invite/badge.png"
                          style="height: 24px"
                        />
                      </th>
                      <th>{{ $t("text_total") }}</th>
                      <th>{{ $t("text_level") }}</th>
                      <th>{{ $t("text_invited") }}</th>
                      <th>{{ $t("text_recharge") }}</th>
                      <th>{{ $t("history_transaction_type2") }}</th>
                      <th>{{ $t("share_invite_text31") }}</th>
                      <th>{{ $t("text_profits") }}</th>
                    </tr>
                  </thead>
                  <tbody class="ant-table-tbody">
                    <tr
                      class="ant-table-row ant-table-row-level-0"
                      v-for="l in data_arr"
                      :key="l"
                    >
                      <td v-show="l.index == 1" :rowspan="l.level">
                        {{ $t("grand_total") }}
                      </td>
                      <td v-show="l.index == 1" :rowspan="l.level">
                        <span> {{ $t("cur") }} {{ total }} </span>
                      </td>
                      <td>{{ $t("text_level") }}{{ l.index }}</td>
                      <td>{{ l.invited_level }}</td>
                      <td>
                        <span> {{ $t("cur") }} {{ l.recharge_level }} </span>
                      </td>
                      <td>
                        <span> {{ $t("cur") }} {{ l.withdraw_level }} </span>
                      </td>
                      <td>
                        <span> {{ $t("cur") }} {{ l.turnover_level }} </span>
                      </td>
                      <td>
                        <span> {{ $t("cur") }} {{ l.profits_level }} </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- <div class="Agent_tableBlockM__-tsPI border_eee box_shadow p-2">
                <div class="Agent_statisticItem__9+dXQ">
                  <div class="Agent_statisticItemHeader__JZgtK">
                    <span class="text_gray"> {{ $t("grand_total") }} </span>
                  </div>
                  <div
                    class="Agent_statisticItemRow__v3Gi0"
                    style="background-color: #f5f5f5"
                  >
                    <span
                      class="Agent_statisticItemRowItem__7i9px Agent_label__-ie+m"
                    >
                      {{ $t("text_level") }}
                    </span>
                    <span class="Agent_statisticItemRowItem__7i9px">
                      {{ $t("text_level") }}1
                    </span>
                    <span class="Agent_statisticItemRowItem__7i9px">
                      {{ $t("text_level") }}2
                    </span>
                    <span class="Agent_statisticItemRowItem__7i9px">
                      {{ $t("text_level") }}3
                    </span>
                  </div>
                  <div
                    class="Agent_statisticItemRow__v3Gi0"
                    style="background-color: #fff"
                  >
                    <span
                      class="Agent_statisticItemRowItem__7i9px Agent_label__-ie+m"
                    >
                      {{ $t("text_invited") }}
                    </span>
                    <span class="Agent_statisticItemRowItem__7i9px">
                      <span> {{ invited_level1 }} </span>
                    </span>
                    <span class="Agent_statisticItemRowItem__7i9px">
                      <span> {{ invited_level2 }} </span>
                    </span>
                    <span class="Agent_statisticItemRowItem__7i9px">
                      <span> {{ invited_level3 }} </span>
                    </span>
                  </div>
                  <div
                    class="Agent_statisticItemRow__v3Gi0"
                    style="background-color: #f5f5f5"
                  >
                    <span
                      class="Agent_statisticItemRowItem__7i9px Agent_label__-ie+m"
                    >
                      {{ $t("text_recharge") }}
                    </span>
                    <span class="Agent_statisticItemRowItem__7i9px">
                      <span> ${{ recharge_level1 }} </span>
                    </span>
                    <span class="Agent_statisticItemRowItem__7i9px">
                      <span> ${{ recharge_level2 }} </span>
                    </span>
                    <span class="Agent_statisticItemRowItem__7i9px">
                      <span> ${{ recharge_level3 }} </span>
                    </span>
                  </div>
                  <div
                    class="Agent_statisticItemRow__v3Gi0"
                    style="background-color: #fff"
                  >
                    <span
                      class="Agent_statisticItemRowItem__7i9px Agent_label__-ie+m"
                    >
                      {{ $t("history_transaction_type2") }}
                    </span>
                    <span class="Agent_statisticItemRowItem__7i9px">
                      <span> ${{ withdraw_level1 }} </span>
                    </span>
                    <span class="Agent_statisticItemRowItem__7i9px">
                      <span> ${{ withdraw_level2 }} </span>
                    </span>
                    <span class="Agent_statisticItemRowItem__7i9px">
                      <span> ${{ withdraw_level3 }} </span>
                    </span>
                  </div>
                  <div
                    class="Agent_statisticItemRow__v3Gi0"
                    style="background-color: #f5f5f5"
                  >
                    <span
                      class="Agent_statisticItemRowItem__7i9px Agent_label__-ie+m"
                    >
                      {{ $t("share_invite_text31") }}
                    </span>
                    <span class="Agent_statisticItemRowItem__7i9px">
                      <span> ${{ losewin_level1 }} </span>
                    </span>
                    <span class="Agent_statisticItemRowItem__7i9px">
                      <span> ${{ losewin_level2 }} </span>
                    </span>
                    <span class="Agent_statisticItemRowItem__7i9px">
                      <span> ${{ losewin_level3 }} </span>
                    </span>
                  </div>
                  <div
                    class="Agent_statisticItemRow__v3Gi0"
                    style="background-color: #fff"
                  >
                    <span
                      class="Agent_statisticItemRowItem__7i9px Agent_label__-ie+m"
                    >
                      {{ $t("text_profits") }}
                    </span>
                    <span class="Agent_statisticItemRowItem__7i9px">
                      <span> ${{ profits_level1 }} </span>
                    </span>
                    <span class="Agent_statisticItemRowItem__7i9px">
                      <span> ${{ profits_level2 }} </span>
                    </span>
                    <span class="Agent_statisticItemRowItem__7i9px">
                      <span> ${{ profits_level3 }} </span>
                    </span>
                  </div>
                  <div class="Agent_statisticItemFooter__wVMNW">
                    <span class="text_gray">
                      {{ $t("text_total") }} ${{ total }}
                    </span>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import dateTime from "@/components/utils/datatime";

export default {
  name: "statistics",
  computed: {},
  data() {
    return {
      code: Cookies.get("code"),
      begin: dateTime.getoneday(0),
      end: dateTime.getoneday(0),
      day: 1,
      total: 0,
      data_arr: [],
    };
  },
  watch: {
    day: function (e) {
      if (e == 1) {
        this.begin = dateTime.getoneday(0);
      } else if (e == 30) {
        var d =
          dateTime.getYear() +
          "-" +
          (dateTime.getMonth() - 1) +
          "-" +
          dateTime.getDate();
        this.begin = d;
      } else {
        this.begin = dateTime.getoneday(-e);
      }
      if (
        Cookies.get("code") != null &&
        Cookies.get("code") != "" &&
        localStorage.getItem("uid") != null &&
        localStorage.getItem("uid") != ""
      ) {
        this.statistic();
      }
    },
  },
  methods: {
    statistic: function () {
      let param = {
        action: "statistic",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
          begindate: this.begin,
          enddate: this.end,
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        var status = res["status"];
        if (status == "success") {
          this.data_arr = res["data_arr"];
          var data = res["data"][0];
          this.total = data.total;
        }
      });
    },
    begindate_change: function () {
      this.begin = document.getElementById("begindate").value;
      if (
        Cookies.get("code") != null &&
        Cookies.get("code") != "" &&
        localStorage.getItem("uid") != null &&
        localStorage.getItem("uid") != ""
      ) {
        this.statistic();
      }
    },
    enddate_change: function () {
      this.end = document.getElementById("enddate").value;
      if (
        Cookies.get("code") != null &&
        Cookies.get("code") != "" &&
        localStorage.getItem("uid") != null &&
        localStorage.getItem("uid") != ""
      ) {
        this.statistic();
      }
    },
  },
  created() {
    if (
      Cookies.get("code") != null &&
      Cookies.get("code") != "" &&
      localStorage.getItem("uid") != null &&
      localStorage.getItem("uid") != ""
    ) {
      this.statistic();
    }
  },
};
</script>
