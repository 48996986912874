<template>
  <div>
    <div class="casino-header-container-new new_loan">
      <div
        class="d-flex justify-content-between align-items-center position-relative flex-1024-column"
      >
        <div
          class="d-flex justify-content-center align-items-center w-1024-100"
        >
          <span class="text-center club_span_big ms-0 text-nowrap me-fix-15">
            {{ $t("club_text41") }}
          </span>
          <button
            class="btn mb-0 take-btn blue-loan-btn opacity-100 w-100"
            style="border-radius: 8px"
            @click="flag_finance_page = true"
          >
            {{ $t("club_text42") }}
          </button>
        </div>
        <div class="casino-header-container w-50 w-1024-100 mt-0">
          <div class="casino-search-input-results w-100">
            <div class="casino-header-container-search w-100">
              <div>
                <input :placeholder="$t('slot_Search')" v-model="keyWord" />
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
              >
                <path
                  fill="#7A7D8D"
                  d="M13.874 12.105c.903-1.267 1.44-2.801 1.44-4.457C15.315 3.434 11.872 0 7.646 0 3.42 0 0 3.434 0 7.648c0 4.213 3.444 7.647 7.67 7.647 1.66 0 3.224-.536 4.47-1.437l2.784 2.777c.244.243.562.365.855.365.293 0 .635-.122.855-.365.488-.487.488-1.242 0-1.73l-2.76-2.8zM2.443 7.648c0-2.874 2.344-5.212 5.227-5.212 2.882 0 5.227 2.338 5.227 5.212 0 2.873-2.345 5.212-5.227 5.212-2.883-.025-5.227-2.363-5.227-5.212z"
                ></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div
        class="mt-3 d-flex justify-content-start align-items-center flex-1023-column"
      >
        <div
          class="d-flex justify-content-start align-items-center flex-1023-column w-100"
        >
          <div
            class="serach_date_card-new d-flex align-items-center me-3 me-1023-3 w-100"
          >
            <div class="dateControlLabel">{{ $t("history_begin") }}:</div>
            <input type="date" v-model="old_begin" Style="padding: 15px;" />
          </div>
          <div
            class="serach_date_card-new d-flex align-items-center me-3 me-1023-3 w-100"
          >
            <div class="dateControlLabel">{{ $t("history_end") }}:</div>
            <input type="date" v-model="old_end" Style="padding: 15px;" />
          </div>
        </div>
        <div
          class="show-history-button rounded-5 bg_green_btn m-0 btn-ft w-100"
          @click="lending_list()"
        >
          {{ $t("text_search") }}
        </div>
      </div>

      <div class="d-flex bg_drak_coff2 mt-3 mt-1024-0 member_opa">
        <span
          class="club_span3 c-pointer"
          @click="is_not_id = 'S'"
          :class="is_not_id == 'S' ? 'bg_light_coff2' : ''"
          >{{ $t("text_king6") }}</span
        >
        <span class="club_span3">｜</span>
        <span
          class="club_span3 c-pointer"
          @click="is_not_id = ''"
          :class="is_not_id == '' ? 'bg_light_coff2' : ''"
          >{{ $t("club_text30") }}</span
        >
      </div>

      <div
        class="mt-3 w-100 p-3"
        style="background: #071d2a; border-radius: 16px"
      >
        <div
          v-if="this.filterSearch.length > 0"
          style="height: 45vh; overflow-y: auto"
        >
          <div class="new_data new_data_3 w-100 align-content-start d-grid">
            <div class="new_data-div" v-for="l in this.filterSearch" :key="l">
              <div
                class="d-flex justify-content-between align-items-center flex-1024-column"
                style="gap: 8px"
              >
                <div class="" style="flex: 1">
                  <p class="fs-18 d-flex align-items-center flex-wrap">
                    {{ $t("club_text31") }}：
                    <span class="text-new-gold">{{ l.userid }}</span>
                  </p>
                  <p class="fs-18 d-flex align-items-center flex-wrap">
                    {{ $t("club_text32") }}：
                    <span class="text-new-gold">{{ l.payment }}</span>
                  </p>
                  <p class="fs-18 mb-0 d-flex align-items-center flex-wrap">
                    {{ $t("club_text33") }}：
                    <span class="text-new-gold">{{ l.cdate }}</span>
                  </p>
                </div>
                <div class="" style="flex: 1">
                  <p class="fs-18 d-flex align-items-center flex-wrap">
                    {{ $t("club_text34") }}：
                    <span class="text-new-gold"
                      >{{ l.rate }}% ({{ change_method_name(l.method) }})</span
                    >
                  </p>
                  <p class="fs-18 d-flex align-items-center flex-wrap">
                    {{ $t("club_text35") }}：
                    <span class="text-new-gold">{{ l.get_coin }}</span>
                  </p>
                  <p class="fs-18 mb-0 d-flex align-items-center flex-wrap">
                    {{ $t("club_text36") }}：
                    <span class="text-new-gold">{{ l.repaid }}</span>
                  </p>
                </div>
                <div
                  v-if="l.status == 'S'"
                  class="d-flex justify-content-center align-items-center flex-column btn-loan"
                  style="gap: 15px"
                >
                  <button
                    class="btn mb-0 take-btn reject-btn opacity-100 w-100"
                    style="border-radius: 8px"
                    @click="lending_manage(l.pkidx, 2)"
                  >
                    {{ $t("club_text37") }}
                  </button>
                  <button
                    class="btn mb-0 take-btn give-btn opacity-100 w-100"
                    style="border-radius: 8px"
                    @click="lending_manage(l.pkidx, 1)"
                  >
                    {{ $t("club_text38") }}
                  </button>
                </div>
                <div
                  v-else
                  class="d-flex justify-content-center align-items-center flex-column btn-loan"
                  style="gap: 15px"
                >
                  <button
                    class="btn mb-0 take-btn reject-btn opacity-100 w-100"
                    style="border-radius: 8px"
                    v-if="l.status == 'P'"
                    @click="lending_repaid(l.pkidx)"
                  >
                    {{ $t("club_text40") }}
                  </button>
                  <button
                    class="btn mb-0 take-btn settle-btn opacity-100 w-100"
                    style="border-radius: 8px"
                    v-else-if="l.status == 'N'"
                  >
                    {{ $t("club_text37") }}
                  </button>
                  <button
                    class="btn mb-0 take-btn give-btn opacity-100 w-100"
                    style="border-radius: 8px"
                    v-else
                  >
                    {{ $t("club_text39") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="d-flex justify-content-center flex-column align-items-center my-3"
        >
          <h5 class="m-0">{{ $t("nodata") }}</h5>
        </div>
      </div>
    </div>

    <div class="finance_page home_vip_page" v-show="flag_finance_page">
      <div class="finance_card">
        <div class="finance_close" @click="flag_finance_page = false">×</div>
        <div class="finance_title">
          {{ $t("club_text42") }}
        </div>

        <div class="finance_main">
          <div class="finance_deposit" style="margin-top: 10px">
            <div class="d-flex align-items-center mt-4">
              <p class="mb-0">{{ $t("club_text43") }}：</p>
              <div class="position-relative" style="flex: 1">
                <div
                  class="position-relative d-flex align-items-center buy_coin_vip"
                >
                  <input
                    type="text"
                    class="text-center"
                    v-model="rate1"
                    style="background: transparent; color: #fff"
                  />
                  <span class="deposit_cur"> % </span>
                </div>
                <p v-show="flag_pay_error" class="mt-1 position-absolute">
                  <span style="color: #dc3545">{{
                    $t("text_tip_phonenum")
                  }}</span>
                </p>
              </div>
            </div>

            <div class="d-flex align-items-center mt-4">
              <p class="mb-0">{{ $t("club_text44") }}：</p>
              <div class="position-relative" style="flex: 1">
                <div
                  class="position-relative d-flex align-items-center buy_coin_vip"
                >
                  <input
                    type="text"
                    class="text-center"
                    v-model="rate2"
                    style="background: transparent; color: #fff"
                  />
                  <span class="deposit_cur"> % </span>
                </div>
                <p v-show="flag_pay_error" class="mt-1 position-absolute">
                  <span style="color: #dc3545">{{
                    $t("text_tip_phonenum")
                  }}</span>
                </p>
              </div>
            </div>

            <div class="col-10 text-white mx-auto mt-5 mb-4">
              <button
                class="border-0 text-white bg_green_btn p-2 w-100"
                :disabled="flag_pay_error"
                :style="
                  flag_pay_error
                    ? 'cursor: no-drop;background-color: #CCC;'
                    : ''
                "
                @click="update_lending_rate()"
              >
                <span>{{ $t("club_text45") }}</span>
              </button>
            </div>

            <p style="color: gray" class="text-center">
              {{ $t("club_text46") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import dateTime from "@/components/utils/datatime";
export default {
  computed: {
    filterSearch() {
      return this.list.filter((v) => {
        if (this.keyWord.length > 0) {
          if (this.is_not_id == "S") {
            return (
              v.status == "S" &&
              v.userid.toLowerCase().includes(this.keyWord.toLowerCase())
            );
          } else
            return (
              v.status != "S" &&
              v.userid.toLowerCase().includes(this.keyWord.toLowerCase())
            );
        } else if (this.is_not_id == "S") {
          return v.status == "S";
        } else return v.status != "S";
      });
    },
  },
  data() {
    return {
      is_not_id: "S",
      keyWord: "",
      flag_finance_page: false,
      old_begin: dateTime.getoneday(-1),
      old_end: dateTime.getoneday(0),
      rate1: 10000000,
      rate2: 10000000,
      list: [],
    };
  },
  methods: {
    lending_rate_vip() {
      let param = {
        action: "lending_rate_vip",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        if (res.status == "success") {
          this.rate1 = res.rate1;
          this.rate2 = res.rate2;
        }
      });
    },
    update_lending_rate() {
      let param = {
        action: "update_lending_rate",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
          rate1: this.rate1,
          rate2: this.rate2,
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        if (res.status == "success") {
          this.$MSG.API_popmsg(res.msg, "", "success");
          this.lending_rate_vip();
          this.flag_finance_page = false;
        }
      });
    },
    lending_list() {
      if (
        Cookies.get("code") == null ||
        Cookies.get("code") == "" ||
        localStorage.getItem("uid") == null ||
        localStorage.getItem("uid") == ""
      ) {
        this.$store.commit("islogin", true);
        this.$store.dispatch("logout_public");
      } else {
        let param = {
          action: "lending_list",
          body: {
            uid: localStorage.getItem("uid"),
            session_code: Cookies.get("code"),
            clubid: localStorage.getItem("clubid"),
            begindate: this.old_begin,
            enddate: this.old_end,
          },
        };
        this.$store.dispatch("getpost", param).then((res) => {
          if (res.status == "success") {
            this.list = res.data;
          }
        });
      }
    },
    lending_manage(e, f) {
      let param = {
        action: "lending_manage",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
          clubid: localStorage.getItem("clubid"),
          pkidx: e,
          type: f,
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        if (res.status == "success") {
          this.$MSG.API_popmsg(res.msg, "", "success");
          this.lending_list();
        }
      });
    },
    lending_repaid(e) {
      if (
        Cookies.get("code") == null ||
        Cookies.get("code") == "" ||
        localStorage.getItem("uid") == null ||
        localStorage.getItem("uid") == ""
      ) {
        this.$store.commit("islogin", true);
        this.$store.dispatch("logout_public");
      } else {
        let param = {
          action: "lending_repaid",
          body: {
            uid: localStorage.getItem("uid"),
            session_code: Cookies.get("code"),
            clubid: localStorage.getItem("clubid"),
            pkidx: e,
          },
        };
        this.$store.dispatch("getpost", param).then((res) => {
          if (res.status == "success") {
            this.$MSG.API_popmsg(res.msg, "", "success");
            this.lending_list();
          }
        });
      }
    },
    change_method_name(e) {
      if (e == "1") return this.$t("loan_text18");
      else return this.$t("loan_text19");
    },
  },
  components: {},
  watch: {},
  created() {
    this.lending_rate_vip();
    this.lending_list();
  },
};
</script>
