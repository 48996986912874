<template>
  <div
    class="mx-auto mb-5"
    style="max-width: 1200px; min-height: 550px; padding-top: 30px"
  >
    <div class="d-flex w-100">
      <div>
        <h1 class="help_title">{{ $t("footer_t") }}</h1>
        <div
          class="ft_14"
          v-html="
            $t('term_con1') +
            $t('term_con2') +
            $t('term_con3') +
            $t('term_con4')
          "
        ></div>
      </div>
    </div>
  </div>
</template>
