<template>
  <form method="POST" @submit="changePassword" ref="change_pwd">
    <div class="mybank_second">
      <div>
        <div class="nh-NavigationHeaderModule nh-NavigationHeaderModule-wide">
          <div class="nh-NavigationHeaderModule_Title">
            {{ $t("reg_trade_pw_title") }}
          </div>
        </div>
      </div>
      <div>
        <div class="wmeTextBox">
          <div>
            <span class="flexiField_Label paymentnumber-label">{{
              $t("oldtradepwd")
            }}</span>
            <input
              type="password"
              minlength="4"
              maxlength="6"
              CssClass="flexiField_Input"
              :placeholder="this.$t('oldtradepwdsub')"
              v-model="pwd"
              required
            />
          </div>
        </div>
        <div class="wmeTextBox">
          <div>
            <span class="flexiField_Label paymentnumber-label">{{
              $t("oldtradepwd1")
            }}</span>
            <input
              type="password"
              minlength="4"
              maxlength="6"
              CssClass="flexiField_Input"
              :placeholder="this.$t('oldtradepwd1sub')"
              v-model="newpwd"
              required
            />
            <p>
              <span class="tips">{{ $t("oldtradepwd1subsub") }} </span>
            </p>
          </div>
        </div>

        <div class="wmeTextBox">
          <div>
            <span class="flexiField_Label paymentnumber-label">{{
              $t("oldtradepwd2")
            }}</span>
            <input
              type="password"
              minlength="4"
              maxlength="6"
              CssClass="flexiField_Input"
              :placeholder="this.$t('oldtradepwd2sub')"
              v-model="confirpwd"
              required
            />
          </div>
        </div>

        <div class="col-12 text-center">
          <button class="border-0 main_color bg_green_btn p-2 w-50 mt-3">
            {{ $t("submit_oldtradepwd") }}
          </button>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import Cookies from "js-cookie";

export default {
  computed: {},
  data() {
    return {
      pwd: "",
      newpwd: "",
      confirpwd: "",
    };
  },
  methods: {
    changePassword: function (e) {
      e.preventDefault();
      if (this.pwd && this.newpwd && this.confirpwd) {
        if (this.checkform()) {
          let param = {
            action: "ChangeWithdrawPassword",
            body: {
              uid: localStorage.getItem("uid"),
              session_code: Cookies.get("code"),
              password: this.pwd,
              password2: this.confirpwd,
            },
          };
          this.$store.dispatch("getpost", param).then((res) => {
            if (res.status == "success")
              this.$MSG.API_popmsg(res.msg, "regi_success", res.status);
            else this.$MSG.API_popmsg(res.msg, "", res.status);
          });
        }
      } else {
        var error_str = "";
        if (!this.pwd)
          error_str +=
            this.$t("oldpwd") + " " + this.$t("error_required") + "</br>";
        if (!this.newpwd)
          error_str +=
            this.$t("newpwd") + " " + this.$t("error_required") + "</br>";
        if (!this.confirpwd)
          error_str +=
            this.$t("comfpwd") + " " + this.$t("error_required") + "</br>";
        this.$MSG.API_popmsg(error_str, "", "error");
      }
    },
    checkform: function () {
      var rules = /^([0-9]{4,6})+$/;
      if (!rules.test(this.pwd) || !rules.test(this.newpwd)) {
        this.$MSG.API_popmsg(
          this.$t("Member_registerTradePasswordPatternErrMessage"),
          "",
          "error"
        );
        return false;
      } else if (this.newpwd !== this.confirpwd) {
        this.$MSG.API_popmsg(
          this.$t("Member_registerCheckPasswordPatternErrMessage"),
          "",
          "error"
        );
        return false;
      } else return true;
    },
  },
  created() {},
};
</script>
