<template>
  <div id="mess_box">
    <div
      class="row position-absolute newmess_div"
      v-for="(l, k) in list"
      :key="k"
      :style="{ top: k * 150 + 30 + 'px' }"
      :id="l.subject + k"
      @click="alert_message()"
      v-show="flag_new_message"
    >
      <div class="d-flex align-items-center newmess_title ps-0 rounded-top">
        <img
          src="@/assets/img/main/new_message.png"
          style="width: 50px; height: 50px; padding: 5px"
        />
        <span>{{ $t("left_menu_text6").toUpperCase() }}</span>
        <span class="text-end">{{ l.cdate1 }} {{ l.cdate2 }}</span>
      </div>
      <div class="newmess_con py-1 rounded-bottom">
        <span class="fw-bolder">{{ l.subject }}</span
        ><br /><span>{{ l.body }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";

export default {
  name: "newmessage",
  computed: {},
  data() {
    return {
      list: [],
      code: Cookies.get("code"),
      isMobile: this.$Utils.isMobile(),
      flag_new_message: false,
    };
  },
  methods: {
    msg: function (d) {
      this.list = [];
      let param = {
        action: "message1",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
          day: d,
          isread: "N",
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        res = JSON.parse(JSON.stringify(res).replaceAll("@", "<br/>"));
        if (res["data"] != "" && res["data"] != null) {
          this.flag_new_message = true;
          document.body.onclick = this.clickall;
          this.list = res["data"].filter((v, i) => {
            var j = 6;
            if (this.isMobile != "pc端") {
              j = 1;
            }

            if (i < j) {
              return v;
            }
          });
        }
      });
    },
    alert_message() {
      this.$router.push("/VIP/message");

      // document.getElementById(el).classList.add("newmess_hide");
      // setTimeout(() => {
      //   var k = 0;
      //   this.list.forEach((element, index) => {
      //     try {
      //       var id = document.getElementById(element["subject"] + index);
      //       if (element["subject"] + index != el) {
      //         id.style.top = k * 150 + 30 + "px";
      //         k++;
      //       } else document.getElementById(el).remove();
      //     } catch {
      //       console.log(element);
      //     }
      //   });
      // }, 100);
    },
    clickall(event) {
      var one = document.getElementById("mess_box");
      if (one) {
        if (!one.contains(event.target)) {
          this.flag_new_message = false;
          document.body.onclick = null;
        }
      }
    },
  },
  mounted() {
    if (
      Cookies.get("code") != null &&
      Cookies.get("code") != "" &&
      localStorage.getItem("uid") != null &&
      localStorage.getItem("uid") != ""
    )
      this.msg("30");
  },
};
</script>
