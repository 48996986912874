<template>
  <div v-show="flag_box_show">
    <div class="extra_float_big_bg"></div>
    <div class="draw_float_big_main">
      <div class="draw_float_big">
        <div class="extra-close" @click="this.$emit('close_extra_main', false)">
          <svg
            class="svg-inline--fa fa-times fa-w-11"
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="times"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 352 512"
            data-fa-i2svg=""
          >
            <path
              fill="currentColor"
              d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
            ></path>
          </svg>
        </div>
        <div>
          <img
            src="@/assets/img/sign_up/extra_open.png"
            alt=""
            class="extra-box"
          />
          <div class="numbers-box" style="margin-bottom: 40px">
            <span class="extra_span extra_1">{{ payment }}</span>
            <span class="extra_span extra_2">{{ $t("cur") }}</span>
          </div>
          <div class="extra-btn">
            {{ $t("shared_comfirm").toUpperCase() }}
          </div>
          <img src="@/assets/img/sign_up/extra.gif" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
export default {
  data() {
    return {
      payment: 0,
      flag_box_show: false,
    };
  },
  methods: {
    click_go() {
      let param = {
        action: "extra_bonus",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        if (res.status == "success") {
          this.payment = res.payment;
          this.flag_box_show = true;
        } else {
          this.$emit("close_extra_main", false);
        }
      });
    },
  },
  created() {
    if (
      Cookies.get("code") == null ||
      Cookies.get("code") == "" ||
      localStorage.getItem("uid") == null ||
      localStorage.getItem("uid") == ""
    ) {
      this.$store.commit("islogin", true);
      this.$store.dispatch('logout_public');
    } else {
      this.click_go();
    }
  },
};
</script>
