<template>
  <div class="wc-WebConsoleModule_SiteContainer">
    <div class="wc-PageView" style="height: 100%">
      <div class="wc-PageView_Main wc-MembersPageResponsive_MainContainer">
        <div class="wcl-CommonElementStyle_NavContentContainer">
          <div class="mim-MembersIframeModule">
            <div class="wc-WebConsoleModule">
              <div
                class="wc-WebConsoleModule_Content wc-WebConsoleModule_Content-footer-displayed"
              >
                <div
                  class="wc-PageView wc-PageView-wide wc-PageView-1400 wc-PageView-nonavmenu"
                >
                  <div
                    class="wc-PageView_ContentContainer wc-OpenAccountLegacyPage_ContentContainer"
                  >
                    <div class="wmeTextBox mt-xl-5" v-if="this.check">
                      <div class="payment_banner">
                        <div class="payment_container">
                          <div class="payment-method-selector-container mb-3">
                            <!-- <div
                              class="payment-method me-3"
                              @click="this.channelway = 'USDT'"
                              :class="
                                this.channelway == 'USDT'
                                  ? 'payment_selected'
                                  : ''
                              "
                            >
                              <img
                                src="@/assets/img/main/USDT.png"
                                alt=""
                                style="width: 100px"
                              />
                            </div> -->
                            <div
                              class="payment-method bank_with"
                              @click="this.channelway = 'BANK'"
                              :class="
                                this.channelway == 'BANK'
                                  ? 'payment_selected'
                                  : ''
                              "
                              v-if="isCheckBank"
                            >
                              <img
                                src="@/assets/img/main/bank_card.png"
                                style="width: 60px; height: 60px"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        class="usdt_page page"
                        :class="this.channelway == 'BANK' ? '' : 'Hidden'"
                      >
                        <div class="usdt_card">
                          <div class="usdt_title">{{ $t("add_Bank") }}</div>
                          <select
                            v-model="bankname"
                            :disabled="disabled"
                            :style="disabled ? 'appearance: none' : ''"
                          >
                            <option v-for="(l, i) in list" :key="i" :value="l">
                              {{ l }}
                            </option>
                          </select>
                        </div>
                        <div class="usdt_card">
                          <div class="usdt_title">{{ $t("add_Account") }}</div>
                          <input
                            type="text"
                            v-model="cname"
                            disabled="false"
                            style="cursor: no-drop"
                          />
                        </div>
                        <div class="usdt_card">
                          <div class="usdt_title">{{ $t("add_Card") }}</div>
                          <input
                            type="text"
                            v-model="cardno"
                            :disabled="disabled"
                          />
                        </div>

                        <div class="usdt_card" style="display: none">
                          <div class="usdt_title">Swift Code/BIC</div>
                          <input type="text" v-model="swift" />
                        </div>
                      </div>

                      <div
                        class="usdt_page page"
                        :class="this.channelway == 'USDT' ? '' : 'Hidden'"
                      >
                        <div class="usdt_card">
                          <div class="usdt_title">{{ $t("usdt_type") }}</div>
                          <select
                            v-model="usdtType"
                            :disabled="disabled"
                            :style="disabled ? 'appearance: none' : ''"
                          >
                            <option value="TRC20">Tron (TRC20)</option>
                            <!-- <option value="ERC20">Ethereum (ERC 20)</option> -->
                            <option value="TRX">TRX</option>
                          </select>
                        </div>

                        <div class="usdt_card">
                          <div class="usdt_title">{{ $t("usdt_wallet") }}</div>
                          <input
                            type="text"
                            v-model="wallet"
                            :disabled="disabled"
                          />
                        </div>
                      </div>
                      <div>
                        <span
                          class="flexiField_Label paymentnumber-label w-font"
                          >{{ $t("main_acc") }}({{
                            $t("main_minwithdrawl")
                          }}：{{ min }}</span
                        >
                      </div>
                      <div class="deposit_amount w-100 d-flex">
                        <input
                          type="text"
                          :placeholder="this.$t('withdraw_Withdrawal')"
                          v-model="payment"
                          style="height: auto"
                          class="text-center"
                        />
                        <span class="deposit_cur">
                          {{ $t("cur") }}
                        </span>
                      </div>
                      <p
                        class="mt-2 mb-1"
                        v-show="flag_pay_error"
                        style="color: red"
                      >
                        {{ $t("error_tips") }}
                      </p>

                      <p style="margin-top: 3%">
                        {{ errormsg }}
                      </p>

                      <!-- <p class="mb-0">
                        {{ $t("draw_fee") }}：{{ withdrawfee }}={{
                          parseFloat((withdrawfee * payment).toFixed(2))
                        }}
                        {{ $t("cur") }}
                      </p> -->

                      <div class="usdt_page">
                        <div class="usdt_card">
                          <div class="usdt_title">
                            {{ $t("reg_trade_pw_title") }}
                          </div>
                        </div>
                      </div>

                      <div class="w-100 d-flex">
                        <input
                          :type="flag_eye2 ? 'text' : 'password'"
                          :placeholder="this.$t('reg_trade_pw_tips')"
                          v-model="trade_pw"
                          minlength="4"
                          maxlength="6"
                          class="ps-2"
                        />
                        <span
                          class="validating-icon"
                          @click="flag_eye2 = !flag_eye2"
                          style="position: absolute; right: 15px; top: 6px"
                        >
                          <img
                            :src="
                              flag_eye2
                                ? require('@/assets/img/main/open_eye.png')
                                : require('@/assets/img/main/close_eye.png')
                            "
                          />
                        </span>
                      </div>

                      <fieldset
                        data-section="submit"
                        class="deposit-submit-astropayonetouch"
                      >
                        <div class="col-12 text-center">
                          <button
                            id="submit"
                            type="button"
                            class="border-0 main_color bg_green_btn p-2 w-50 mt-3"
                            @click="withdrawl2"
                            :disabled="flag_pay_error"
                            :style="
                              flag_pay_error
                                ? 'cursor: no-drop;background-color: #CCC;'
                                : ''
                            "
                          >
                            {{ $t("withdrawl").toUpperCase() }}
                          </button>
                        </div>
                      </fieldset>
                    </div>
                    <div v-if="!this.check">
                      <i></i>
                      <div>
                        <img
                          src="@/assets/img/main/withdraw_icon_wait.png"
                          class="w-100"
                        />
                        <p id="countdown_tip">
                          <span id="countdown" style="color: red">{{
                            $t("DepositWithdraw_wait")
                          }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import Cookies from "js-cookie";

export default defineComponent({
  name: "Home",
  computed: {
    disabled: function () {
      if (this.channelway == "USDT") {
        return this.usdt;
      } else {
        return this.bankcard;
      }
    },
  },
  data() {
    return {
      channelway: "BANK",
      bankname: "",
      cname: "",
      cardno: "",
      swift: "",
      usdtType: "TRC20",
      wallet: "",
      list: [],
      check: true,
      total_deduct: 0,
      payment: 0,
      min: 0,
      max: 0,
      errormsg: "",
      card: [],
      usdt: false,
      bankcard: false,
      isCheckBank: true,
      flag_pay_error: false,
      withdrawfee: 0,
      trade_pw: "",
      flag_eye2: false,
    };
  },
  methods: {
    bankList: function () {
      let param = {
        action: "banklist",
        body: {
          uid: localStorage.getItem("uid"),
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        this.list = res.list;
        this.bankname = this.list[0];
      });
    },
    withdrawl1: function () {
      let param = {
        action: "withdraw1",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        try {
          var o = res;
          this.card = o["data"];
          this.min = o["min_withdraw"];
          this.max = o["max_withdraw"];
          this.errormsg = o["ErrorMessage"];
          this.total_deduct = o["total_deduct"];
          this.payment = this.min;
          this.withdrawfee = o["withdrawfee"];
          for (var v in o["data"]) {
            if (this.channelway == "USDT") {
              if (String(o["data"][v].bankname).includes("USDT")) {
                this.usdtType = String(o["data"][v].bankname).split("/")[1];
                this.wallet = o["data"][v].cardno;
                this.cname = o["data"][v].cname;
                if (this.wallet != "") {
                  this.usdt = true;
                }
              }
            } else {
              if (
                !String(o["data"][v].bankname).includes("USDT") &&
                !String(o["data"][v].bankname).includes("credicard")
              ) {
                this.bankname = o["data"][v].bankname;
                this.cardno = o["data"][v].cardno;
                this.cname = o["data"][v].cname;
                if (this.cardno != "") {
                  this.bankcard = true;
                }
              }
            }
          }
        } catch {
          if (String(res).indexOf("http") != -1) {
            this.check = false;
          } else {
            this.$MSG.API_popmsg(res, "home", "error");
          }
        }
      });
    },
    withdrawl2: function () {
      let cname, bankname, cardno, city, max, min, money;
      max = parseInt(this.max);
      min = parseInt(this.min);
      money = parseInt(this.payment);

      if (this.total_deduct == 0) {
        if (money >= min && money <= max) {
          if (this.channelway == "USDT") {
            cname = this.cname;
            bankname = this.usdtType;
            cardno = this.wallet;
            city = "";
            if (!this.disabled) {
              this.addbankcard("usdt");
            }
          } else {
            cname = this.cname;
            bankname = this.bankname;
            cardno = this.cardno;
            city = this.swift;
            if (!this.disabled) {
              this.addbankcard("bank");
            }
          }
          if (String(cardno).length > 0) {
            let param = {
              action: "withdraw2",
              body: {
                uid: localStorage.getItem("uid"),
                session_code: Cookies.get("code"),
                cname: cname,
                bankname: bankname,
                cardno: cardno,
                capital: "",
                city: city,
                payment: money,
                withdrawpw: this.trade_pw,
              },
            };
            this.$store.dispatch("getpost", param).then((res) => {
              if (res.status == "success") {
                this.$MSG.API_popmsg(res.msg, "withdraw", "success");
              } else this.$MSG.API_popmsg(res.msg, "", "warning");
            });
          }
        } else this.$MSG.API_popmsg(this.errormsg, "", "error");
      } else
        this.$MSG.API_popmsg(
          this.$t("withdraw_error_audit") +
            "<br/>" +
            this.$t("withdraw_audit") +
            "：" +
            this.total_deduct,
          "",
          "error"
        );
    },
    addbankcard: function (e) {
      let param = null;
      if (e == "usdt") {
        param = {
          action: "addbankcard",
          body: {
            uid: localStorage.getItem("uid"),
            session_code: Cookies.get("code"),
            methods: "USDT/" + this.usdtType,
            cardno: this.wallet,
            name: this.cname,
            bankcity: "",
          },
        };
      } else if (e == "bank") {
        param = {
          action: "addbankcard",
          body: {
            uid: localStorage.getItem("uid"),
            session_code: Cookies.get("code"),
            methods: this.bankname,
            cardno: this.cardno,
            name: this.cname,
            bankcity: "",
          },
        };
      }

      if (param != null) {
        this.$store.dispatch("getpost", param);
      }
    },
  },
  created() {
    this.bankList();
    this.withdrawl1();
  },
  watch: {
    payment: function (e) {
      if (!Number(e)) {
        this.flag_pay_error = true;
      } else this.flag_pay_error = false;
    },
    channelway: function (e) {
      this.cname = "";
      this.wallet = "";
      this.cardno = "";
      if (e == "USDT") {
        this.card.forEach((v) => {
          if (String(v.bankname).includes("USDT")) {
            this.usdtType = String(v.bankname).split("/")[1];
            this.wallet = v.cardno;
            this.cname = v.cname;
            if (this.wallet != "") {
              this.usdt = true;
            }
          }
        });
      } else {
        this.card.forEach((v) => {
          if (
            !String(v.bankname).includes("USDT") &&
            !String(v.bankname).includes("credicard")
          ) {
            this.bankname = v.bankname;
            this.cardno = v.cardno;
            this.cname = v.cname;
            if (this.cardno != "") {
              this.bankcard = true;
            }
          }
        });
      }
    },
  },
});
</script>
