<template>
  <div>
    <div class="home_vip_class">
      <img src="@/assets/img/img_intro_company/home-pc.png" class="w-100" />
      <div
        class="d-flex justify-content-between align-items-center mt-4 flex-1199-column flex-1199-gap flex-768-1024-row"
      >
        <div
          class="d-flex justify-content-start align-items-start flex-1199-column flex-768-1024-row"
          style="flex: 1; width: 100%"
        >
          <span class="mb-1199-1 text-nowrap"> {{ $t("text_vip_4") }}：</span>
          <div
            style="flex: 1; display: grid"
            class="vip_link grid-1199-1 grid-1200-2 w-100"
          >
            <div
              class="px-2 py-2 vip_url_border"
              v-for="(l, k) in url"
              :key="l"
              :style="[
                k == 0
                  ? 'border-bottom: 1px solid #fff;border-right: 1px solid #fff'
                  : k == 1
                  ? 'border-bottom: 1px solid #fff'
                  : k == 2
                  ? 'border-right: 1px solid #fff'
                  : '',
              ]"
            >
              <div class="d-flex justify-content-between align-items-center">
                <span class="ellipsis_text"> {{ l }}</span>
                <button
                  @click="Copy(l)"
                  class="py-1 agent_detail_btn text-nowrap"
                >
                  {{ $t("text_copy") }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <button
          class="transparent-button transparent-button-active user-header-deposit-btn ms-2 w-auto m-1199-auto"
          @click="flag_finance_page = true"
        >
          {{ $t("text_vip_5") }}
        </button>
      </div>

      <div class="mt-5">
        <div class="">
          <div class="p-2" style="background-color: #a89359; color: #fff">
            {{ $t("text_message_ann") }}
          </div>
          <div class="p-2" style="background-color: #fff; color: #1a1a1a">
            <div class="mb-3" v-for="l in list" :key="l">
              <p class="text-end mb-1">{{ l.cdate }}</p>
              <span style="word-break: break-all; white-space: pre-line">{{
                l.cht_msg
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <qaview />
    </div>

    <div class="finance_page home_vip_page" v-show="flag_finance_page">
      <div class="finance_card">
        <div class="finance_close" @click="flag_finance_page = false">×</div>
        <div class="finance_title">
          {{ $t("text_vip_5") }}
        </div>

        <div class="finance_main">
          <div class="finance_deposit" style="margin-top: 10px">
            <div class="d-flex align-items-center">
              <p class="mb-0">{{ $t("text_vip_15") }}：</p>
              <div class="position-relative" style="flex: 1">
                <div
                  class="position-relative d-flex align-items-center buy_coin_vip"
                >
                  <input
                    type="text"
                    class="text-center"
                    v-model="payment"
                    style="background: transparent; color: #fff"
                  />
                  <span class="deposit_cur">
                    {{ $t("cur") }}
                  </span>
                </div>
                <p v-show="flag_pay_error" class="mt-1 position-absolute">
                  <span style="color: #dc3545">{{
                    $t("text_tip_phonenum")
                  }}</span>
                </p>
              </div>
            </div>

            <div class="mt-4">
              <p class="mb-0">{{ $t("text_vip_16") }}：{{ get_score }}</p>
            </div>

            <div class="mt-4 d-flex align-items-center">
              <p class="mb-0">{{ $t("reg_trade_pw_title") }}：</p>
              <div
                class="position-relative d-flex align-items-center"
                style="flex: 1"
              >
                <input
                  :type="flag_eye2 ? 'text' : 'password'"
                  class="finance_text text-center"
                  v-model="withdrawpw"
                  minlength="4"
                  maxlength="6"
                />
                <span
                  class="validating-icon"
                  @click="flag_eye2 = !flag_eye2"
                  style="position: absolute; right: 15px; top: 6px"
                >
                  <img
                    :src="
                      flag_eye2
                        ? require('@/assets/img/main/open_eye.png')
                        : require('@/assets/img/main/close_eye.png')
                    "
                  />
                </span>
              </div>
            </div>

            <div class="col-10 text-white mx-auto my-3">
              <button
                class="border-0 text-white bg_green_btn p-2 w-100"
                :disabled="flag_pay_error"
                :style="
                  flag_pay_error
                    ? 'cursor: no-drop;background-color: #CCC;'
                    : ''
                "
                @click="buy_coin()"
              >
                <span>{{ $t("text_vip_17") }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Cookies from "js-cookie";
import qaview from "./vip_common_qa.vue";
export default {
  name: "home_vip",
  data() {
    return {
      flag_finance_page: false,
      payment: 0,
      flag_pay_error: false,
      flag_eye2: false,
      withdrawpw: "",
      url: [],
      list: [],
    };
  },
  computed: {
    ...mapGetters(["master_balance_hall", "member_total_coin"]),
    get_score() {
      if (!this.flag_pay_error)
        return this.payment * this.$store.state.service.qq.rate;
      else return 0;
    },
  },
  methods: {
    ...mapActions(["getService"]),
    get_info() {
      if (
        Cookies.get("code") == null ||
        Cookies.get("code") == "" ||
        localStorage.getItem("uid") == null ||
        localStorage.getItem("uid") == ""
      ) {
        this.$store.commit("islogin", true);
        this.$store.dispatch("logout_public");
      } else {
        let param = {
          action: "myclub_vip",
          body: {
            uid: localStorage.getItem("uid"),
            session_code: Cookies.get("code"),
          },
        };
        this.$store.dispatch("getpost", param).then((res) => {
          if (res.status == "success") {
            if (res.master_club_list.length > 0) {
              localStorage.setItem("clubid", res.master_club_list[0].clubid);
              this.url = res.master_club_list[0].url.split(" , ");

              this.$store.commit(
                "master_balance_hall",
                res.master_club_list[0].master_balance_hall
              );
              this.$store.commit(
                "member_total_coin",
                res.master_club_list[0].balance_hall
              );
            }
          } else {
            param = {
              action: "create_club_vip",
              body: {
                uid: localStorage.getItem("uid"),
                session_code: Cookies.get("code"),
                userid: localStorage.getItem("uid"),
              },
            };
            this.$store.dispatch("getpost", param).then((res) => {
              if (res.status == "success") {
                localStorage.setItem("clubid", res.clubid);
                this.url = res.url.split(" , ");
              } else this.$MSG.API_popmsg(res.msg, "", "warning");
            });
          }
        });
      }
    },
    buy_coin() {
      if (
        Cookies.get("code") == null ||
        Cookies.get("code") == "" ||
        localStorage.getItem("uid") == null ||
        localStorage.getItem("uid") == ""
      ) {
        this.$store.commit("islogin", true);
        this.$store.dispatch("logout_public");
      } else {
        let param = {
          action: "buy_club_coin",
          body: {
            uid: localStorage.getItem("uid"),
            session_code: Cookies.get("code"),
            amount: this.payment,
            clubid: localStorage.getItem("clubid"),
          },
        };
        this.$store.dispatch("getpost", param).then((res) => {
          if (res.status == "success") {
            location.reload();
          } else this.$MSG.API_popmsg(res.msg, "", "warning");
        });
      }
    },
    Copy: function (val_text) {
      this.$copyText(val_text).then(
        () => {
          this.$MSG.API_popmsg(
            this.$t("text_copied") + "<br/>" + val_text,
            "",
            "success"
          );
        },
        (e) => {
          this.$MSG.API_popmsg(
            this.$t("text_notcopy") + "： " + e.text,
            "",
            "error"
          );
        }
      );
    },
    club_message() {
      let param = {
        action: "club_message",
        body: {},
      };
      this.$store.dispatch("getpost", param).then((res) => {
        if (res.status == "success") {
          this.list = res.data;
        }
      });
    },
  },
  created() {
    this.getService();
    this.get_info();
    this.club_message();
  },
  watch: {
    payment: function (e) {
      if (!Number(e)) {
        this.flag_pay_error = true;
      } else this.flag_pay_error = false;
    },
  },
  mounted() {},
  components: { qaview },
};
</script>
