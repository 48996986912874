<template>
  <div>
    <div class="finance_page home_vip_page">
      <div class="finance_card">
        <div
          class="finance_close"
          @click="this.$emit('close_inter_main', false)"
        >
          ×
        </div>
        <div class="finance_title">
          {{ $t("text_inter_1") }}
        </div>

        <div class="finance_main" style="width: 90%; margin-bottom: 15px">
          <div
            class="finance_deposit"
            style="
              margin-top: 10px;
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              grid-gap: 20px 10px;
            "
          >
            <div @click="jump_pop()" class="c-pointer">
              <img
                src="@/assets/img/img_inter/ctry_ic_vietnam.png"
                style="width: 35px"
                class="me-2"
              />
              <span style="font-size: 18px">{{ $t("text_inter_2") }}</span>
            </div>
            <div @click="jump_pop()" class="c-pointer">
              <img
                src="@/assets/img/img_inter/ctry_ic_philippines.png"
                style="width: 35px"
                class="me-2"
              />
              <span style="font-size: 18px">{{ $t("text_inter_3") }}</span>
            </div>
            <div @click="jump_pop()" class="c-pointer">
              <img
                src="@/assets/img/img_inter/ctry_ic_southkorea.png"
                style="width: 35px"
                class="me-2"
              />
              <span style="font-size: 18px">{{ $t("text_inter_4") }}</span>
            </div>
            <div @click="jump_pop()" class="c-pointer">
              <img
                src="@/assets/img/img_inter/ctry_ic_hongkong.png"
                style="width: 35px"
                class="me-2"
              />
              <span style="font-size: 18px">{{ $t("text_inter_5") }}</span>
            </div>
            <div
              @click="jump_url_pop('https://usdt.bcvipx.com/#/')"
              class="c-pointer"
            >
              <img
                src="@/assets/img/img_inter/ctry_ic_usdt.png"
                style="width: 35px"
                class="me-2"
              />
              <span style="font-size: 18px">{{ $t("text_inter_6") }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    jump_pop: function () {
      this.$MSG.API_popmsg(this.$t("text_inter_7"), "", "warning");
    },
    jump_url_pop: function (url) {
      window.open(url);
    },
  },
};
</script>
