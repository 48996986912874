<template>
  <div class="casino-header-container-new">
    <div
      class="d-flex justify-content-between align-items-center position-relative"
    >
      <span class="text-center club_span_big ms-0">
        {{ $t("text_club7") }}
      </span>

      <p class="text-center mb-0">{{ begin }} ～ {{ end }}</p>
    </div>

    <div
      class="mt-2 d-flex justify-content-start align-items-center flex-1023-column"
    >
      <div
        class="d-flex justify-content-start align-items-center flex-1023-column w-100"
      >
        <div
          class="serach_date_card-new d-flex align-items-center me-3 me-1023-3 w-100"
        >
          <div class="dateControlLabel">{{ $t("history_begin") }}:</div>
          <input type="date" v-model="old_begin" Style="padding: 15px;" />
        </div>
        <div
          class="serach_date_card-new d-flex align-items-center me-3 me-1023-3 w-100"
        >
          <div class="dateControlLabel">{{ $t("history_end") }}:</div>
          <input type="date" v-model="old_end" Style="padding: 15px;" />
        </div>
      </div>
      <div
        class="show-history-button rounded-5 bg_green_btn m-0 btn-ft w-100"
        @click="get_data()"
      >
        {{ $t("text_search") }}
      </div>
    </div>

    <div
      class="d-flex justify-content-start align-items-center mt-3"
      style="background-color: transparent"
    >
      <div class="">{{ $t("text_totalBetAmount") }}：{{ totalBetAmount }}</div>
      &nbsp; &nbsp; &nbsp;
      <div class="">
        {{ $t("text_totalWinLose") }}：<span
          :style="[
            totalWinLose < 0
              ? 'color:#00af4c;font-weight:600'
              : totalWinLose > 0
              ? 'color:#e7323e;font-weight:600'
              : '',
          ]"
          >{{ Math.abs(totalWinLose) }}</span
        >
      </div>
    </div>

    <div
      v-if="list.length != 0"
      class="mt-3 p-3"
      style="background: #071d2a; border-radius: 16px"
    >
      <div class="new_data" style="height: 45vh; overflow-y: auto">
        <div
          class="new_data-div d-flex justify-content-start align-items-center flex-wrap new_data-his"
          v-for="l in list"
          :key="l"
          style="gap: 25px"
        >
          <div
            class="d-flex justify-content-center align-items-center"
            style="gap: 20px"
          >
            <div>
              <span> {{ $t("text_game_center") }}</span
              >：<span>{{ l.gametype }}</span>
            </div>
            <div>
              <span> {{ $t("text_losewin") }}</span
              >：
              <span
                :style="[
                  l.losewin < 0
                    ? 'color:#00af4c;font-weight:600'
                    : l.losewin > 0
                    ? 'color:#e7323e;font-weight:600'
                    : '',
                ]"
                >{{ Math.abs(l.losewin) }}
              </span>
            </div>
          </div>
          <div
            class="d-flex justify-content-center align-items-center"
            style="gap: 20px"
          >
            <div>
              <span> {{ $t("text_turnover") }}</span
              >：<span>{{ l.turnover }}</span>
            </div>
            <div>
              <span> {{ $t("text_number") }}</span
              >：<span>{{ l.cnt }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="d-flex justify-content-center flex-column align-items-center mt-3 p-3"
      style="background: #071d2a; border-radius: 16px"
    >
      <h5 class="mb-0">{{ $t("nodata") }}</h5>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import dateTime from "@/components/utils/datatime";
export default {
  computed: {},
  data() {
    return {
      old_begin: dateTime.getoneday(-1),
      old_end: dateTime.getoneday(0),
      begin: "",
      end: "",
      totalWinLose: 0,
      totalBetAmount: 0,
      list: [],
    };
  },
  methods: {
    get_data: function () {
      this.begin = this.old_begin;
      this.end = this.old_end;
      if (
        Cookies.get("code") == null ||
        Cookies.get("code") == "" ||
        localStorage.getItem("uid") == null ||
        localStorage.getItem("uid") == ""
      ) {
        this.$store.commit("islogin", true);
        this.$store.dispatch("logout_public");
      } else {
        let param = {
          action: "data_vip",
          body: {
            uid: localStorage.getItem("uid"),
            session_code: Cookies.get("code"),
            clubid: localStorage.getItem("clubid"),
            begindate: this.begin,
            enddate: this.end,
          },
        };
        this.$store.dispatch("getpost", param).then((res) => {
          this.totalWinLose = res.losewin;
          this.totalBetAmount = res.betamount;
          this.list = res.data;
        });
      }
    },
  },
  components: {},
  watch: {},
  created() {
    this.begin = this.old_begin;
    this.end = this.old_end;
  },
};
</script>
