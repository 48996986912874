<template>
  <div class="wc-PageView wc_bank">
    <div class="wc-PageView_NavigationMenu">
      <div
        class="nm-NavigationMenuModule-footerdisplayed nm-NavigationMenuModule nm-NavigationMenuModule-wide"
      >
        <div class="nm-MenuHeader">
          <div class="nm-MenuHeader_Text">{{ $t("wellat") }}</div>
        </div>
        <div class="nm-MenuItems">
          <div
            class="nm-MenuItem"
            :class="
              this.id == 'balance'
                ? 'nm-MenuItem-selected nm-MenuItem-selectedindicator'
                : ''
            "
            @click="this.id = 'balance'"
          >
            <div class="nm-MenuItem_Text">{{ $t("text_balance") }}</div>
          </div>
          <div
            class="nm-MenuItem"
            :class="
              this.id == 'deposit'
                ? 'nm-MenuItem-selected nm-MenuItem-selectedindicator'
                : ''
            "
            @click="this.id = 'deposit'"
          >
            <div class="nm-MenuItem_Text">{{ $t("deposit") }}</div>
          </div>
          <div
            class="nm-MenuItem"
            :class="
              this.id == 'withdraw'
                ? 'nm-MenuItem-selected nm-MenuItem-selectedindicator'
                : ''
            "
            @click="this.id = 'withdraw'"
          >
            <div class="nm-MenuItem_Text">{{ $t("withdrawl") }}</div>
          </div>
          <!-- <div class="nm-MenuItem " :class="this.id == 'chargegift' ? 'nm-MenuItem-selected nm-MenuItem-selectedindicator' : ''"
            @click="this.id = 'chargegift'">
            <div class="nm-MenuItem_Text ">{{ $t('charge_text1') }}</div>
          </div> -->
        </div>
      </div>
    </div>
    <wallet @selectid="selectval" v-if="this.id == 'balance'" />
    <deposit v-if="this.id == 'deposit'" />
    <withdraw v-if="this.id == 'withdraw'" />
    <!-- <chargegiftview v-if="this.id == 'chargegift'" /> -->
  </div>
</template>
<script>
import wallet from "./Home/_wallet";
import deposit from "./Deposit";
import withdraw from "./Home/_withdraw";
// import chargegiftview from "./Home/charge_gift";

export default {
  name: "Home",
  components: {
    wallet,
    deposit,
    withdraw,
    // chargegiftview,
  },
  data() {
    return {
      id: "balance",
    };
  },
  created() {
    if (this.$route.query.id != null) {
      this.id = this.$route.query.id;
      let newQuery = JSON.parse(JSON.stringify(this.$route.query));
      delete newQuery.id;
      this.$router.replace({ query: newQuery });
    }
    this.change_id();
  },
  methods: {
    selectval: function (e) {
      this.id = e;
    },
    change_id() {
      if (this.$route.params.sub_type != null) {
        this.id = this.$route.params.sub_type;
        this.$router.push("/ME/wallet");
      }
    },
  },
  watch: {
    sub_type() {
      this.change_id();
    },
  },
  computed: {
    sub_type() {
      if (this.$route.params.sub_type != null) {
        return this.$route.params.sub_type;
      } else return "";
    },
  },
};
</script>
