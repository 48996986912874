<template>
  <div>
    <div class="casino-header-container-new">
      <div
        class="d-flex justify-content-between align-items-center position-relative"
      >
        <span class="text-center club_span_big ms-0">
          {{ $t("text_club15") }}
        </span>
        <p class="text-center mb-0">{{ begin }} ～ {{ end }}</p>
      </div>

      <div class="d-flex bg_drak_coff2 mt-3 mt-1024-0 member_opa">
        <span
          class="club_span3 c-pointer"
          @click="is_not_id = '1'"
          :class="is_not_id == '1' ? 'bg_light_coff2' : ''"
          >{{ $t("text_club22") }}</span
        >
        <span class="club_span3">｜</span>
        <span
          class="club_span3 c-pointer"
          @click="is_not_id = '2'"
          :class="is_not_id == '2' ? 'bg_light_coff2' : ''"
          >{{ $t("text_club23") }}</span
        >
        <span class="club_span3">｜</span>
        <span
          class="club_span3 c-pointer"
          @click="is_not_id = '3'"
          :class="is_not_id == '3' ? 'bg_light_coff2' : ''"
          >{{ $t("text_club24") }}</span
        >
      </div>

      <div
        class="mt-2 d-flex justify-content-start align-items-center flex-1023-column"
      >
        <div
          class="d-flex justify-content-start align-items-center flex-1023-column w-100"
        >
          <div
            class="serach_date_card-new d-flex align-items-center me-3 me-1023-3 w-100"
          >
            <div class="dateControlLabel">{{ $t("history_begin") }}:</div>
            <input type="date" v-model="old_begin" Style="padding: 15px;" />
          </div>
          <div
            class="serach_date_card-new d-flex align-items-center me-3 me-1023-3 w-100"
          >
            <div class="dateControlLabel">{{ $t("history_end") }}:</div>
            <input type="date" v-model="old_end" Style="padding: 15px;" />
          </div>
        </div>
        <div
          class="show-history-button rounded-5 bg_green_btn m-0 btn-ft w-100"
          @click="get_data()"
        >
          {{ $t("text_search") }}
        </div>
      </div>

      <div
        v-if="list.length != 0"
        class="mt-3 w-100 p-3"
        style="background: #071d2a; border-radius: 16px"
      >
        <div style="height: 45vh; overflow-y: auto">
          <div
            v-if="is_not_id == 1"
            class="new_data new_data_3 w-100 align-content-start d-grid"
          >
            <div class="new_data-div" v-for="l in list" :key="l">
              <!-- <div class="d-flex justify-content-between align-items-center">
                <div
                  class="agent_detail_btn px-2 py-1"
                  @click="flag_finance_page = true"
                >
                  {{ $t("text_agent_text5") }}
                </div>
              </div> -->
              <div
                class="d-flex justify-content-between align-items-center"
                style="gap: 8px"
              >
                <div class="" style="flex: 1">
                  <p class="fs-18" style="flex: 1">
                    {{ $t("text_agent_text1") }}：<br />{{ l.userid }}
                  </p>
                  <p class="fs-18" style="flex: 1">
                    {{ $t("text_agent_text2") }}：<br />{{ l.member }}
                  </p>
                  <p class="fs-18" style="flex: 1">
                    {{ $t("text_agent_text3") }}：<br />{{ l.turnover }}
                  </p>
                  <p class="fs-18 mb-0" style="flex: 1">
                    {{ $t("text_agent_text4") }}：<br />{{ l.losewin }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="is_not_id == 2"
            class="new_data new_data_3 w-100 align-content-start d-grid"
          >
            <div class="new_data-div" v-for="l in list" :key="l">
              <div
                class="d-flex justify-content-between align-items-center"
                style="gap: 8px"
              >
                <div class="" style="flex: 1">
                  <p class="fs-18" style="flex: 1">
                    {{ $t("text_club25") }}：<br />{{ l.userid }}
                  </p>
                  <p class="fs-18" style="flex: 1">
                    {{ $t("text_club26") }}：<br />{{ l.payment }}
                  </p>
                  <p class="fs-18" style="flex: 1">
                    {{ $t("text_club27") }}：<br />{{ l.getback }}
                  </p>
                  <p class="fs-18 mb-0" style="flex: 1">
                    {{ $t("text_club28") }}：<br />{{ l.rate }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="is_not_id == 3"
            class="new_data new_data_3 w-100 align-content-start d-grid new_data_1"
          >
            <div class="new_data-div" v-for="l in list_countertop" :key="l">
              <div
                class="d-flex justify-content-between align-items-center flex-1024-column"
                style="gap: 8px"
              >
                <div class="" style="flex: 1">
                  <p class="fs-18" style="flex: 1">
                    {{ $t("text_club25") }}：<br />{{ l.middle_man }}
                  </p>
                  <p class="fs-18" style="flex: 1">
                    {{ $t("text_club29") }}：<br />{{ l.userid }}
                  </p>
                  <p class="fs-18 mb-0" style="flex: 1">
                    {{ $t("text_club30") }}：<br />{{ l.rate }}
                  </p>
                </div>
                <div class="" style="flex: 1">
                  <p class="fs-18" style="flex: 1">
                    {{ $t("text_club31") }}：<br />{{ l.start_time }}<br />{{
                      l.end_time
                    }}
                  </p>
                  <p class="fs-18" style="flex: 1">
                    {{ $t("text_club32") }}：<br />{{ l.losewin }}
                  </p>
                  <p class="fs-18 mb-0" style="flex: 1">
                    {{ $t("text_club33") }}：<br />{{ l.turnover }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="d-flex justify-content-center flex-column align-items-center mt-3 w-100 p-3"
        style="background: #071d2a; border-radius: 16px"
      >
        <h5 class="m-0">{{ $t("nodata") }}</h5>
      </div>
    </div>

    <div class="finance_page home_vip_page" v-show="flag_finance_page">
      <div class="finance_card">
        <div class="finance_close" @click="flag_finance_page = false">×</div>
        <div class="finance_title">
          {{ $t("text_agent_text5") }}
        </div>

        <div class="finance_main">
          <div class="finance_deposit" style="margin: 10px auto">
            <div class="d-flex justify-content-start align-items-center">
              <span> {{ $t("text_king8") }}：</span>
              <span>DEMO</span>
            </div>
            <div class="d-flex justify-content-start align-items-center">
              <span> {{ $t("text_agent_text6") }}：</span>
              <span>DEMO</span>
            </div>
            <div class="d-flex justify-content-start align-items-center">
              <span> {{ $t("text_turnover") }}：</span>
              <span>DEMO</span>
            </div>
            <hr style="color: #fff" />

            <div
              class="d-flex justify-content-start align-items-center"
              style="gap: 15px"
            >
              <span> {{ $t("home_text6_1") }}：</span>
              <div>
                <span> {{ $t("text_agent_text7") }}：</span>
                <span
                  :style="[
                    totalWinLose > 0
                      ? 'color:#00af4c;font-weight:600'
                      : totalWinLose < 0
                      ? 'color:#e7323e;font-weight:600'
                      : '',
                  ]"
                  >{{ totalWinLose }}</span
                >
              </div>
              <div class="text-grey">
                <span> {{ $t("text_agent_text8") }}：</span>
                <span>100,000</span>
              </div>
            </div>
            <div
              class="d-flex justify-content-start align-items-center"
              style="gap: 15px"
            >
              <span> {{ $t("home_text7_1") }}：</span>
              <div>
                <span> {{ $t("text_agent_text7") }}：</span>
                <span
                  :style="[
                    totalWinLose > 0
                      ? 'color:#00af4c;font-weight:600'
                      : totalWinLose < 0
                      ? 'color:#e7323e;font-weight:600'
                      : '',
                  ]"
                  >{{ totalWinLose }}</span
                >
              </div>
              <div class="text-grey">
                <span> {{ $t("text_agent_text8") }}：</span>
                <span>100,000</span>
              </div>
            </div>
            <div
              class="d-flex justify-content-start align-items-center"
              style="gap: 15px"
            >
              <span> {{ $t("text_lotto_1") }}：</span>
              <div>
                <span> {{ $t("text_agent_text7") }}：</span>
                <span
                  :style="[
                    totalWinLose > 0
                      ? 'color:#00af4c;font-weight:600'
                      : totalWinLose < 0
                      ? 'color:#e7323e;font-weight:600'
                      : '',
                  ]"
                  >{{ totalWinLose }}</span
                >
              </div>
              <div class="text-grey">
                <span> {{ $t("text_agent_text8") }}：</span>
                <span>100,000</span>
              </div>
            </div>
            <div
              class="d-flex justify-content-start align-items-center"
              style="gap: 15px"
            >
              <span> {{ $t("text_sport_1") }}：</span>
              <div>
                <span> {{ $t("text_agent_text7") }}：</span>
                <span
                  :style="[
                    totalWinLose > 0
                      ? 'color:#00af4c;font-weight:600'
                      : totalWinLose < 0
                      ? 'color:#e7323e;font-weight:600'
                      : '',
                  ]"
                  >{{ totalWinLose }}</span
                >
              </div>
              <div class="text-grey">
                <span> {{ $t("text_agent_text8") }}：</span>
                <span>100,000</span>
              </div>
            </div>
            <div
              class="d-flex justify-content-start align-items-center"
              style="gap: 15px"
            >
              <span> {{ $t("text_chess_1") }}：</span>
              <div>
                <span> {{ $t("text_agent_text7") }}：</span>
                <span
                  :style="[
                    totalWinLose > 0
                      ? 'color:#00af4c;font-weight:600'
                      : totalWinLose < 0
                      ? 'color:#e7323e;font-weight:600'
                      : '',
                  ]"
                  >{{ totalWinLose }}</span
                >
              </div>
              <div class="text-grey">
                <span> {{ $t("text_agent_text8") }}：</span>
                <span>100,000</span>
              </div>
            </div>
            <div
              class="d-flex justify-content-start align-items-center"
              style="gap: 15px"
            >
              <span> {{ $t("text_texas_poker") }}：</span>
              <div>
                <span> {{ $t("text_agent_text7") }}：</span>
                <span
                  :style="[
                    totalWinLose > 0
                      ? 'color:#00af4c;font-weight:600'
                      : totalWinLose < 0
                      ? 'color:#e7323e;font-weight:600'
                      : '',
                  ]"
                  >{{ totalWinLose }}</span
                >
              </div>
              <div class="text-grey">
                <span> {{ $t("text_agent_text8") }}：</span>
                <span>100,000</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import dateTime from "@/components/utils/datatime";
export default {
  computed: {},
  data() {
    return {
      filterSearch: [],
      old_begin: dateTime.getoneday(-1),
      old_end: dateTime.getoneday(0),
      begin: "",
      end: "",
      flag_finance_page: false,
      totalWinLose: 500,
      list: [],
      list_countertop: [],
      is_not_id: 1,
    };
  },
  methods: {
    get_data: function () {
      this.begin = this.old_begin;
      this.end = this.old_end;
      if (
        Cookies.get("code") == null ||
        Cookies.get("code") == "" ||
        localStorage.getItem("uid") == null ||
        localStorage.getItem("uid") == ""
      ) {
        this.$store.commit("islogin", true);
        this.$store.dispatch("logout_public");
      } else {
        let param = {
          action: "dalidata",
          body: {
            uid: localStorage.getItem("uid"),
            session_code: Cookies.get("code"),
            clubid: localStorage.getItem("clubid"),
            begindate: this.begin,
            enddate: this.end,
          },
        };
        this.$store.dispatch("getpost", param).then((res) => {
          this.list = res.data;
        });
        param = {
          action: "dalidata2",
          body: {
            uid: localStorage.getItem("uid"),
            session_code: Cookies.get("code"),
            clubid: localStorage.getItem("clubid"),
            begindate: this.begin,
            enddate: this.end,
          },
        };
        this.$store.dispatch("getpost", param).then((res) => {
          this.list_countertop = res.data;
        });
      }
    },
  },
  components: {},
  watch: {},
  created() {
    this.begin = this.old_begin;
    this.end = this.old_end;
  },
};
</script>
