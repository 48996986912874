<template>
  <div class="">
    <div v-if="!flag_step">
      <div
        class="d-flex justify-content-center align-items-center position-relative"
      >
        <div
          class="mobile-menu-deposit-back pe-2 py-1 c-pointer position-absolute mobile-menu-back"
          @click="$router.push('/club')"
        >
          <span> ＜{{ $t("text_back_page") }} </span>
        </div>
        <span class="text-center club_span_big"> {{ $t("text_sg1") }} </span>
      </div>
      <div class="bg-table-img p-md-5 mt-3">
        <div class="d-flex flex-wrap justify-content-center">
          <div
            class="d-flex position-relative radius-25 col-6 col-md-4"
            @click="(flag_step = true), (games = 'texas'), (texas_type = 9)"
          >
            <img
              src="@/assets/img/sg_img/mainimg_game_txh.png"
              class="w-100 px-3 py-3"
            />
          </div>
          <div
            class="d-flex position-relative radius-25 col-6 col-md-4"
            @click="(flag_step = true), (games = 'sicbo'), (texas_type = 9)"
          >
            <img
              src="@/assets/img/sg_img/mainimg_game_dic.png"
              class="w-100 px-3 py-3"
            />
          </div>
          <div
            class="d-flex position-relative radius-25 col-6 col-md-4"
            @click="(flag_step = true), (games = 'texas'), (texas_type = 6)"
          >
            <img
              src="@/assets/img/sg_img/mainimg_game_royal.png"
              class="w-100 px-3 py-3"
            />
          </div>
          <div
            class="d-flex position-relative radius-25 col-6 col-md-4"
            @click="(flag_step = true), (games = 'tongzi'), (texas_type = 9)"
          >
            <img
              src="@/assets/img/sg_img/mainimg_game_ttz.png"
              class="w-100 px-3 py-3"
            />
          </div>
          <div
            class="d-flex position-relative radius-25 col-6 col-md-4"
            @click="(flag_step = true), (games = 'niu'), (texas_type = 9)"
          >
            <img
              src="@/assets/img/sg_img/mainimg_game_cow.png"
              class="w-100 px-3 py-3"
            />
          </div>
          <div
            class="d-flex position-relative radius-25 col-6 col-md-4"
            @click="(flag_step = true), (games = 'baccarat'), (texas_type = 9)"
          >
            <img
              src="@/assets/img/sg_img/mainimg_game_bac.png"
              class="w-100 px-3 py-3"
            />
          </div>
          <div
            class="d-flex position-relative radius-25 col-6 col-md-4"
            @click="(flag_step = true), (games = 'fasttexas'), (texas_type = 9)"
          >
            <img
              src="@/assets/img/sg_img/mainimg_game_tex.png"
              class="w-100 px-3 py-3"
            />
          </div>
          <!-- <div
            class="d-flex position-relative radius-25 col-6 col-md-4"
            @click="flag_step = true, games = 'roulette', texas_type = 9"
          > -->
          <div
            class="d-flex position-relative radius-25 col-6 col-md-4"
            @click="pop_alert()"
          >
            <img
              src="@/assets/img/sg_img/mainimg_game_rou.png"
              class="w-100 px-3 py-3"
            />
          </div>
          <!-- <div
            class="d-flex position-relative radius-25 col-6 col-md-4"
            @click="(flag_step = true), (games = 'dragontiger'), (texas_type = 9)"
          > -->
          <div
            class="d-flex position-relative radius-25 col-6 col-md-4"
            @click="pop_alert()"
          >
            <img
              src="@/assets/img/sg_img/mainimg_game_dragontiger.png"
              class="w-100 px-3 py-3"
            />
          </div>
        </div>
      </div>
    </div>

    <step2
      v-else
      @selectid="selectval"
      :games="this.games"
      :texas_type="this.texas_type"
    />
  </div>
</template>
<script>
import step2 from "./select_game_step2.vue";
export default {
  computed: {},
  data() {
    return {
      flag_step: false,
      games: "texas",
      texas_type: 9,
    };
  },
  methods: {
    pop_alert() {
      this.$MSG.API_popmsg(this.$t("text_coming"), "", "warning");
    },
    selectval: function (e) {
      this.flag_step = e;
    },
  },
  watch: {},
  created() {},
  components: { step2 },
};
</script>
